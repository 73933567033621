import { Col, Text, CMSLayout, Grid, RatioCol, VideoPlayInModal } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';
import { ActivityIndicator } from 'react-native';

export const VIDEO_INSTRUCTIONS_ADMIN = [
  {
    title: 'Login & Account',
    videos: [
      { path: 'video-instructions/1-global-admin/admin.login.cy.js', label: 'Login as a Global Admin' }
    ]
  },
  {
    title: 'Impersonate client',
    videos: [
      { path: 'video-instructions/1-global-admin/admin.1-impersonate-client.cy.js', label: 'Select client' }
    ]
  }
];

export const VIDEO_INSTRUCTIONS_CLIENT = [
  {
    title: 'Login & Account',
    videos: [
      { path: 'video-instructions/2-client-admin/client.1-login.cy.js', label: 'Login' },
    ]
  },
  {
    title: 'Pathfinder',
    videos: [
      { path: 'video-instructions/2-client-admin/client.2-create-pathfinder.cy.js', label: 'Create and update multiple Pathfinder' },
      { path: 'video-instructions/2-client-admin/client.3-check-pathfinder.cy.js', label: 'A quick glance at Pathfinder List' },
      { path: 'video-instructions/2-client-admin/client.4-edit-elements.cy.js', label: 'Add elements to Pathfinder' },
      { path: 'video-instructions/2-client-admin/client.5-edit-statements.cy.js', label: 'Add work-statements to Pathfinder' },
      { path: 'video-instructions/2-client-admin/client.6-edit-email-settings.cy.js', label: 'Edit email settings' },
    ]
  },
  {
    title: 'Pathfinder Detail',
    videos: [
      { path: 'video-instructions-frontend/learner.1-view-elements.cy.js', label: 'Learner can view elements after submit contact form' },
    ]
  },
];

const Home : IScreen = ( ) => {
  const { navigate } = useNavFunc();
  const UserStore = Store.useUserStore();
  const { user } = UserStore;
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    UserStore.onReady().then((u) => {
      if (!u) {
        navigate(SCREEN.Login);
      }
    });
  }, []);

  useEffect(() => {
    if (!isLoggedIn && !!user) {
      setIsLoggedIn(true);
    }
    if (!user && isLoggedIn) {
      setIsLoggedIn(false);
    }
  }, [user, isLoggedIn]);

  const userName = !user ? '' : `${user.firstName} ${user.lastName}`;

  const videos = !isLoggedIn || !user ? [] : user.role === 'admin' ? VIDEO_INSTRUCTIONS_ADMIN : VIDEO_INSTRUCTIONS_CLIENT;

  return !isLoggedIn ? (
    <Col flex1 middle>
      <ActivityIndicator color="black" size="large" />
      <Text mt2>Please wait...</Text>
    </Col>
  ) : (
    <CMSLayout>
      <Col flex1 m2 p2 round1 bgWhite shadow>
        <Text h4>Welcome, {userName}.</Text>
        <Text mb2>Check out our video instructions of how to use the system</Text>
        {videos.map((v, vI) => {
          return (
            <Col key={'v-'+vI} mb2>
              <Text subtitle1 mb1>{v.title}</Text>
              <Grid xs='100%' md='25%'>
                {v.videos.map((item, itemIndex) => {
                  const videoSrc = process.env.NODE_ENV === 'production' ? `https://pathfinder-cd.fra1.digitaloceanspaces.com/${item.path}.mp4` : `/video-instructions/${item.path}.mp4`;
                  return (
                    <Col key={'video-'+vI+'-'+itemIndex}>
                      <VideoPlayInModal src={videoSrc} mb1 />
                      <Text>{item.label}</Text>
                    </Col>
                  )
                })}
              </Grid>
            </Col>
          )
        })}
      </Col>
    </CMSLayout>
  )
};

Home.routeInfo = {
  title: 'Pathfinder',
  path: '/',
};

export default Home;
