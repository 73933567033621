import { Col, Text, CMSLayout } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';

const ListLearning : IScreen = ( ) => {
  const { navigate } = useNavFunc();

  return  (
    <CMSLayout requireAuthen>
      <Col flex1 m2 p2 round1 bgWhite shadow>
        <Text h4>List Learning</Text>
      </Col>
    </CMSLayout>
  )
};

ListLearning.routeInfo = {
  title: 'List Learnings',
  path: '/learnings',
};

export default ListLearning;