import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, IColProps, Row, TouchField, Grid, ImageSelect, Text } from 'components';
import { COLOR } from 'const';
import { TPathfinder } from 'type';
import { FontAwesome5 } from '@expo/vector-icons';
import Store from 'store';
import { usePathfinder } from 'store/Pathfinder.Store';
import PathfinderContents from './PathfinderContents';
import { Animated, ScrollView } from 'react-native';
import { Tooltip } from 'react-tippy';

interface Props extends IColProps {
  pathfinderId?: string
  onRefresh?: () => void
}

const PathdinderLayout = ({ pathfinderId, ...props }: Props) => {
  const [isHover, setIsHover] = useState(false);
  // const [isExpanding, setIsExpanding] = useState(false);
  const pathfinder: TPathfinder = usePathfinder(pathfinderId);
  const animatedHeight = useRef(new Animated.Value(100));
  const { editPathfinder, isSelectMode, selectPathfinder, selectedPathfinder, expandingPathfinderId, expandPathfinder } = Store.usePathfinderStore(state => ({
    editPathfinder: state.editPathfinder,
    isSelectMode: state.isSelectMode,
    selectPathfinder: state.selectPathfinder,
    selectedPathfinder: state.selectedPathfinder,
    expandingPathfinderId: state.expandingPathfinderId,
    expandPathfinder: state.expandPathfinder,
  }));

  const isExpanding = expandingPathfinderId === pathfinderId;

  // useEffect(() => {
  //   if (expandingPathfinderId === pathfinderId) {
  //     setIsExpanding(true);
  //   }
  //   if (expandingPathfinderId === "none") {
  //     setIsExpanding(false);
  //   }
  // }, [expandingPathfinderId])

  useEffect(() => {
    if (!isExpanding) {
      Animated.timing(animatedHeight.current, {
        toValue: 100,
        duration: 300,
        useNativeDriver: false,
      }).start();
    }
  }, [isExpanding])

  const handleContentSizeChange = useCallback((w, h) => {
    Animated.timing(animatedHeight.current, {
      toValue: h,
      useNativeDriver: false,
      duration: 300,
    }).start();
  }, []);

  return (
    <Row
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      backgroundColor={isHover ? COLOR.GREY_LIGHT : 'white'}
      minHeight={isExpanding ? 150 : undefined}
      borderRadius={10}
      borderWidth={1}
      borderColor={'rgba(0,0,0,0.08)'}
      shadow1={isHover}
      dataSet={{ element: 'pathfinder-layout' }}
      alignItems={'flex-start'}
      {...props}
    >
      <Animated.View style={{ height: animatedHeight.current, flex: 1 }}>
        {isExpanding ?
          <ScrollView scrollEnabled={false} onContentSizeChange={handleContentSizeChange}>
            <Grid xs='100%' md=':1' stretch height100p flex1>
              <Col m0 width={120} overflow={'hidden'}>
                <ImageSelect
                  value={pathfinder?.logo}
                  imageStyle={{ width: '100%', height: 90 }}
                  onChange={(url) => editPathfinder(pathfinderId, 'logo', url)}
                  isButtonsBottom
                  entityName='logo'
                />
                <Text fontSize={12} textAlign={"center"} marginTop={8} color={COLOR.SUB_TEXT}>PNG - 645px - 192px</Text>
                <Text fontSize={12} textAlign={"center"} marginTop={8} color={COLOR.SUB_TEXT}>If no logo is uploaded, only the pathfinder name will be displayed</Text>
              </Col>
              <PathfinderContents
                pathfinderId={pathfinderId}
                collapsePathfinder={() => expandPathfinder(undefined)}
                {...props}
              />
            </Grid>
          </ScrollView>
          :
          <TouchField nativeID='expand-pathfinder' flex1 onPress={() => expandPathfinder(pathfinderId)}>
            <Row flex1 pr2>
              <Col m0 width={120} overflow={'hidden'}>
                <ImageSelect
                  value={pathfinder?.logo}
                  imageStyle={{ width: '100%', height: 90 }}
                  onChange={(url) => editPathfinder(pathfinderId, 'logo', url)}
                  canRemove={false}
                  entityName='logo'
                />
              </Col>
              <Col flex1 m0 p1>
                <Text fontSize={14} bold>Pathfinder name: <Text bold={false}>{pathfinder.name}</Text></Text>
              </Col>
              <Tooltip trigger="mouseenter" title="Expand the pathfinder">
                <FontAwesome5 name='chevron-up' size={20} color={COLOR.MAIN} />
              </Tooltip>
            </Row>
          </TouchField>
        }
      </Animated.View>
      {isSelectMode &&
        <TouchField
          onPress={() => selectPathfinder(pathfinderId)}
          padding={6}
          marginRight={4}
          marginTop={6}
        >
          <FontAwesome5
            name={selectedPathfinder.includes(pathfinderId) ? 'check-square' : 'square'}
            size={20}
            solid={selectedPathfinder.includes(pathfinderId)}
            color={COLOR.MAIN}
          />
        </TouchField>
      }
    </Row>
  )
};

export default React.memo(PathdinderLayout)
