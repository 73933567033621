import Request from '../Request.utils'
export interface ICreateRequestBody {
  clientId?: string;
  comment?: string;
  data?: object;
  icon?: string;
  name: string;
  tags?: string;
  type?: string;
  url: string;
}
export interface IUpdateRequestBody {
  clientId?: string;
  comment?: string;
  data?: object;
  icon?: string;
  id: string;
  name?: string;
  tags?: string;
  type?: string;
  url?: string;
}
export interface IListRequestQuery {
  all?: boolean;
  clientId?: string;
  name?: string;
  sortBy?: 'name' | 'type' | 'tags' | 'name_desc' | 'type_desc' | 'tags_desc';
  tags?: string;
  type?: string;
}
export interface IRemoveRequestBody {
  id: string;
}
export interface IMergeTagsRequestBody {
  clientId?: string;
  newTag: string;
  tags: string;
}
export interface IImportRequestBody {
  clientId?: string;
  learnings: {
    /**
     * Unknown Property
     */
    [x: string]: unknown;
    comment?: string;
    icon?: string;
    name?: string;
    tags?: string;
    type?: string;
    url?: string;
    urlShortName?: string;
  }[];
  updateExisting?: boolean;
}


export class LearningAPI {


  request = Request // for ts auto suggestion
  debugMode = false // for ts auto suggestion
  constructor(request, debugMode = false) {
    if(request) this.request = request;
    this.debugMode = debugMode;
  }
  create = async (body: ICreateRequestBody) => {
    if(this.debugMode) console.log('/api/learnings/create', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/learnings/create', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  update = async (body: IUpdateRequestBody) => {
    if(this.debugMode) console.log('/api/learnings/update', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/learnings/update', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  list = async (query: IListRequestQuery) => {
    if(this.debugMode) console.log('/api/learnings', 'GET', undefined, query, undefined, );
    const res = await this.request.call('/api/learnings', 'GET', undefined, query, undefined, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  remove = async (body: IRemoveRequestBody) => {
    if(this.debugMode) console.log('/api/learnings/remove', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/learnings/remove', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  mergeTags = async (body: IMergeTagsRequestBody) => {
    if(this.debugMode) console.log('/api/learnings/merge-tags', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/learnings/merge-tags', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  import = async (body: IImportRequestBody) => {
    if(this.debugMode) console.log('/api/learnings/import', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/learnings/import', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
}
export default new LearningAPI(Request);