import React, { forwardRef } from 'react';
import { COLOR } from 'const';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Input from './Input';

interface DatePickerProps {
  value?: any;
  onChange?(newValue: any): void;
}

const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
  <Input
    height={40}
    borderRadius={8}
    borderWidth={1}
    value={value}
    onPress={onClick}
    inputProps={{
      style: {
        // @ts-ignore
        outline: 'none',
        fontSize: 16,
      },
    }}
    borderColor={COLOR.BORDER_LIGHT}
  />
));

const DatePickerCustom = (props: DatePickerProps) => {
  const { value, onChange } = props;
  return (
    <DatePicker
      selected={value}
      onChange={onChange}
      customInput={<ExampleCustomInput />}
    />
  )
}
export default DatePickerCustom;
