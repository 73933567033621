import React, { useState, useEffect, useRef } from 'react';
import { Image as ImageRN, ImageProps } from 'react-native';
import { QuickComponent, IStyleProps } from 'quickly-react';
import { addCommonStyle, TCommonPropStyle } from './addCommonStyle';
import Col from './Col';
import { Entypo } from '@expo/vector-icons';
import { COLOR } from 'const';
import { isEmpty } from 'lodash';
import Text from './Text';

const quickComponent = new QuickComponent();
quickComponent.setupDefaultProps([], {
  shouldDetectStyleProps: true,
});
addCommonStyle(quickComponent);

export interface IImageProps extends ImageProps, Omit<IStyleProps, 'borderRadius'>, TCommonPropStyle {
  onRef?: any;
  emptyText?: string;
}

const ImageComponent = (props: IImageProps) => {
  const [err, setErr] = useState(false);
  const onError = (e) => {
    setErr(true);
  };
  useEffect(() => {
    // @ts-ignore
    if (!props.source || (typeof props.source === 'object' && !props.source.uri)) {
      setErr(true);
    } else setErr(false);
  }, [props.source]);
  if (err) {
    return (
      <Col overflow={'hidden'} {...props}>
        <Col absoluteFill backgroundColor={COLOR.MAIN} opacity={0.2} />
        <Col absoluteFill middle>
          <Entypo name="image" size={24} color="rgba(0,0,0,0.3)" />
          {!isEmpty(props.emptyText) &&
            <Text
              style={{ textDecorationLine: "underline" }}
              color={COLOR.SUB_TEXT}
              fontSize={12}
            >
              {props.emptyText}
            </Text>
          }
        </Col>
      </Col>
    )
  }
  return <ImageRN ref={props.onRef} onError={onError} {...props} />;
};

const Image: React.FC<IImageProps> = quickComponent.make(ImageComponent);

export default Image;
