import React, { memo } from "react";
import Col, { IColProps } from "./Col";
import { useDynamicResponsiveValue } from "quickly-react";

interface IMainContentContainer extends IColProps {

}

const MainContentContainer = (props: IMainContentContainer) => {
  const rV = useDynamicResponsiveValue(['xs', 'md']);
  const breakpoint = rV({ xs: 'xs', md: 'md' });

  if (breakpoint === 'xs') {
    return <Col width100p padding={16} {...props} />
  }
  return <Col maxWidth={1400} width="90%" alignSelf="center" padding={16} {...props} />
}

export default memo(MainContentContainer);
