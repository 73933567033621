import * as ScreenObjects from 'screens';
import { IScreen } from 'type';
const Route = require('route-parser');

export const { linking, screenArr } = (() => {
  const screenUrls = {};
  const screenArr = [];
  Object.keys(ScreenObjects).forEach((screenName) => {
    const ScreenObj: IScreen = ScreenObjects[screenName];
    screenArr.push({ name: screenName, component: ScreenObj, title: ScreenObj.routeInfo.title });
    if (!screenUrls[screenName]) {
      screenUrls[screenName] = ScreenObj.routeInfo.path;
    }
  });
  return {
    linking: screenUrls,
    screenArr,
  };
})();

export const convertRouteToLink = (route, params) => {
  if (!linking[route]) return '';
  var route = new Route(linking[route]);
  return route.reverse(params) || undefined;
}