import React, { useRef } from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { TouchField, modal } from "components/elements";
import { COLOR } from "const";
import { read, utils } from "xlsx";
import { Col } from "components/base";
import ConfirmImportModal from "./ConfirmImportModal";

const infoIdx = {
  0: 'name',
  1: 'welcomeInstruction',
  2: 'additionalInstruction',
  3: 'completionInstruction',
  4: 'likertScale',
  5: 'elementsTitle',
}
const elementColIdx = {
  name: 3,
  rolloverText: 4,
  filters: 5,
  image: 6,
}
const statementColIdx = {
  statement: 7,
  rollover1: 8,
  learning1: 9,
  rollover2: 10,
  learning2: 11,
  rollover3: 12,
  learning3: 13,
  element: 14,
}

const learningColIdx = {
  name: 0,
  url: 1,
  type: 2,
  icon: 3,
  comment: 4,
  tags: 5,
}

const parseImageUrl = (str: string) => {
  if (!str) return '';
  return String(str).match(/IMAGE\("(.*?)"/)?.[1] || str;
}

const ImportButton = () => {
  const inputRef = useRef(null);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const range = utils.decode_range(ws['!ref']);

      let i = 0;
      const pathfinders = [];

      const getTargetPathfinder = (rowIdx: number) => {
        for (let i = 0; i < pathfinders.length; i++) {
          if (pathfinders[i].range[0] <= rowIdx && pathfinders[i].range[1] >= rowIdx) {
            return {
              pathfinder: pathfinders[i],
              idx: i,
            }
          }
        }
      }

      const grid = [];
      for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
        i += 1;
        const column = [];
        // Parse each row of the column
        for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
          const cellAddress = utils.encode_cell({ r: rowNum, c: colNum });
          const cellValue = ws[cellAddress]?.v || ws[cellAddress]?.f;
          column.push(cellValue);
        }
        grid.push(column);

        // handle pathfinder logo
        if (i === 1) {
          let lastStartIdx;
          let lastLogo;
          for (let j = 0; j < column.length; j++) {
            if (column[j] === "Logo" || j === column.length - 1) {
              if (lastStartIdx !== undefined) {
                pathfinders.push({
                  range: [lastStartIdx, j - 1],
                  logo: lastLogo,
                });
              }
              lastLogo = undefined;
              lastStartIdx = j;
            }
            if (lastStartIdx !== undefined && j === lastStartIdx + 1) {
              lastLogo = parseImageUrl(column[j]);
            }
          }
        }
        // handle pathfinder infos
        if (i === 3) {
          for (let j = 0; j < column.length; j++) {
            const target = getTargetPathfinder(j);
            if (!target) continue;
            const { range } = target.pathfinder;
            const pathfinder = { ...target.pathfinder };
            if (j === range[0]) {
              pathfinder['name'] = column[j];
            }
            const keyName = infoIdx[j - range[0]];
            if (keyName) {
              pathfinder[keyName] = column[j];
            }
            pathfinders[target.idx] = pathfinder;
          }
        }
      }

      for (let idx in pathfinders) {
        const pathfinder = { ...pathfinders[idx] };
        const elements = [];
        for (let i = pathfinder.range[0] + 2; i < pathfinder.range[1]; i++) {
          if (!grid[elementColIdx.name][i]) break;
          elements.push({
            name: grid[elementColIdx.name][i],
            rolloverText: grid[elementColIdx.rolloverText][i],
            filters: grid[elementColIdx.filters][i],
            image: parseImageUrl(grid[elementColIdx.image][i]),
          });
        }
        pathfinder.elements = elements;

        const statements = [];
        for (let i = pathfinder.range[0] + 2; i < pathfinder.range[1]; i++) {
          if (!grid[statementColIdx.statement][i]) break;
          statements.push({
            statement: grid[statementColIdx.statement][i],
            rollover1: grid[statementColIdx.rollover1][i],
            learning1: grid[statementColIdx.learning1][i],
            rollover2: grid[statementColIdx.rollover2][i],
            learning2: grid[statementColIdx.learning2][i],
            rollover3: grid[statementColIdx.rollover3][i],
            learning3: grid[statementColIdx.learning3][i],
            element: grid[statementColIdx.element][i],
          });
        }
        pathfinder.statements = statements;
        pathfinders[idx] = pathfinder;
      }

      // handle learnings
      const learnings = [];
      const ws2 = wb.Sheets[wb.SheetNames[1]];
      if (ws2) {
        const range2 = utils.decode_range(ws2['!ref']);
        const grid2 = [];
        for (let rowNum = range2.s.r; rowNum <= range2.e.r; rowNum++) {
          i += 1;
          const row = [];
          // Parse each row of the column
          for (let colNum = range2.s.c; colNum <= range2.e.c; colNum++) {
            const cellAddress = utils.encode_cell({ r: rowNum, c: colNum });

            const cellData = ws2[cellAddress];
            let cellValue = cellData?.v || cellData?.f;
            if (row.length === learningColIdx.url && cellData?.l?.Target) {
              cellValue = {
                url: cellData?.l?.Target,
                urlShortName: cellData?.v || cellData?.f,
              };
            }
            row.push(cellValue);
          }
          grid2.push(row);
        }
        for (let i = 1; i < grid2.length; i++) {
          learnings.push({
            name: grid2[i][learningColIdx.name],
            url: typeof grid2[i][learningColIdx.url] === "string" ? grid2[i][learningColIdx.url] : grid2[i][learningColIdx.url]?.url,
            urlShortName: typeof grid2[i][learningColIdx.url] === "object" ? grid2[i][learningColIdx.url]?.urlShortName : undefined,
            icon: grid2[i][learningColIdx.icon],
            type: grid2[i][learningColIdx.type],
            comment: grid2[i][learningColIdx.comment],
            tags: grid2[i][learningColIdx.tags] ? String(grid2[i][learningColIdx.tags]) : undefined,
          })
        }
      }

      modal.show(
        <ConfirmImportModal pathfinders={pathfinders} learnings={learnings} />
      );
    };
    reader.readAsBinaryString(file);
  };

  return (
    <TouchField
      width={36} height={36} marginLeft={8}
      borderRadius={18} middle
      onPress={() => inputRef.current?.click()}
      dataSet={{ element: 'import-pathfinder' }}
    >
      <MaterialCommunityIcons name='database-import' size={28} color={COLOR.MAIN} />
      <input
        ref={inputRef} type="file" accept=".xlsx"
        onChange={handleFileUpload} style={{ display: 'none' }}
      />
    </TouchField>
  )
}

export default ImportButton;
