import Request from '../Request.utils'
export interface IContactRequestBody {
  email: string;
  firstName?: string;
  lastName?: string;
  pathfinderId?: string;
}
export interface ISubmitRequestBody {
  elementId: string;
  likerts: object;
  pathfinderId: string;
}
export interface IReportRequestBody {
  clientId?: string;
  fromDate?: '';
  learnerId?: string;
  pathfinderId?: string;
  statementId?: string;
  toDate?: '';
}
export interface IListRequestQuery {
  all?: boolean;
}


export class LearnerAPI {


  request = Request // for ts auto suggestion
  debugMode = false // for ts auto suggestion
  constructor(request, debugMode = false) {
    if(request) this.request = request;
    this.debugMode = debugMode;
  }
  contact = async (body: IContactRequestBody) => {
    if(this.debugMode) console.log('/api/learners/contact', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/learners/contact', 'POST', undefined, undefined, body, );
    if (res.data.data && res.data.data.token) this.request.setLearnerToken(res.data.data.token)
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  submit = async (body: ISubmitRequestBody) => {
    if(this.debugMode) console.log('/api/learners/submit', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/learners/submit', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  report = async (body: IReportRequestBody) => {
    if(this.debugMode) console.log('/api/learners/report', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/learners/report', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  list = async (query: IListRequestQuery) => {
    if(this.debugMode) console.log('/api/learners', 'GET', undefined, query, undefined, );
    const res = await this.request.call('/api/learners', 'GET', undefined, query, undefined, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
}
export default new LearnerAPI(Request);