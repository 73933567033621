import BottleGoose from './svg/BottleGoose';
import Eye from './svg/Eye';
import Note from './svg/Note';
import Pencil from './svg/Pencil';
import Connect from './svg/Connect';


export const SVG = {
  BottleGoose,
  Eye,
  Note,
  Pencil,
  Connect,
}

export const ASSETS = {
  LOGO: require('./img/pathfinder-logo.png'),
  CUSTOMER_ADDRESS_ICON: require('./img/address.png'),
  LOGIN_BG: require('./img/login-bg.jpg'),
  FORM_BG: require('./img/form-bg.png'),
  EMAIL_TABLE_PREVIEW: require('./img/email-table-preview.png'),
}
