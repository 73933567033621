import React, { ReactElement, useCallback, useState } from "react";
import TouchField from "../button/TouchField";
import { Col, IColProps, Row, Text } from "components/base";
import { modal } from "../modal";
import { FontAwesome5 } from "@expo/vector-icons";
import { COLOR } from "const";

export interface IDropdownMenuItem {
  label?: string
  icon?: string
  id?: string
}

interface Props extends IColProps {
  children?: ReactElement
  options?: { label: string, icon?: string }[]
  onPressOption?: (opt: IDropdownMenuItem) => void
}

const DROPDOWN_W = 200;

const Menu = ({ options, onPressOption }: any) => {
  return (
    <Col width={DROPDOWN_W} bgWhite borderRadius={8}>
      {options?.map((opt, idx) => (
        <TouchField
          paddingHorizontal={16} paddingVertical={12}
          key={`opt${idx}`} onPress={() => onPressOption(opt)}
        >
          <Row>
            {!!opt.icon &&
              <Col marginRight={8}>
                <FontAwesome5 name={opt.icon} solid size={16} color={COLOR.SUB_TEXT} />
              </Col>
            }
            <Text>{opt.label}</Text>
          </Row>
        </TouchField>
      ))}
    </Col>
  )
}

const Dropdown = ({ children, options, onPressOption, ...restProps }: Props) => {
  const onPress = useCallback((e) => {
    const left = e?.nativeEvent?.clientX ? e?.nativeEvent?.clientX - DROPDOWN_W : 0;
    const top = e?.nativeEvent?.clientY ? e?.nativeEvent?.clientY : 0;
    modal.show(<Menu
      options={options}
      onPressOption={(opt) => {
        modal.hide();
        onPressOption(opt);
      }}
    />, {
      containerStyle: {
        backgroundColor: 'transparent',
      },
      contentStyle: {
        position: 'absolute',
        top,
        left,
      }
    })
  }, [options, onPressOption])

  return (
    <TouchField {...restProps} onPress={onPress}>
      {children}
    </TouchField>
  )
}

export default Dropdown;
