import React, { useMemo, useEffect, useState } from 'react';
import { Col } from 'components';
import { ScrollView, StyleSheet, useWindowDimensions } from 'react-native';
import * as Animatable from 'react-native-animatable';

class ModalController {

  show(component, modalSettings = {}) {

  }

  hide() {

  }
}

export const modal = new ModalController();

export const ModalContainer = ({ children }) => {
  const [show, setShow] = useState(false);
  const { height } = useWindowDimensions();
  const [anim, setAnim] = useState({
    animation: 'fadeInUp',
    duration: 300,
    delay: 300,
  });
  const [comp, setComp] = useState(null);
  const [modalSettings, setModalSettings] = useState<any>({
    containerStyle: {},
    contentStyle: {},
    containerOnClick: () => setShow(false),
  });

  useEffect(() => {
    modal.hide = () => {
      setShow(false);
    };
    modal.show = (component, settings = {}) => {
      setModalSettings({
        ...settings,
      })
      setComp(component)
      setShow(true);
    }
  });

  const onContainerClick = () => {
    if (modalSettings.containerOnClick) return modalSettings.containerOnClick?.();
    setShow(false);
  }

  useEffect(() => {
    if (!show) setComp(null);
  }, [show])

  return (
    <Col flex1>
      {useMemo(() => children, [])}
      {show && (
        <Animatable.View
          style={[{
            ...StyleSheet.absoluteFillObject,
            position: 'fixed',
            backgroundColor: 'rgba(0,0,0,0.3)',
          }, modalSettings.bgContainerStyle]}
          animation='fadeIn'
          duration={300}
        >
          <Col flex1 middle style={modalSettings.containerStyle} onPress={onContainerClick}>
            <Animatable.View {...anim} style={modalSettings.contentStyle}>
              <Col onPress={() => { }} activeOpacity={1} maxHeight={height}>
                <ScrollView>
                  {comp}
                </ScrollView>
              </Col>
            </Animatable.View>
          </Col>
        </Animatable.View>
      )}
    </Col>
  );
};
