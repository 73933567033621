import Request from '../Request.utils'
export interface ICreateRequestBody {
  clientId?: string;
  data?: object;
  elementIds?: string;
  id?: string;
  learningIdsLikert1?: string;
  learningIdsLikert2?: string;
  learningIdsLikert3?: string;
  orderIndex?: number;
  pathfinderId: string;
  rolloverLikert1?: string;
  rolloverLikert2?: string;
  rolloverLikert3?: string;
  statement: string;
}
export interface IUpdateRequestBody {
  data?: object;
  elementIds?: string;
  id: string;
  learningIdsLikert1?: string;
  learningIdsLikert2?: string;
  learningIdsLikert3?: string;
  orderIndex?: number;
  rolloverLikert1?: string;
  rolloverLikert2?: string;
  rolloverLikert3?: string;
  statement?: string;
}
export interface IListRequestQuery {
  all?: boolean;
  elementId?: string;
  pathfinderId?: string;
  statement?: string;
}
export interface IRemoveRequestBody {
  id: string;
}
export interface IImportRequestBody {
  pathfinderId: string;
  statements: {
    /**
     * Unknown Property
     */
    [x: string]: unknown;
    element?: string;
    rollover1?: string;
    rollover2?: string;
    rollover3?: string;
    statement?: string;
  }[];
  updateExisting?: boolean;
}


export class StatementAPI {


  request = Request // for ts auto suggestion
  debugMode = false // for ts auto suggestion
  constructor(request, debugMode = false) {
    if(request) this.request = request;
    this.debugMode = debugMode;
  }
  create = async (body: ICreateRequestBody) => {
    if(this.debugMode) console.log('/api/statements/create', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/statements/create', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  update = async (body: IUpdateRequestBody) => {
    if(this.debugMode) console.log('/api/statements/update', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/statements/update', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  list = async (query: IListRequestQuery) => {
    if(this.debugMode) console.log('/api/statements', 'GET', undefined, query, undefined, );
    const res = await this.request.call('/api/statements', 'GET', undefined, query, undefined, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  remove = async (body: IRemoveRequestBody) => {
    if(this.debugMode) console.log('/api/statements/remove', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/statements/remove', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  import = async (body: IImportRequestBody) => {
    if(this.debugMode) console.log('/api/statements/import', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/statements/import', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
}
export default new StatementAPI(Request);