import React, { useRef } from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { TouchField, modal } from "components/elements";
import { COLOR } from "const";
import { read, utils } from "xlsx";
import ConfirmImportModal from "./ConfirmImportModal";

const statementColIdx = {
  statement: 0,
  rollover1: 1,
  rollover2: 2,
  rollover3: 3,
  element: 4,
}

const ImportButton = ({ pathfinderId }) => {
  const inputRef = useRef(null);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = read(bstr, { type: 'binary' });
      let i = 0;
      // handle statements
      const statements = [];
      const ws = wb.Sheets[wb.SheetNames[2]];
      if (ws) {
        const range2 = utils.decode_range(ws['!ref']);
        const grid2 = [];
        for (let rowNum = range2.s.r; rowNum <= range2.e.r; rowNum++) {
          i += 1;
          const row = [];
          // Parse each row of the column
          for (let colNum = range2.s.c; colNum <= range2.e.c; colNum++) {
            const cellAddress = utils.encode_cell({ r: rowNum, c: colNum });
            const cellValue = ws[cellAddress]?.v || ws[cellAddress]?.f;
            row.push(cellValue);
          }
          grid2.push(row);
        }
        for (let j = 2; j < grid2.length; j++) {
          if (grid2[j][statementColIdx.statement]) {
            statements.push({
              statement: grid2[j][statementColIdx.statement],
              rollover1: grid2[j][statementColIdx.rollover1],
              rollover2: grid2[j][statementColIdx.rollover2],
              rollover3: grid2[j][statementColIdx.rollover3],
              element: grid2[j][statementColIdx.element],
            })
          }
        }
      }

      modal.show(
        <ConfirmImportModal
          statements={statements}
          pathfinderId={pathfinderId}
        />
      );
    };
    reader.readAsBinaryString(file);
  };

  return (
    <TouchField
      width={30} height={30} marginRight={8}
      borderRadius={15} middle
      onPress={() => inputRef.current?.click()}
      dataSet={{ element: 'import-statement' }}
    >
      <MaterialCommunityIcons name='database-import' size={24} color={COLOR.MAIN} />
      <input
        ref={inputRef} type="file" accept=".xlsx"
        onChange={handleFileUpload} style={{ display: 'none' }}
      />
    </TouchField>
  )
}

export default ImportButton;
