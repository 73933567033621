import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row, TouchField, SwapIconEffect, ContactForm } from 'components';
import { COLOR, SCREEN } from 'const';
import { ASSETS } from 'assets';
import { useDynamicResponsiveValue } from 'quickly-react';
import * as Animatable from 'react-native-animatable';
import { Animated, Image } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useNavFunc } from 'navigation';
import { useExpansionAnim, animStill } from './CMSLayout.anim';
import Store from 'store';
import shallow from 'zustand/shallow';
import { useRoute } from '@react-navigation/native';

export const HEADER_LOGIN_WIDTH = 269 * 0.7;
export const HEADER_LOGIN_HEIGHT = 79 * 0.7;

const HeaderBar = Row;
const MainContent = Animated.View;

let didAnimatedOneTime = false;

interface ILearnerLayoutProps {
  children: any,
  requireAuthen?: boolean,
  loginRedirectScreen?: string,
  expanded?: boolean,
}

const CMSMainContent = ({ children }: any) => {
  return (
    <Animatable.View animation={!didAnimatedOneTime ? 'fadeIn' : animStill} duration={300} delay={300} style={{ flex: 1 }}>
      {children}
    </Animatable.View>
  )
}

const LearnerLayout = ({ children, expanded }: ILearnerLayoutProps) => {
  const { navigate } = useNavFunc();
  const params = useRoute().params as { id: string };
  const [mobileLeftIconIndex, setMobileLeftIconIndex] = useState(0);
  const { shouldDoExpansionAnim, headerAnimated, mainAnimated } = useExpansionAnim(expanded);
  const rV = useDynamicResponsiveValue(['xs', 'md']);
  const breakpoint = rV({ xs: 'xs', md: 'md' });

  const { asked } = Store.useLearnerStore(state => ({
    asked: state.asked,
  }), shallow);
  const { currentPathfinder, getPathfinderDetail } = Store.usePathfinderStore(state => ({
    currentPathfinder: state.currentPathfinder,
    getPathfinderDetail: state.getPathfinderDetail,
  }), shallow);

  useEffect(() => {
    if (params?.id) {
      getPathfinderDetail(params?.id);
    }
  }, [params])

  const openMobileDrawer = () => {
    setMobileLeftIconIndex(1);
  };

  const closeMobileDrawer = () => {
    setMobileLeftIconIndex(0);
  };

  const mainContent = useMemo(() => {
    if (!currentPathfinder?.id) return null;
    if (asked) return children;
    return (
      <ContactForm
        pathfinderId={params?.id}
      />
    );
  }, [currentPathfinder, asked, params])

  const renderHeader = () => {
    return (
      <>
        <Animated.View
          // @ts-ignore
          style={{
            backgroundColor: 'white',
            flexDirection: 'row',
            alignItems: 'stretch',
            height: HEADER_LOGIN_HEIGHT,
            transform: [
              { translateY: headerAnimated.containerY },
            ],
            shadowColor: "#000",
            shadowOffset: {
              width: 1,
              height: 2,
            },
            shadowOpacity: 0.12,
            shadowRadius: 6.46,
            elevation: 9,
          }}
        >
          {breakpoint === 'xs' ? (
            <Row stretch width100p height={HEADER_LOGIN_HEIGHT}>
              <SwapIconEffect
                width={50} height={55}
                displayIndex={mobileLeftIconIndex}
                effect={mobileLeftIconIndex === 0 ? 'rotate' : 'rotate-backward'}
              >
                <TouchField width={50} height={55} middle onPress={openMobileDrawer}>
                  <Ionicons name="menu-sharp" size={24} color={COLOR.MAIN} />
                </TouchField>
                <TouchField width={50} height={55} middle onPress={closeMobileDrawer}>
                  <Ionicons name="close" size={24} color={COLOR.MAIN} />
                </TouchField>
              </SwapIconEffect>
              <Row flex1 middle>
                <Image source={ASSETS.LOGO} style={{ width: '65%', height: '80%' }} resizeMode='contain' />
              </Row>
              <Col width={50} />
            </Row>
          ) : (
            <>
              <Animated.View
                style={{
                  transform: [
                    { translateX: headerAnimated.logoXY.x },
                    { translateY: headerAnimated.logoXY.y },
                  ]
                }}
              >
                <Col
                  onPress={() => navigate(SCREEN.DetailPathfinder)}
                  middle width={HEADER_LOGIN_WIDTH}
                  height={HEADER_LOGIN_HEIGHT}
                >
                  <Image source={ASSETS.LOGO} style={{ width: '65%', height: '80%' }} resizeMode='contain' />
                </Col>
              </Animated.View>
              <HeaderBar flex1 justifyContent={'space-between'}>
              </HeaderBar>
              <Col
                onPress={() => navigate(SCREEN.DetailPathfinder)}
                middle width={HEADER_LOGIN_WIDTH}
                height={HEADER_LOGIN_HEIGHT}
              >
                <Image source={{ uri: currentPathfinder?.logo }} style={{ width: '65%', height: '80%' }} resizeMode='contain' />
              </Col>
            </>
          )}
        </Animated.View>
      </>
    );
  };

  return (
    <Col flex1 backgroundColor={COLOR.GREY_BG}>
      {renderHeader()}
      <Col stretch flex1>
        <MainContent
          style={shouldDoExpansionAnim ? {
            position: 'absolute',
            left: mainAnimated.left,
            top: mainAnimated.top,
            right: 0,
            bottom: 0,
          } : {
            flex: 1,
            height: '100%',
          }}
        >
          <CMSMainContent>
            {mainContent}
          </CMSMainContent>
        </MainContent>
      </Col>
    </Col>
  );
};

export default LearnerLayout;
