import ReactSelect, { MultiValueGenericProps, components } from "react-select";
import { useCallback, useMemo, useState } from "react";
import { isString } from "lodash";
import { Tooltip } from 'react-tippy';
import React from "react";
import { COLOR } from "const";
import { TLearning } from "type";
import { Col, IColProps, Row, Text } from "components/base";
import Store from "store";
import shallow from "zustand/shallow";
import { LayoutChangeEvent, ScrollView, useWindowDimensions } from "react-native";
import { modal } from "../modal";
import Input from "./Input";
import { FontAwesome5 } from "@expo/vector-icons";
import TouchField from "../button/TouchField";

interface Props extends IColProps {
  onChange?: any;
  value?: string;
  showFull?: boolean;
  limitedHeight?: number;
  onReachLimitHeight?: () => void;
  [anyprop: string]: any;
}

let preventModalTs = 0;

const ImageWithFallbackIcon = ({ url, label }: any) => {
  const [isError, setIsError] = useState(false);
  const commnStyles = {
    borderRadius: 8,
    marginRight: 10,
    width: 20,
    height: 20,
  };
  return isError ? (
    <div style={{ backgroundColor: "rgba(0,0,0,0.05)", ...commnStyles }} />
  ) : (
    <img
      src={url}
      alt={label}
      width={20}
      height={20}
      style={commnStyles}
      onError={() => setIsError(true)}
    />
  );
};

const ModalSelect = (props: any) => {
  const { selecteds, onChange } = props;
  const { width, height } = useWindowDimensions();
  const [isTagFocused, setTagFocused] = useState(false);
  const [showTagMenu, setShowTagMenu] = useState(false);
  const [selectedIds, setSelectedIds] = useState(selecteds?.split(',')?.map(i => i?.trim()) || []);
  const [inputValue, setInputValue] = useState('');
  const [selectedTag, setSelectedTag] = useState(null);
  const { learningIds, learnings } = Store.useLearningStore(state => ({
    learnings: state.learnings,
    learningIds: state.learningIds,
  }), shallow);

  const onSelectLearning = useCallback((learning) => {
    let _selectedIds;
    if (selectedIds.includes(learning.id)) {
      _selectedIds = selectedIds.filter(i => i && i !== learning.id);
    } else {
      _selectedIds = selectedIds.concat(learning.id).filter(Boolean);
    }
    setSelectedIds(_selectedIds);
  }, [selectedIds]);

  const tags = useMemo(() => {
    const arr = ['All tags'];
    for (let id of learningIds) {
      learnings[id]?.tags?.split(',')?.forEach(tag => {
        if (tag && !arr.includes(tag?.trim())) {
          arr.push(tag?.trim());
        }
      });
    }
    return arr.map(i => ({
      label: i,
      value: i,
    }));
  }, [learningIds]);

  const displayLearnings = useMemo(() => {
    let all = learningIds?.map(i => learnings[i]);
    if (inputValue) {
      all = all.filter(i => i.name && i.name.toLowerCase().includes(inputValue.toLowerCase()));
    }
    if (selectedTag && selectedTag.value !== 'All tags') {
      all = all.filter(i => i.tags?.includes(selectedTag.value));
    }
    return all;
  }, [learningIds, learnings, inputValue, selectedTag]);

  const onConfirm = () => {
    onChange?.(selectedIds);
    modal.hide();
  }

  const isSelectedAll = useMemo(() => !displayLearnings.some(i => !selectedIds?.includes(i.id)), [displayLearnings, selectedIds])

  const onPressSelectAll = () => {
    const _selectedIds = isSelectedAll ? [] : displayLearnings.map(learning => learning.id);
    setSelectedIds(_selectedIds);
  }

  const onBlurTagInput = () => {
    setTagFocused(false);
    setShowTagMenu(false);
  }

  const onFocusTagInput = () => {
    setTagFocused(true);
    setTimeout(() => {
      setShowTagMenu(true);
    }, 200);
  }

  return (
    <Col padding={24} borderRadius={8} bgWhite width={Math.min(500, width - 32)}>
      <Row alignItems='center' justifyContent='space-between' marginBottom={24}>
        <Text fontSize={20} semiBold color={COLOR.MAIN}>Select learning</Text>
        {/* <TouchField padding={4} onPress={() => modal.hide()} nativeID="btn-close-modal">
          <FontAwesome5 name='times' size={18} color={COLOR.MAIN} />
        </TouchField> */}
      </Row>
      <Row width100p borderRadius={20} backgroundColor={COLOR.GREY_LIGHT} marginBottom={8} paddingRight={2}>
        <Input
          height={40}
          flex1
          value={inputValue}
          onChange={setInputValue}
          borderColor='transparent'
          inputProps={{
            style: {
              // @ts-ignore
              outline: 'none',
              fontSize: 14,
            },
            placeholderTextColor: COLOR.GREY,
          }}
          placeholder='Search'
        />
        <ReactSelect
          value={selectedTag}
          onChange={setSelectedTag}
          styles={{
            control: (style) => ({
              ...style,
              height: 36,
              minHeight: 36,
              borderRadius: 18,
              backgroundColor: 'white',
              borderColor: 'transparent',
              boxShadow: 'none',
              minWidth: isTagFocused ? 200 : 90,
            }),
            menu: (style) => ({
              ...style,
              minWidth: 200,
            }),
            clearIndicator: (provided) => ({
              ...provided,
              visibility: 'hidden',
              width: 0,
            }),
            valueContainer: (provided) => ({
              ...provided,
              paddingLeft: 6,
              paddingTop: 0,
              paddingBottom: 0,
              fontSize: 14,
              marginRight: -10,
            }),
            input: styles => ({ ...styles, outline: 'none' }),
            indicatorSeparator: (style) => ({ display: 'none' }),
          }}
          menuIsOpen={showTagMenu}
          onFocus={onFocusTagInput}
          onBlur={onBlurTagInput}
          placeholder='All tags'
          options={tags}
          menuPortalTarget={document.querySelector('body')}
        />
      </Row>
      <Row justifyContent={'flex-end'}>
        <TouchField
          onPress={onPressSelectAll}
          padding={6}
          flexDirection={'row'}
          alignItems={'center'}
        >
          <Text color={COLOR.MAIN}>{isSelectedAll ? 'Deselect all' : 'Select all'}</Text>
        </TouchField>
      </Row>
      <ScrollView style={{ maxHeight: Math.min(500, height - 200) }}>
        {displayLearnings?.map((learning, idx) => {
          return (
            <TouchField key={`learning-item-${idx}`} nativeID={`learning-item-${idx}`} onPress={() => onSelectLearning(learning)} marginTop={8}>
              <Row
                borderWidth={1} borderColor={COLOR.BORDER_LIGHT} borderRadius={8}
                paddingHorizontal={16} paddingVertical={12}
              >
                {/* <Image source={{ uri: learning.icon }} style={{ width: 40, height: 40, borderRadius: 8 }} /> */}
                <Text flex={1} marginLeft={8} fontWeight={500}>{learning.name}</Text>
                {selectedIds?.includes(learning.id) &&
                  <FontAwesome5 name='check' size={14} color={COLOR.MAIN} />
                }
              </Row>
            </TouchField>
          )
        })}
      </ScrollView>
      <Row justifyContent={'flex-end'} marginTop={24}>
        <TouchField
          height={40}
          width={80}
          middle
          borderColor={COLOR.MAIN}
          borderRadius={20}
          borderWidth={1}
          onPress={() => modal.hide()}
          m0 ph2
          nativeID="btn-close-modal"
        >
          <Text color={COLOR.MAIN}>Cancel</Text>
        </TouchField>
        <TouchField
          height={40}
          middle
          borderColor={COLOR.MAIN}
          borderRadius={20}
          borderWidth={1}
          backgroundColor={COLOR.MAIN}
          m0 ph2
          onPress={onConfirm}
          nativeID="btn-confirm-modal"
        >
          <Row>
            <Text color={COLOR.WHITE}>Apply</Text>
          </Row>
        </TouchField>
      </Row>
    </Col>
  )
}

const MultiValueContainer = (props: MultiValueGenericProps<{ label: string, value: string }>) => {
  const { label } = props.data || {};
  return (
    <Tooltip
      title={label}
      style={{ maxWidth: '100%' }}
      trigger="mouseenter"
    >
      <components.MultiValueContainer {...props} />
    </Tooltip>
  );
};

const LearningSelect = (props: Props) => {
  const { value, onChange, options, showFull, limitedHeight, onReachLimitHeight, ...restProp } = props;
  const { learnings } = Store.useLearningStore(state => ({
    learnings: state.learnings,
  }), shallow);

  const onLayout = useCallback((e: LayoutChangeEvent) => {
    if (limitedHeight) {
      if (e.nativeEvent.layout.height - 5 > limitedHeight) {
        onReachLimitHeight?.();
      }
    }
  }, [limitedHeight, onReachLimitHeight])

  const _value = useMemo(() => {
    if (!value) return [];
    if (!isString(value)) return [];
    return value.split(',').map(i => ({
      label: learnings[i]?.name,
      value: i,
      data: learnings[i],
    }));
  }, [value, learnings])

  const onPress = useCallback(() => {
    if (new Date().getTime() - preventModalTs > 1000) {
      modal.show(<ModalSelect selecteds={value} onChange={onChange} />)
    }
  }, [value, onChange])

  const onChangeValue = useCallback((newVal) => {
    if (newVal?.length < value.length) {
      preventModalTs = new Date().getTime();
      onChange?.(newVal?.map(i => i.value));
    }
  }, [value]);

  return (
    <>
      <Col dataSet={{ element: 'select-learnings' }} onPress={onPress} {...props} onLayout={onLayout}>
        <ReactSelect
          value={_value}
          isMulti
          onFocus={onPress}
          onChange={onChangeValue}
          components={{ MultiValueContainer }}
          menuIsOpen={false}
          styles={{
            control: (style) => ({
              ...style,
              height: 'auto',
              minHeight: 36,
              borderRadius: 8,
              backgroundColor: 'transparent',
              borderColor: 'transparent',
              boxShadow: 'none',
              '&:hover': {
                borderColor: 'transparent',
              },
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              visibility: 'hidden',
              width: 0,
            }),
            clearIndicator: (provided) => ({
              ...provided,
              visibility: 'hidden',
              width: 0,
            }),
            indicatorsContainer: (provided) => ({
              ...provided,
              width: 0,
            }),
            input: styles => ({ ...styles, outline: 'none' }),
            indicatorSeparator: (style) => ({ display: 'none' }),
            placeholder: (style) => ({
              ...style,
              fontSize: 12,
              color: COLOR.FONT,
            }),
            valueContainer: (provided) => ({
              ...provided,
              maxHeight: showFull ? undefined : limitedHeight,
            }),
            // multiValueRemove: () => ({ display: 'none' }),
          }}
          placeholder=''
          options={options}
          menuPortalTarget={document.querySelector('body')}
        />
      </Col>
      <Text
        textDecorationLine='underline'
        marginLeft={8}
        marginVertical={2}
        onPress={onPress}>
        + Add learning
      </Text>
    </>
  );
};

export default LearningSelect;
