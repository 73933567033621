export * from './screens';

export const FONT = {
  defaultFont: 'Raleway, sans-serif',
  bold: 'Raleway, sans-serif',
  light: 'Raleway, sans-serif',
};

export const COLOR = {
  FONT: 'black',
  // MAIN: '#003846',
  MAIN: '#35b3ae',
  BORDER: '#707070',
  BLUE: '#005EB8',
  YELLOW: '#FFF100',
  GREY_BG: '#F5F5F5',
  GREEN: '#49D009',
  GREY: '#B9B9B9',
  GREY_LIGHT: '#F0F4F6',
  MAIN_TEXT: '#052728',
  SUB_TEXT: '#477B7E',
  RED: 'red',
  MASK: 'rgba(255,255,255,0.5)',
  WHITE: '#FFFFFF',
  BORDER_LIGHT: 'rgba(0,0,0,0.08)',
  BG_TABLE_ROW: 'rgba(242, 248, 249, 1)',
}

export const INCH_TO_MM = 25.4;
