import React, { useState } from 'react';
import { Col, IColProps, Text, RowTableLoading, Row, TouchField, ElementSelect, LearningSelectModal, modalConfirm } from 'components';
import { TStatement } from 'type';
import Store from 'store';
import { useStatements } from 'store/Statement.Store';
import { LineHorizontal, LineVertical, RowInput, RowTable } from './Commons';
import shallow from 'zustand/shallow';
import { COLOR } from 'const';
import { Feather, FontAwesome5, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Tooltip } from 'react-tippy';

interface Props extends IColProps {
  pathfinderId: string
}

const SELECTOR_LIMIT_H = 80;

export const StatementItem = ({ item, pathfinderId, editStatement }) => {
  const [expaned, setExpand] = useState(false);
  const [expandMode, setExpandMode] = useState(false);

  const showExpandMode = () => {
    setExpandMode(true);
  };

  const limited = expandMode && !expaned;

  return (
    <Col paddingBottom={8} marginTop={8} borderBottomWidth={2} borderColor={COLOR.BORDER}>
      <RowTable backgroundColor={COLOR.BG_TABLE_ROW}>
        <Col width={150}>
          <Text caption bold>Statement</Text>
        </Col>
        <LineVertical />
        <Col flex1 ml0>
          <RowInput
            value={item.statement}
            onChangeText={(text) => editStatement(item.id, 'statement', text)}
          />
        </Col>
      </RowTable>
      <RowTable>
        <Col width={150}>
          <Text caption bold>Elements</Text>
        </Col>
        <LineVertical />
        <Col flex1>
          <ElementSelect
            pathfinderId={pathfinderId}
            value={item.elementIds}
            onReachLimitHeight={showExpandMode}
            showFull={!limited}
            limitedHeight={SELECTOR_LIMIT_H + 32}
            onChange={(opts) => {
              editStatement(item.id, 'elementIds', opts.map(i => i.value).join(','))
            }}
            hasAllOpt
          />
        </Col>
      </RowTable>
      <RowTable backgroundColor={COLOR.BG_TABLE_ROW}>
        <Col width={150} paddingVertical={4}>
          <Text caption bold>Rollovers{`\n(Left-Middle-Right)`}</Text>
        </Col>
        <LineVertical />
        <Col flex1 ml0>
          <Row>
            <Col flex1 borderRightWidth={1} borderColor={COLOR.BORDER}>
              <RowInput value={item.rolloverLikert1} onChangeText={(text) => editStatement(item.id, 'rolloverLikert1', text)} />
            </Col>
            <Col flex1 borderRightWidth={1} borderColor={COLOR.BORDER}>
              <RowInput value={item.rolloverLikert2} onChangeText={(text) => editStatement(item.id, 'rolloverLikert2', text)} />
            </Col>
            <Col flex1>
              <RowInput value={item.rolloverLikert3} onChangeText={(text) => editStatement(item.id, 'rolloverLikert3', text)} />
            </Col>
          </Row>
        </Col>
      </RowTable>
      <RowTable>
        <Col width={150} alignSelf='flex-start' paddingVertical={4}>
          <Text caption bold>Learnings{`\n(Left-Middle-Right)`}</Text>
        </Col>
        <LineVertical />
        <Col flex1 ml0>
          <Row alignItems='flex-start' maxHeight={limited ? SELECTOR_LIMIT_H + 20 : undefined}>
            <Col flex={1} borderRightWidth={1} borderColor={COLOR.BORDER}>
              <LearningSelectModal
                onReachLimitHeight={showExpandMode}
                showFull={!limited}
                limitedHeight={SELECTOR_LIMIT_H}
                value={item.learningIdsLikert1}
                onChange={(ids) => editStatement(item.id, 'learningIdsLikert1', ids.join(','))}
              />
            </Col>
            {/* <LineVertical /> */}
            <Col flex={1} borderRightWidth={1} borderColor={COLOR.BORDER}>
              <LearningSelectModal
                onReachLimitHeight={showExpandMode}
                showFull={!limited}
                limitedHeight={SELECTOR_LIMIT_H}
                value={item.learningIdsLikert2}
                onChange={(ids) => editStatement(item.id, 'learningIdsLikert2', ids.join(','))}
              />
            </Col>
            {/* <LineVertical /> */}
            <Col flex={1}>
              <LearningSelectModal
                onReachLimitHeight={showExpandMode}
                showFull={!limited}
                limitedHeight={SELECTOR_LIMIT_H}
                value={item.learningIdsLikert3}
                onChange={(ids) => editStatement(item.id, 'learningIdsLikert3', ids.join(','))}
              />
            </Col>
          </Row>
          {expandMode ?
            <Row middle bgWhite>
              <TouchField onPress={() => setExpand(s => !s)}>
                <Row middle p1>
                  <Text color={COLOR.MAIN}>{expaned ? 'Hide' : 'Show more'} </Text>
                  <Ionicons name={expaned ? 'chevron-up-outline' : 'chevron-down-outline'} size={12} color={COLOR.MAIN} />
                </Row>
              </TouchField>
            </Row>
            :
            <Col height={10} />
          }
        </Col>
      </RowTable>
    </Col>
  )
}

const TabStatements = ({ pathfinderId }: Props) => {
  const statements: TStatement[] = useStatements(pathfinderId);

  const { loading: loadingStatements, editStatement, reOrderStatements, selectedStatement, selectStatement, selectModePathfinder, setStatementStore, getStatements } = Store.useStatementStore(state => ({
    loading: state.loading,
    editStatement: state.editStatement,
    reOrderStatements: state.reOrderStatements,
    selectStatement: state.selectStatement,
    selectedStatement: state.selectedStatement,
    selectModePathfinder: state.selectModePathfinder,
    setStatementStore: state.set,
    getStatements: state.getStatements,
  }), shallow);

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    let updatedList = [...statements];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    reOrderStatements(updatedList);
  };

  const confirmDelete = () => {
    modalConfirm.show({
      title: 'Delete',
      message: `Do you want to delete ${selectedStatement.length} statement${selectedStatement.length > 1 ? 's' : ''}?`,
      onConfirm: async () => {
        await Promise.all(selectedStatement.map(id =>
          Store.Api.Statement.remove({ id })
        ))
        setStatementStore({ selectedStatement: [] });
        await getStatements(pathfinderId);
      }
    })
  }

  const selectedAll = !statements.some(i => !selectedStatement.includes(i.id));

  const onSelectAll = () => {
    const newValue = selectedAll ? [] : statements.map(i => i.id);
    setStatementStore({ selectedStatement: newValue });
  }

  return (
    <>
      {selectModePathfinder === pathfinderId && (
        <Row mb1 backgroundColor={COLOR.BG_TABLE_ROW} borderRadius={8} padding={8} alignItems={'center'}>
          <Row flex1>
            {selectedStatement?.length > 0 && selectModePathfinder === pathfinderId &&
              <Row alignItems="center" flex1 justifyContent="space-between">
                <Text semiBold color={COLOR.MAIN}>{selectedStatement.length} {selectedStatement.length > 1 ? 'items' : 'item'} selected</Text>
                <Tooltip style={{ alignSelf: "center" }} trigger="mouseenter" title={selectedStatement?.length > 1 ? "Delete statements" : "Delete statement"}>
                  <TouchField borderRadius={8} alignSelf={'center'} onPress={confirmDelete}>
                    <Row middle paddingHorizontal={12} paddingVertical={8}>
                      {/* <Text color={COLOR.RED}>Delete </Text> */}
                      <FontAwesome5 name='trash' size={16} color={COLOR.RED} />
                    </Row>
                  </TouchField>
                </Tooltip>
              </Row>
            }
          </Row>
          <TouchField
            onPress={onSelectAll}
            padding={6}
            marginLeft={8}
            alignItems="center"
            flexDirection="row"
          >
            <Text marginRight={8} caption>{!selectedAll ? "Select" : "Deselect"} all</Text>
            <FontAwesome5
              name={selectedAll ? 'check-square' : 'square'}
              size={20}
              solid={selectedAll}
              color={COLOR.MAIN}
            />
          </TouchField>
        </Row>
      )}
      {loadingStatements?.[pathfinderId] ?
        <RowTableLoading numOfCol={4} />
        :
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId="list-statement-container">
            {(provided) => (
              <div
                className="list-statement-container"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {statements?.map((i, idx) => (
                  <Draggable key={i.id} draggableId={i.id} index={idx}>
                    {(provided) => (
                      <div
                        className="statement-item-container"
                        ref={provided.innerRef}
                        {...provided.draggableProps}>
                        <Row>
                          <div {...provided.dragHandleProps}>
                            <Feather
                              name="move"
                              size={18}
                              color={COLOR.SUB_TEXT}
                            />
                          </div>
                          <Col flex1>
                            <StatementItem
                              key={i.id}
                              item={i}
                              pathfinderId={pathfinderId}
                              editStatement={editStatement}
                            />
                          </Col>
                          {selectModePathfinder === pathfinderId &&
                            <TouchField
                              onPress={() => selectStatement(i.id)}
                              padding={6}
                              marginRight={4}
                              marginTop={6}
                            >
                              <FontAwesome5
                                name={selectedStatement.includes(i.id) ? 'check-square' : 'square'}
                                size={20}
                                solid={selectedStatement.includes(i.id)}
                                color={COLOR.MAIN}
                              />
                            </TouchField>
                          }
                        </Row>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      }
    </>
  )
};

export default TabStatements;
