import React from 'react';
import Navigation from './src/navigation/Navigation';
import { ConfirmModal, ModalContainer } from './src/components';
import './App.css';
import 'react-tippy/dist/tippy.css';

export default function AppWeb() {
  return (
    <ConfirmModal>
      <ModalContainer>
        <Navigation />
      </ModalContainer>
    </ConfirmModal>
  );
}
