import { Col, Text, CMSLayout, Table, Row, TouchField, modal, modalConfirm, ClientDetailModal } from 'components';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { IScreen, TUser } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { COLOR } from 'const';
import { FontAwesome5 } from '@expo/vector-icons';
import { Tooltip } from 'react-tippy';

const ListClients: IScreen = () => {
  const { navigate } = useNavFunc();
  const [clients, setClients] = useState<TUser[]>([]);
  const [loading, setLoading] = useState(false);
  const { user } = Store.useUserStore();

  const fetchData = useCallback(async () => {
    if (loading) return;
    try {
      setLoading(true);
      const res = await Store.Api.Client.list({});
      if (res.data.data && res.data.success) {
        setClients(res.data.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [loading])

  useEffect(() => {
    if (user?.id) {
      fetchData()
    }
  }, [user?.id])

  const showModalCreateAdmin = useCallback(() => {
    modal.show(
      <ClientDetailModal
        title='Create Client'
        data={undefined}
        refresh={fetchData}
      />
    )
  }, [fetchData])

  const tableRows = useMemo(() => {
    if (!clients) return [];
    const onPressDelete = (client) => {
      modalConfirm.show({
        title: 'Delete',
        message: 'Do you want to delete this client',
        onConfirm: async () => {
          await Store.Api.Client.remove({
            id: client.id,
          });
          fetchData()
        }
      })
    }
    const onPressEdit = (client) => {
      modal.show(
        <ClientDetailModal
          title='Edit Client'
          data={client}
          refresh={fetchData}
        />
      )
    }

    return clients.map(i => ({
      ...i,
      actions: <Row justifyContent='flex-end'>
        <Tooltip trigger="mouseenter" title={"Delete client"}>
          <TouchField paddingHorizontal={12} paddingVertical={8} onPress={() => onPressDelete(i)}>
            <FontAwesome5 name='trash' size={16} color={COLOR.RED} />
            {/* <Text color={COLOR.RED}>Delete</Text> */}
          </TouchField>
        </Tooltip>
        <Tooltip trigger="mouseenter" title={"Edit client"}>
          <TouchField paddingHorizontal={12} paddingVertical={8} onPress={() => onPressEdit(i)}>
            <FontAwesome5 name='pen' size={16} color={COLOR.MAIN} />
            {/* <Text color={COLOR.MAIN}>Edit</Text> */}
          </TouchField>
        </Tooltip>
      </Row>
    }))
  }, [clients, fetchData])

  return (
    <CMSLayout requireAuthen>
      <Col flex1 m2 p2 round1 bgWhite shadow>
        <Row alignItems={'flex-start'} justifyContent={'space-between'}>
          <Text h4 mb2 nativeID={'heading'}>Clients</Text>
          <Row>
            <TouchField
              marginLeft={8}
              width={36} height={36}
              borderRadius={18} middle
              onPress={showModalCreateAdmin}
              dataSet={{ element: 'add-pathfinder' }}
            >
              <FontAwesome5 name='plus-circle' size={24} color={COLOR.MAIN} />
            </TouchField>
          </Row>
        </Row>
        <Table
          columns={[
            { key: 'name', title: 'Name', flex: 1 },
            { key: 'location', title: 'Location', flex: 1 },
            { key: 'actions', title: '', width: 150 },
          ]}
          data={tableRows}
          style={{ margin: 20 }}
          minWidthRequired={500}
          isLoading={loading}
          rowProps={{
            paddingVertical: 16,
          }}
        />
      </Col>
    </CMSLayout>
  )
};

ListClients.routeInfo = {
  title: 'Pathfinder - List Client',
  path: '/clients',
};

export default ListClients;
