import ReactSelect from "react-select";
import { useMemo, useState } from "react";
import React from "react";
import { COLOR } from "const";
import { Col, Row, Text } from "components/base";
import Store from "store";
import shallow from "zustand/shallow";
import { ScrollView, useWindowDimensions } from "react-native";
import { modal } from "../modal";
import Input from "./Input";
import { FontAwesome5 } from "@expo/vector-icons";
import TouchField from "../button/TouchField";

const PathfinderSelectModal = (props: any) => {
  const { onChange, selectedId } = props;
  const { width, height } = useWindowDimensions();
  const [inputValue, setInputValue] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);
  const { clients, clientIds } = Store.useClientStore(state => ({
    clients: state.clients,
    clientIds: state.clientIds,
  }), shallow);
  const { pathfinders } = Store.usePathfinderStore((state) => ({
    pathfinders: state.pathfinders,
  }), shallow);

  const clientOpts = useMemo(() => {
    const arr = [{ value: "all", label: "All clients" }];
    for (let id of clientIds) {
      arr.push({
        value: id,
        label: clients[id]?.name,
      });
    }
    return arr;
  }, [clientIds, clients]);

  const displayPathfinders = useMemo(() => {
    let all = Object.keys(pathfinders).map(i => pathfinders[i]);
    if (inputValue) {
      all = all.filter(i => i.name && i.name.toLowerCase().includes(inputValue.toLowerCase()));
    }
    if (selectedClient && selectedClient.value !== "all") {
      all = all.filter(i => i.clientId === selectedClient.value);
    }
    return all;
  }, [pathfinders, selectedClient, inputValue]);

  return (
    <Col padding={24} borderRadius={8} bgWhite width={Math.min(500, width - 32)}>
      <Row alignItems='center' justifyContent='space-between' marginBottom={24}>
        <Text fontSize={20} semiBold color={COLOR.MAIN}>Select pathfinder</Text>
        <TouchField padding={4} onPress={() => modal.hide()} nativeID="btn-close-modal">
          <FontAwesome5 name='times' size={18} color={COLOR.MAIN} />
        </TouchField>
      </Row>
      <Row width100p borderRadius={20} backgroundColor={COLOR.GREY_LIGHT} marginBottom={8} paddingRight={2}>
        <Input
          height={40}
          flex1
          value={inputValue}
          onChange={setInputValue}
          borderColor='transparent'
          inputProps={{
            style: {
              // @ts-ignore
              outline: 'none',
              fontSize: 14,
            },
            placeholderTextColor: COLOR.GREY,
          }}
          placeholder='Search'
        />
        <ReactSelect
          value={selectedClient}
          onChange={setSelectedClient}
          styles={{
            control: (style) => ({
              ...style,
              height: 36,
              minHeight: 36,
              borderRadius: 18,
              backgroundColor: 'white',
              borderColor: 'transparent',
              boxShadow: 'none',
              minWidth: 90,
            }),
            clearIndicator: (provided) => ({
              ...provided,
              visibility: 'hidden',
              width: 0,
            }),
            valueContainer: (provided) => ({
              ...provided,
              paddingLeft: 6,
              paddingTop: 0,
              paddingBottom: 0,
              fontSize: 14,
              marginRight: -10,
            }),
            input: styles => ({ ...styles, outline: 'none' }),
            indicatorSeparator: (style) => ({ display: 'none' }),
          }}
          placeholder='All clients'
          options={clientOpts}
          menuPortalTarget={document.querySelector('body')}
        />
      </Row>
      <ScrollView style={{ maxHeight: Math.min(500, height - 200), minHeight: 180 }}>
        {displayPathfinders?.map((pathfinder, idx) => {
          return (
            <TouchField key={`pathfinder-item-${idx}`} nativeID={`pathfinder-item-${idx}`} onPress={() => onChange(pathfinder.id)} marginTop={8}>
              <Row
                borderWidth={1} borderColor={COLOR.BORDER_LIGHT} borderRadius={8}
                paddingHorizontal={16} paddingVertical={12}
              >
                <Text flex={1} marginLeft={8} fontWeight={500}>{pathfinder.name || pathfinder.id}</Text>
                {selectedId === pathfinder.id &&
                  <FontAwesome5 name='check' size={14} color={COLOR.MAIN} />
                }
              </Row>
            </TouchField>
          )
        })}
      </ScrollView>
    </Col>
  )
}

export default PathfinderSelectModal;
