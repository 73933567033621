import ReactSelect from "react-select";
import { useCallback, useMemo, useState } from "react";
import { isString } from "lodash";
import React from "react";
import { COLOR } from "const";
import { TLearning } from "type";
import { Col, IColProps } from "components/base";
import Store from "store";
import shallow from "zustand/shallow";
import { LayoutChangeEvent } from "react-native";

type TOption = { label: string; value: string; data: TLearning };

interface Props extends IColProps {
  options: Array<TOption>;
  onChange: any;
  value: string;
  showFull?: boolean;
  limitedHeight?: number;
  onReachLimitHeight?: () => void;
  [anyprop: string]: any;
}

const ImageWithFallbackIcon = ({ url, label }: any) => {
  const [isError, setIsError] = useState(false);
  const commnStyles = {
    borderRadius: 8,
    marginRight: 10,
    width: 20,
    height: 20,
  };
  return isError ? (
    <div style={{ backgroundColor: "rgba(0,0,0,0.05)", ...commnStyles }} />
  ) : (
    <img
      src={url}
      alt={label}
      width={20}
      height={20}
      style={commnStyles}
      onError={() => setIsError(true)}
    />
  );
};

const LearningSelect = (props: Props) => {
  const { value, onChange, options, showFull, limitedHeight, onReachLimitHeight, ...restProp } = props;
  const [inputValue, setInputValue] = useState('');

  const { learnings } = Store.useLearningStore(state => ({
    learnings: state.learnings,
  }), shallow);

  const onLayout = useCallback((e: LayoutChangeEvent) => {
    if (limitedHeight) {
      if (e.nativeEvent.layout.height > limitedHeight) {
        onReachLimitHeight?.();
      }
    }
  }, [limitedHeight, onReachLimitHeight])

  const _value = useMemo(() => {
    if (!value) return [];
    if (!isString(value)) return [];
    return value.split(',').map(i => ({
      label: learnings[i]?.name,
      value: i,
      data: learnings[i],
    }));
  }, [value])

  const onInputChange = (newValue: string) => {
    if (newValue?.length > 1 && newValue.includes(',')) {
      const newValues: TOption[] = _value;
      newValue.split(',').forEach(i => {
        if (i && !newValues.some(tag => tag.label === i.trim())) {
          newValues.push({
            label: i.trim(),
            value: i.trim(),
            data: null,
          });
        }
      });
      onChange(newValues);
      setInputValue('');
      return;
    }
    setInputValue(newValue);
  }

  return (
    <Col dataSet={{ element: 'select-learnings' }} {...props} onLayout={onLayout}>
      <ReactSelect
        value={_value}
        isMulti
        styles={{
          control: (style) => ({
            ...style,
            height: 'auto',
            minHeight: 36,
            borderRadius: 8,
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            boxShadow: 'none',
            '&:hover': {
              borderColor: 'transparent',
            },
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            visibility: 'hidden',
            width: 0,
          }),
          clearIndicator: (provided) => ({
            ...provided,
            visibility: 'hidden',
            width: 0,
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            width: 0,
          }),
          input: styles => ({ ...styles, outline: 'none' }),
          indicatorSeparator: (style) => ({ display: 'none' }),
          placeholder: (style) => ({
            ...style,
            fontSize: 12,
            color: COLOR.FONT,
          }),
          valueContainer: (provided) => ({
            ...provided,
            maxHeight: showFull ? undefined : limitedHeight,
          })
        }}
        onInputChange={onInputChange}
        onChange={onChange}
        placeholder='Add learning'
        options={options}
        inputValue={inputValue}
        menuPortalTarget={document.querySelector('body')}
        formatOptionLabel={(value: TOption) => {
          return (
            <div
              className="user-option"
              style={{ display: "flex", alignItems: "center" }}
            >
              <ImageWithFallbackIcon
                url={value.data?.icon}
                label={value.label}
              />
              <span>{value.label}</span>
            </div>
          );
        }}
      />
    </Col>
  );
};

export default LearningSelect;
