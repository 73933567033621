import { write, utils } from "xlsx";
import { saveAs } from 'file-saver';
import { PathfinderImportItem } from "type";

const fieldNames = {
  name: "Name",
  email: "Email",
  pathfinderName: "Pathfinder Name",
  statement: "Statement",
  likert: "Likert",
  createdAt: "Date Created",
  learnings: "Learnings",
};

const infoIdx = {
  name: {
    idx: 0,
    label: 'Name',
  },
  welcomeInstruction: {
    idx: 1,
    label: 'Welcome Instruction',
  },
  additionalInstruction: {
    idx: 2,
    label: 'Additional Instruction',
  },
  completionInstruction: {
    idx: 3,
    label: 'Completion Dialog Instructions',
  },
  likertScale: {
    idx: 4,
    label: 'Likert Scale',
  },
  elementsTitle: {
    idx: 5,
    label: 'Screen Element Title',
  },
}

const elementColIdx = {
  name: {
    idx: 3,
    label: 'Name',
  },
  rolloverText: {
    idx: 4,
    label: 'Rollover Text',
  },
  filters: {
    idx: 5,
    label: 'Filters',
  },
  image: {
    idx: 6,
    label: 'Image',
  },
}

const statementColIdx = {
  statement: {
    idx: 7,
    label: 'Statement',
  },
  rollover1: {
    idx: 8,
    label: 'Rollover Left',
  },
  learning1: {
    idx: 9,
    label: 'Left learning',
  },
  rollover2: {
    idx: 10,
    label: 'Rollover Middle',
  },
  learning2: {
    idx: 11,
    label: 'Middle learning',
  },
  rollover3: {
    idx: 12,
    label: 'Rollover Right',
  },
  learning3: {
    idx: 13,
    label: 'Right learning',
  },
  element: {
    idx: 14,
    label: 'Element',
  },
}

class ExportHelper {
  saveDataToFile = (data: PathfinderImportItem[]) => {
    const numOfCol = 15;
    const numOfRows = data.map(i => Math.max(6, i.elements?.length + 2, i.statements?.length + 2));
    const numOfRow = numOfRows.reduce((a, b) => a + b, 0);
    const worksheet = utils.aoa_to_sheet(Array.from({ length: numOfRow }, () => new Array(numOfCol).fill('')));

    const editCell = (r, c, values, styles = undefined) => {
      const cellAddress = utils.encode_cell({ r, c });
      worksheet[cellAddress] = {
        ...values,
        s: { wrapText: true, ...styles },
      };
    }
    const merges = [];

    const columnWidths = [
      { wch: 30 },
      { wch: 10 },
      { wch: 35 },
      { wch: 20 },
      { wch: 25 },
      { wch: 15 },
      { wch: 15 },
      { wch: 40 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
    ];

    worksheet['!cols'] = columnWidths;
    worksheet['!rows'] = new Array(numOfRow).fill({ hpx: 20 });
    numOfRows.map((maxRows, idx) => {
      const pathfinder = data[idx] as PathfinderImportItem;
      const startRow = [...numOfRows].splice(0, idx).reduce((a, b) => a + b, 0) + 1 * idx;
      const endRow = startRow + maxRows - 1;
      // logo
      editCell(startRow, 0, {
        v: 'Logo',
      }, { textDecoration: 'Bold' });
      merges.push(
        { s: { r: startRow + 1, c: 0 }, e: { r: endRow, c: 0 } },
      );
      editCell(startRow + 1, 0, {
        // f: pathfinder.logo ? `IMAGE("${pathfinder.logo}", 1)` : '',
        v: pathfinder.logo,
      });
      // info
      Object.keys(infoIdx).forEach((key) => {
        editCell(startRow + infoIdx[key].idx, 1, {
          v: infoIdx[key].label || '',
        }, { textDecoration: 'Bold' });
        editCell(startRow + infoIdx[key].idx, 2, {
          v: pathfinder[key] || '',
        });
      })
      // elements
      editCell(startRow, 3, {
        v: 'Screen Elements',
      }, { textDecoration: 'Bold' });
      Object.keys(elementColIdx).forEach((key) => {
        editCell(startRow + 1, elementColIdx[key].idx, {
          v: elementColIdx[key].label || '',
        }, { textDecoration: 'Bold' });
      })
      pathfinder.elements?.forEach((element, idx) => {
        Object.keys(elementColIdx).forEach((key) => {
          // const value = key === 'image' ? {
          //   f: element[key] ? `IMAGE("${element[key]}", 1)` : '',
          // } : {
          //   v: element[key] || '',
          // }
          editCell(startRow + 2 + idx, elementColIdx[key].idx, {
            v: element[key] || '',
          });
        })
      })
      // statements
      editCell(startRow, 7, {
        v: 'Work statements',
      }, { textDecoration: 'Bold' });
      Object.keys(statementColIdx).forEach((key) => {
        editCell(startRow + 1, statementColIdx[key].idx, {
          v: statementColIdx[key].label || '',
        }, { textDecoration: 'Bold' });
      })
      pathfinder.statements?.forEach((element, idx) => {
        Object.keys(statementColIdx).forEach((key) => {
          editCell(startRow + 2 + idx, statementColIdx[key].idx, {
            v: element[key] || '',
          });
        })
      })
    })
    worksheet['!merges'] = merges;
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = write(workbook, { type: 'array' });
    const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(excelBlob, 'exported-pathfinders.xlsx');
  }

  saveArrayToFile = (data: any[], options?: any) => {
    const formatedData = data.map(i => {
      const newObj = {};
      Object.keys(i).forEach(key => {
        newObj[fieldNames[key] || key] = i[key];
      })
      return newObj;
    })
    const worksheet = utils.json_to_sheet(formatedData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = write(workbook, { type: 'array' });
    const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(excelBlob, options?.fileName || 'exported.xlsx');
  }
}

export default new ExportHelper();
