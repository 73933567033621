import ReactSelect from "react-select";
import { useCallback, useMemo, useState } from "react";
import { isString } from "lodash";
import React from "react";
import { COLOR } from "const";
import { TElement } from "type";
import { Col, IColProps } from "components/base";
import { LayoutChangeEvent } from "react-native";
import { useElements } from "store/Element.Store";

type TOption = { label: string; value: string; data: TElement };

interface Props extends IColProps {
  onChange: any;
  value: string;
  showFull?: boolean;
  limitedHeight?: number;
  onReachLimitHeight?: () => void;
  pathfinderId?: string;
  hasAllOpt?: boolean;
  [anyprop: string]: any;
}

const ElementSelect = (props: Props) => {
  const { value, onChange, showFull, limitedHeight, onReachLimitHeight, pathfinderId, hasAllOpt, ...restProp } = props;
  const [inputValue, setInputValue] = useState('');
  const elements: TElement[] = useElements(pathfinderId);

  const onLayout = useCallback((e: LayoutChangeEvent) => {
    if (limitedHeight) {
      if (e.nativeEvent.layout.height > limitedHeight) {
        onReachLimitHeight?.();
      }
    }
  }, [limitedHeight, onReachLimitHeight])

  const _value = useMemo(() => {
    if (!value) return [];
    if (!isString(value)) return [];
    return value.split(',').map((id: string) => {
      const _element = elements.find(i => i.id === id);
      return {
        label: _element?.name,
        value: id,
        data: _element,
      }
    });
  }, [value, elements])

  const options = useMemo(() => {
    if (!elements) return [];
    let arr = [];
    const allOpts = elements.map((i: TElement) => {
      return {
        label: i?.name,
        value: i?.id,
        data: i,
      }
    });
    if (hasAllOpt && allOpts.length !== _value.length) {
      arr = arr.concat({ label: "All", value: "all" });
    }
    return arr.concat(allOpts);
  }, [elements, hasAllOpt])

  const onInputChange = (newValue: string) => {
    if (newValue?.length > 1 && newValue.includes(',')) {
      const newValues: TOption[] = _value;
      newValue.split(',').forEach(i => {
        if (i && !newValues.some(tag => tag.label === i.trim())) {
          newValues.push({
            label: i.trim(),
            value: i.trim(),
            data: null,
          });
        }
      });
      onChange(newValues);
      setInputValue('');
      return;
    }
    setInputValue(newValue);
  }

  const onChangeOpts = (values) => {
    let _values = values;
    if (values.some(i => i.value === "all")) {
      _values = elements.map((i: TElement) => {
        return {
          label: i?.name,
          value: i?.id,
          data: i,
        }
      });
    }
    onChange(_values);
  }

  return (
    <Col dataSet={{ element: 'select-elements' }} {...restProp} onLayout={onLayout}>
      <ReactSelect
        value={_value}
        isMulti
        styles={{
          control: (style) => ({
            ...style,
            height: 'auto',
            minHeight: 36,
            borderRadius: 8,
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            boxShadow: 'none',
            '&:hover': {
              borderColor: 'transparent',
            },
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            visibility: 'hidden',
            width: 0,
          }),
          clearIndicator: (provided) => ({
            ...provided,
            visibility: 'hidden',
            width: 0,
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            width: 0,
          }),
          input: styles => ({ ...styles, outline: 'none' }),
          indicatorSeparator: (style) => ({ display: 'none' }),
          placeholder: (style) => ({
            ...style,
            fontSize: 12,
            color: COLOR.FONT,
          }),
          valueContainer: (provided) => ({
            ...provided,
            maxHeight: showFull ? undefined : limitedHeight,
          })
        }}
        onInputChange={onInputChange}
        onChange={onChangeOpts}
        placeholder='Select element'
        options={options}
        inputValue={inputValue}
        menuPortalTarget={document.querySelector('body')}
      />
    </Col>
  );
};

export default ElementSelect;
