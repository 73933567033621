import Request from '../Request.utils'
export interface ICreateRequestBody {
  clientId?: string;
  data?: object;
  filterKeys?: string;
  id?: string;
  image?: string;
  name: string;
  orderIndex?: number;
  pathfinderId: string;
  rolloverText?: string;
}
export interface IUpdateRequestBody {
  data?: object;
  filterKeys?: string;
  id: string;
  image?: string;
  name?: string;
  orderIndex?: number;
  rolloverText?: string;
}
export interface IListRequestQuery {
  all?: boolean;
  filterKeys?: string;
  name?: string;
  pathfinderId: string;
  rolloverText?: string;
}
export interface IRemoveRequestBody {
  id: string;
}


export class ElementAPI {


  request = Request // for ts auto suggestion
  debugMode = false // for ts auto suggestion
  constructor(request, debugMode = false) {
    if(request) this.request = request;
    this.debugMode = debugMode;
  }
  create = async (body: ICreateRequestBody) => {
    if(this.debugMode) console.log('/api/elements/create', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/elements/create', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  update = async (body: IUpdateRequestBody) => {
    if(this.debugMode) console.log('/api/elements/update', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/elements/update', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  list = async (query: IListRequestQuery) => {
    if(this.debugMode) console.log('/api/elements', 'GET', undefined, query, undefined, );
    const res = await this.request.call('/api/elements', 'GET', undefined, query, undefined, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  remove = async (body: IRemoveRequestBody) => {
    if(this.debugMode) console.log('/api/elements/remove', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/elements/remove', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
}
export default new ElementAPI(Request);