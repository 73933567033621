import { FontAwesome5 } from "@expo/vector-icons";
import { Col, Input, Row, Select, Text, TouchField, modal, InputPassword } from "components";
import { COLOR } from "const";
import { VarHelper } from "helpers";
import { cloneDeep } from "lodash";
import React, { useCallback, useState } from "react";
import { ActivityIndicator, useWindowDimensions } from "react-native";
import { Tooltip } from "react-tippy";
import Store from "store";
import shallow from "zustand/shallow";

const fields = [
  { id: 'firstName', label: 'First Name' },
  { id: 'lastName', label: 'Last Name' },
  { id: 'email', label: 'Email' },
  { id: 'newPassword', label: 'New Password' },
  { id: 'confirmPassword', label: 'Confirm Password' },
]

interface Props {
  data?: any
  title?: string
  refresh?: () => void
  isGlobalAdmin?: boolean
  isAdmin?: boolean
}

const AdminDetailModal = ({ data, title, refresh, isGlobalAdmin, isAdmin }: Props) => {
  const [fieldsData, setFieldsData] = useState(data || {})
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { width } = useWindowDimensions()
  const { user } = Store.useUserStore()
  const { clients } = Store.useClientStore(state => ({
    clients: state.clients,
  }), shallow);

  const editField = useCallback((key, value) => {
    setFieldsData(state => {
      const newState = cloneDeep(state);
      newState[key] = value;
      return newState;
    });
  }, [])

  const onPressConfirm = useCallback(async () => {
    try {
      if (fieldsData.newPassword || fieldsData.confirmPassword) {
        if (!VarHelper.validatePassword(fieldsData.newPassword)) {
          throw new Error('Password must have at least: 6 letters, 1 number, 1 symbol');
        }
        if (fieldsData.newPassword !== fieldsData.confirmPassword) {
          throw new Error('Passwords do not match');
        }
      }

      setLoading(true);
      let res;
      if (data) {
        res = await Store.Api.User.update(VarHelper.removeUndefinedNullField({
          id: fieldsData.id,
          email: fieldsData.email,
          firstName: fieldsData.firstName,
          lastName: fieldsData.lastName,
          password: fieldsData.newPassword,
        }))
      } else {
        if (!fieldsData.newPassword) {
          throw new Error('Password is required');
        }
        res = await Store.Api.User.createAccount(VarHelper.removeUndefinedNullField({
          email: fieldsData.email,
          firstName: fieldsData.firstName,
          lastName: fieldsData.lastName,
          password: fieldsData.newPassword,
          role: isGlobalAdmin ? 'admin' : 'client',
          clientId: isGlobalAdmin ? undefined : fieldsData.clientId,
        }))
      }
      if (!res.data.success) {
        setError(res.data.error);
        return;
      }
      setError('');
      refresh?.();
      modal.hide();
    } catch (error) {
      setError(error?.message || JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  }, [fieldsData, data, user])

  return (
    <Col padding={24} paddingBottom={12} paddingRight={16} borderRadius={8} minWidth={Math.min(500, width - 64)} bgWhite>
      <Row alignItems='center' justifyContent='space-between' marginBottom={24}>
        <Text fontSize={20} semiBold color={COLOR.MAIN}>{title}</Text>
        <TouchField padding={4} onPress={() => modal.hide()}>
          <FontAwesome5 name='times' size={18} color={COLOR.MAIN} />
        </TouchField>
      </Row>
      {isAdmin &&
        <Col mt2>
          <Text caption mb1 bold>Client</Text>
          <Select
            isDisabled={!!data}
            options={Object.keys(clients).map(i => ({
              value: clients[i]?.id,
              label: clients[i]?.name,
            }))}
            value={{ value: fieldsData.clientId, label: clients[fieldsData.clientId]?.name }}
            onChange={(e) => editField('clientId', e?.value)}
          />
        </Col>
      }
      {fields.map(field => (
        <Col key={field.id} mt2>
          <Text caption mb1 bold>
            {field.label}
            {field.id === 'newPassword' &&
              <Tooltip title={'Minimum: 6 letters, 1 number, 1 symbol'} trigger="mouseenter">
                <TouchField paddingLeft={4}>
                  <FontAwesome5 name="info-circle" size={12} color={COLOR.SUB_TEXT} />
                </TouchField>
              </Tooltip>
            }
          </Text>
          {field.id.includes('Password') ?
            <InputPassword
              height={40}
              value={fieldsData[field.id]}
              onChange={(v) => editField(field.id, v)}
              inputProps={{
                style: {
                  // @ts-ignore
                  outline: 'none',
                  fontSize: 16,
                },
              }}
              borderRadius={8}
              borderWidth={1}
              borderColor={COLOR.BORDER_LIGHT}
            />
            :
            <Input
              height={40}
              value={fieldsData[field.id]}
              onChange={(v) => editField(field.id, v)}
              inputProps={{
                style: {
                  // @ts-ignore
                  outline: 'none',
                  fontSize: 16,
                },
              }}
              borderRadius={8}
              borderWidth={1}
              borderColor={COLOR.BORDER_LIGHT}
            />
          }
        </Col>
      ))}

      {!!error &&
        <Text color={COLOR.RED} marginTop={8}>{error}</Text>
      }
      <Row justifyContent={'flex-end'} marginTop={24}>
        <TouchField
          height={40}
          width={80}
          middle
          borderColor={COLOR.MAIN}
          borderRadius={20}
          borderWidth={1}
          onPress={() => modal.hide()}
          m0 ph2
        >
          <Text color={COLOR.MAIN}>Cancel</Text>
        </TouchField>
        <TouchField
          height={40}
          middle
          borderColor={COLOR.MAIN}
          borderRadius={20}
          borderWidth={1}
          backgroundColor={COLOR.MAIN}
          m0 ph2
          onPress={onPressConfirm}
        >
          <Row>
            {loading && <ActivityIndicator size='small' color={'white'} style={{ marginRight: 5 }} />}
            <Text color={COLOR.WHITE}>Save</Text>
          </Row>
        </TouchField>
      </Row>
    </Col>
  )
}

export default AdminDetailModal
