import { Col, Text, CMSLayout, Select, TouchField, Row } from 'components';
import React, { useState, useEffect, useCallback } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { COLOR, SCREEN } from 'const';
import { ActivityIndicator } from 'react-native';
import ApiClient from 'store/api-client';

const serverOpts = [
  { value: "http://localhost:3344", label: "Local" },
  { value: "https://api.pathfinder-rebuild.devserver.london", label: "Dev" },
  { value: "https://us.harborpathfinder.com", label: "Prod US" },
]

const tableOpts = [
  { value: "clients", label: "clients" },
  { value: "learnings", label: "learnings" },
  { value: "pathfinders", label: "pathfinders" },
  { value: "elements", label: "elements" },
  { value: "statements", label: "statements" },
]

const Maintainer: IScreen = () => {
  const { navigate } = useNavFunc();
  const [server, setServer] = useState();
  const [table, setTable] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const onPressGetData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await fetch(`${server}/api/maintainer/list-all?type=${table}`, {
        headers: {
          "admin-secret": "CD%2024-dev",
          "Accept": "application/json, text/plain, */*",
          "Authorization": ApiClient.getToken()
        }
      })
      const res2 = await res.json();
      setData(res2.data);
    } catch (error) {

    } finally {
      setLoading(false);
    }
  }, [server, table]);

  const onPressImport = useCallback(async () => {
    try {
      setLoading(true);
      const res = await fetch(`https://us.harborpathfinder.com/api/maintainer/import-all`, {
        method: "POST",
        headers: {
          "admin-secret": "CD%2024-dev",
          "Accept": "application/json, text/plain, */*",
          "Content-Type": "application/json",
          "Authorization": ApiClient.getToken()
        },
        body: JSON.stringify({
          type: table,
          data,
        })
      })
      alert("Success");
    } catch (error) {

    } finally {
      setLoading(false);
    }
  }, [server, table, data]);

  return (
    <CMSLayout requireAuthen>
      <Col flex1 m2 p2 round1 bgWhite shadow>
        <Text h4>Copy DB</Text>
        <Col mt2>
          <Text caption mb1 bold>Region</Text>
          <Select
            options={serverOpts}
            value={serverOpts.find(i => i.value === server)}
            onChange={(e) => setServer(e?.value)}
          />
        </Col>
        <Col mt2>
          <Text caption mb1 bold>Table</Text>
          <Select
            options={tableOpts}
            value={tableOpts.find(i => i.value === table)}
            onChange={(e) => setTable(e?.value)}
          />
        </Col>
        {server && (
          <TouchField
            height={40}
            middle
            borderColor={COLOR.MAIN}
            borderRadius={20}
            borderWidth={1}
            backgroundColor={COLOR.MAIN}
            m0 ph2
            onPress={onPressGetData}
          >
            <Row>
              {loading && <ActivityIndicator size='small' color={'white'} style={{ marginRight: 5 }} />}
              <Text color={COLOR.WHITE}>Get data</Text>
            </Row>
          </TouchField>
        )}
        {data?.length > 0 && (
          <Text mt2>{data.length} {table} records</Text>
        )}
        {data.length > 0 && (
          <TouchField
            height={40}
            middle
            borderColor={COLOR.MAIN}
            borderRadius={20}
            borderWidth={1}
            backgroundColor={COLOR.MAIN}
            m0 ph2
            onPress={onPressImport}
          >
            <Row>
              {loading && <ActivityIndicator size='small' color={'white'} style={{ marginRight: 5 }} />}
              <Text color={COLOR.WHITE}>Import</Text>
            </Row>
          </TouchField>
        )}
      </Col>
    </CMSLayout>
  )
};

Maintainer.routeInfo = {
  title: 'Maintainer',
  path: '/maintainer',
};

export default Maintainer;
