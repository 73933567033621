import React, { useMemo, useState } from 'react';
import { Col, IColProps, Text, RowTableLoading, ImageSelect, TouchField, Row, modalConfirm } from 'components';
import { TElement } from 'type';
import Store from 'store';
import { LineVertical, RowInput, RowTable } from './Commons';
import shallow from 'zustand/shallow';
import { COLOR } from 'const';
import { useElements } from 'store/Element.Store';
import { SelectTagInput } from 'components/elements/form/Select';
import { isArray } from 'lodash';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Feather, FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import { Tooltip } from 'react-tippy';

interface Props extends IColProps {
  pathfinderId: string
}

const TabElements = ({ pathfinderId }: Props) => {
  const [showHint1, setShowHint1] = useState(false);
  const [showHint2, setShowHint2] = useState(false);
  const { loading: loadingElements, editElement, reOrderElements, selectedElement, selectModePathfinder, selectElement, setElementStore, getElements } = Store.useElementStore(state => ({
    loading: state.loading,
    editElement: state.editElement,
    reOrderElements: state.reOrderElements,
    selectElement: state.selectElement,
    selectedElement: state.selectedElement,
    selectModePathfinder: state.selectModePathfinder,
    setElementStore: state.set,
    getElements: state.getElements,
  }), shallow);
  const elements: TElement[] = useElements(pathfinderId);

  const allFilterKeys = useMemo(() => {
    const result = [];
    if (!isArray(result)) return result;
    for (let elem of elements) {
      if (elem?.filterKeys) {
        elem.filterKeys.split(',').forEach(key => {
          if (!result.includes(key)) result.push(key)
        })
      }
    }
    return result.map(i => ({ label: i, value: i }));
  }, [elements])

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    let updatedList = [...elements];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    reOrderElements(updatedList);
  };

  const confirmDelete = () => {
    modalConfirm.show({
      title: 'Delete',
      message: `Do you want to delete ${selectedElement.length} element${selectedElement.length > 1 ? 's' : ''}?`,
      onConfirm: async () => {
        await Promise.all(selectedElement.map(id =>
          Store.Api.Element.remove({ id })
        ))
        setElementStore({ selectedElement: [] });
        await getElements(pathfinderId);;
      }
    })
  }

  return (
    <>
      <RowTable paddingLeft={24}>
        <Col flex1 alignSelf='flex-start'>
          <TouchField padding={2} paddingRight={8} onPress={() => setShowHint1(v => !v)} alignSelf={'flex-start'}>
            <Row alignItems={'center'}>
              <Text caption bold m0 marginRight={4}>Name</Text>
              <FontAwesome5 name="info" size={10} color={COLOR.MAIN} />
            </Row>
          </TouchField>
          {showHint1 &&
            <Text margin={4} marginTop={-2} fontSize={11} color={COLOR.SUB_TEXT} >Drag & drop to re-order</Text>
          }
        </Col>
        <LineVertical />
        <Col flex={3} alignSelf='flex-start'>
          <Text caption bold m0>Rollover Text</Text>
        </Col>
        <LineVertical />
        <Col flex={2} alignSelf='flex-start'>
          <TouchField padding={2} paddingRight={8} onPress={() => setShowHint2(v => !v)} alignSelf={'flex-start'}>
            <Row alignItems={'center'}>
              <Text caption bold m0 marginRight={4}>Filters</Text>
              <FontAwesome5 name="info" size={10} color={COLOR.MAIN} />
            </Row>
          </TouchField>
          {showHint2 &&
            <Text margin={4} marginTop={-2} fontSize={11} color={COLOR.SUB_TEXT} >Separate filters by using commas e.g. filter1, filter2</Text>
          }
        </Col>
        <LineVertical />
        <Col flex1 alignSelf='flex-start'>
          <Text caption bold m0>Image</Text>
        </Col>
      </RowTable>
      {selectedElement?.length > 0 && selectModePathfinder === pathfinderId &&
        <Row mb1 backgroundColor={COLOR.BG_TABLE_ROW} borderRadius={8} padding={8} alignItems={'center'} justifyContent={'space-between'}>
          <Text semiBold color={COLOR.MAIN}>{selectedElement.length} {selectedElement.length > 1 ? 'items' : 'item'} selected</Text>
          <Tooltip style={{ alignSelf: "center" }} trigger="mouseenter" title={selectElement?.length > 1 ? "Delete elements" : "Delete element"}>
            <TouchField borderRadius={8} alignSelf={'center'} onPress={confirmDelete}>
              <Row middle paddingHorizontal={12} paddingVertical={8}>
                {/* <Text color={COLOR.RED}>Delete </Text> */}
                <FontAwesome5 name='trash' size={16} color={COLOR.RED} />
              </Row>
            </TouchField>
          </Tooltip>
        </Row>
      }
      {loadingElements?.[pathfinderId] ?
        <RowTableLoading numOfCol={4} />
        :
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId="list-element-container">
            {(provided) => (
              <div
                className="list-element-container"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {elements?.map((i, idx) => (
                  <Draggable key={i.id} draggableId={i.id} index={idx}>
                    {(provided) => (
                      <div
                        className="element-item-container"
                        ref={provided.innerRef}
                        {...provided.draggableProps}>
                        <RowTable
                          key={`elm${pathfinderId}${i.id}`}
                          backgroundColor={idx % 2 ? undefined : COLOR.BG_TABLE_ROW}
                          alignItems={'flex-start'}
                        >
                          <div {...provided.dragHandleProps}>
                            <Feather
                              name="move"
                              size={18}
                              color={COLOR.SUB_TEXT}
                            />
                          </div>
                          <Col flex1>
                            <RowInput value={i.name} onChangeText={(text) => editElement(i.id, 'name', text)} />
                          </Col>
                          <LineVertical />
                          <Col flex={3}>
                            <RowInput value={i.rolloverText} onChangeText={(text) => editElement(i.id, 'rolloverText', text)} />
                          </Col>
                          <LineVertical />
                          <Col flex={2}>
                            <SelectTagInput
                              options={allFilterKeys}
                              value={i.filterKeys}
                              onChange={(opts) => editElement(i.id, 'filterKeys', opts.map(i => i.value).join(','))}
                              isMulti
                              dataSet={{ element: 'select-filter-keys' }}
                            />
                          </Col>
                          <LineVertical />
                          <Col flex1>
                            <Col m0 overflow={'hidden'}>
                              <ImageSelect
                                value={i.image}
                                imageStyle={{ width: '100%', height: 70 }}
                                onChange={(url) => editElement(i.id, 'image', url)}
                              />
                              <Text fontSize={11} textAlign={"center"} marginTop={4} color={COLOR.SUB_TEXT}>PNG - 400px - 300px</Text>
                            </Col>
                          </Col>
                          {selectModePathfinder === pathfinderId &&
                            <TouchField
                              onPress={() => selectElement(i.id)}
                              padding={6}
                              marginRight={4}
                              marginTop={6}
                            >
                              <FontAwesome5
                                name={selectedElement.includes(i.id) ? 'check-square' : 'square'}
                                size={20}
                                solid={selectedElement.includes(i.id)}
                                color={COLOR.MAIN}
                              />
                            </TouchField>
                          }
                        </RowTable>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      }
    </>
  )
};

export default TabElements;
