import {
  modal,
  MultiFactorNotiModal,
} from "components";
import { IScreen } from "type";
import React, { useState, useRef, useEffect } from "react";
import { SCREEN } from "const";
import { useNavFunc } from "navigation";
import { Animated, useWindowDimensions } from "react-native";
import LoginAnimated from "./LoginAnimated";
import apiClient from "store/api-client";
import { VarHelper } from "helpers";

const FORM_WIDTH = 320;
const FORM_HEIGHT = VarHelper.isDev() ? 410 : 380;

const HEADER_LOGIN_WIDTH = 269 * 0.7;
const HEADER_LOGIN_HEIGHT = 79 * 0.7;

const LOGO_HEIGHT = { BEFORE: 120, AFTER: HEADER_LOGIN_HEIGHT };

const calcFormInCenterPosition = (w, h) => {
  return {
    x: (w - FORM_WIDTH) / 2,
    y: (h - FORM_HEIGHT) / 2,
  };
};

const animate = (
  value: Animated.Value | Animated.ValueXY,
  toValue: Animated.TimingAnimationConfig["toValue"],
  duration: Animated.TimingAnimationConfig["duration"],
  delay: Animated.TimingAnimationConfig["delay"] = 0
) =>
  new Promise((resolve) => {
    Animated.timing(value, {
      toValue,
      duration,
      delay,
      useNativeDriver: false,
    }).start(({ finished }) => {
      resolve(finished);
    });
  });

const Login: IScreen = () => {
  const { reset, route, navigate } = useNavFunc();
  const { redirect, noWelcomeMessage }: any = route.params || {};
  const { width: innerWidth, height: innerHeight } = useWindowDimensions();
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
  const [showAfterLoginLayout, setShowAfterLoginLayout] = useState(false);
  const [showAfterMFALayout, setShowAfterMFALayout] = useState(false);

  useEffect(() => {
    if (!window.location.host.includes("localhost") && !window.location.host.includes('pathfinder-rebuild.devserver.london')) {
      const clientSlug = window.location.host.split(".")[0];
      if (clientSlug === "admin") {
        apiClient.setHost('https://us.harborpathfinder.com');
      } else {
        apiClient.Api.Client.siteInfo({ slug: clientSlug }).then((res) => {
          if (res.data?.data) {
            const { region } = res.data.data;
            apiClient.setHost(`https://${region}.harborpathfinder.com`);
          }
        })
      }
    }
  }, []);

  // anim params
  const position = useRef(
    new Animated.ValueXY(calcFormInCenterPosition(innerWidth, innerHeight))
  ).current;
  useEffect(() => {
    Animated.timing(position, {
      toValue: calcFormInCenterPosition(innerWidth, innerHeight),
      duration: 300,
      useNativeDriver: false,
    }).start();
  }, [innerWidth, innerHeight]);

  const logoHeight = useRef(new Animated.Value(LOGO_HEIGHT.BEFORE)).current;
  const containerWidth = useRef(new Animated.Value(FORM_WIDTH)).current;
  const containerHeight = useRef(new Animated.Value(FORM_HEIGHT)).current;
  const inputOpacity = useRef(new Animated.Value(1)).current;
  const phoneInputOpacity = useRef(new Animated.Value(0)).current;
  const otpCodeInputOpacity = useRef(new Animated.Value(0)).current;
  const boxShadowOpacity = useRef(new Animated.Value(0.12)).current;
  const bgOpacity = useRef(new Animated.Value(1)).current;

  // const logoHeight = containerHeight.interpolate({
  //   inputRange: [HEADER_LOGIN_HEIGHT, FORM_HEIGHT],
  //   outputRange: [LOGO_HEIGHT.AFTER, LOGO_HEIGHT.BEFORE],
  //   extrapolate: 'clamp',
  // })

  const doRedirect = (screen?: string) => {
    if (redirect) {
      reset(screen ?? redirect);
    } else {
      reset(screen ?? SCREEN.Home);
    }
  };

  const onMFASuccess = async (isShowPopup?: boolean) => {
    await animate(otpCodeInputOpacity, 0, 300, 0);
    if (innerWidth < 480 || !!noWelcomeMessage) {
      if (isShowPopup) {
        modal.show(<MultiFactorNotiModal redirect={doRedirect} />);
        return;
      } else return doRedirect();
    }

    setShowWelcomeMessage(true);
    await animate(containerHeight, 240, 300, 300);
    await animate(containerHeight, 120, 300, 4000);
    setShowWelcomeMessage(false);

    await Promise.all([
      animate(bgOpacity, 0, 300, 0),
      animate(position, { x: 0, y: 0 }, 500, 0),
      animate(containerHeight, HEADER_LOGIN_HEIGHT, 300, 200),
      animate(containerWidth, HEADER_LOGIN_WIDTH, 300, 200),
      animate(logoHeight, LOGO_HEIGHT.AFTER, 300, 200),
      animate(boxShadowOpacity, 0, 300, 200),
    ]);
    setShowAfterMFALayout(true);

    setTimeout(() => {
      if (isShowPopup) {
        modal.show(<MultiFactorNotiModal redirect={doRedirect} />);
        return;
      } else doRedirect();
    }, 500);
  };

  const onLoginSuccess = async ({
    isHaveMultiFactor,
  }: {
    isHaveMultiFactor: boolean;
  }) => {
    if (innerWidth < 480 || !!noWelcomeMessage) return doRedirect();
    await animate(inputOpacity, 0, 300, 300);

    if (isHaveMultiFactor) {
      animate(otpCodeInputOpacity, 1, 300, 0);
    }
  };

  const onForgotPassword = () => {
    navigate(SCREEN.ForgotPassword);
  };

  return (
    <LoginAnimated
      logoHeight={logoHeight}
      width={containerWidth}
      height={containerHeight}
      position={position}
      inputOpacity={inputOpacity}
      onLoginSuccess={onLoginSuccess}
      onMFASuccess={onMFASuccess}
      showWelcomeMessage={showWelcomeMessage}
      showAfterMFALayout={showAfterMFALayout}
      boxShadowOpacity={boxShadowOpacity}
      bgOpacity={bgOpacity}
      onForgotPassword={onForgotPassword}
      otpCodeInputOpacity={otpCodeInputOpacity}
    />
  );
};

Login.routeInfo = {
  title: "Login",
  path: "/login",
};

export default Login;
