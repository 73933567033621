import { FontAwesome5 } from '@expo/vector-icons';
import { UploadFile, TouchField, Image, Col, Row, Text } from 'components';
import { COLOR } from 'const';
import React, { useMemo, useRef, useState } from 'react';
import { ActivityIndicator, ImageResizeMode, ImageStyle, StyleProp } from 'react-native';
import { Tooltip } from 'react-tippy';

export interface IImageSelectProps {
  value: string;
  resizeMode?: ImageResizeMode;
  imageStyle?: StyleProp<ImageStyle>;
  onChange?: (url: string) => void;
  canRemove?: boolean;
  isButtonsBottom?: boolean;
  entityName?: string;
  shortenUrl?: boolean;
}

const ImageSelect = (props: IImageSelectProps) => {
  const { value, imageStyle, resizeMode, onChange, canRemove = true, isButtonsBottom, entityName, shortenUrl } = props;
  const uploadRef = useRef<{ showDialog: Function }>(null);
  const [loading, setLoading] = useState(false);

  const handleButtonClick = () => {
    uploadRef.current.showDialog();
  }

  const onImageUploaded = (urls) => {
    if (urls.length === 0) return setLoading(false);
    onChange(urls[0]);
    setLoading(false);
  }

  const handleRemove = () => {
    onChange('');
  }

  const renderImg = useMemo(() => {
    return (
      <TouchField dataSet={{ element: 'image-picker' }} flex1 onPress={!value ? handleButtonClick : undefined}>
        <Image
          source={{ uri: value }}
          style={[{ width: '100%', height: 70 }, imageStyle]}
          borderRadius={10}
          resizeMode={resizeMode || 'contain'}
          emptyText={`Add ${entityName || "image"}`}
        />
        <UploadFile
          ref={uploadRef}
          onUploaded={onImageUploaded}
          onUploading={() => setLoading(true)}
          shortenUrl={shortenUrl}
        />
        {loading &&
          <Col absoluteFill middle backgroundColor={COLOR.MASK}>
            <ActivityIndicator color={COLOR.MAIN} testID='image-uploading' />
          </Col>
        }
      </TouchField>
    )
  }, [value, loading, shortenUrl, resizeMode, handleButtonClick, imageStyle, onImageUploaded])

  if (isButtonsBottom) {
    return (
      <>
        {renderImg}
        {!!value && canRemove &&
          <Row flexWrap={'wrap'} middle>
            <Tooltip
              title="Edit the image"
              trigger="mouseenter"
            >
              <TouchField borderRadius={8} alignSelf={'center'} onPress={handleButtonClick}>
                <Row middle paddingHorizontal={12} paddingVertical={8}>
                  {/* <Text color={COLOR.MAIN}>Edit </Text> */}
                  <FontAwesome5 name='pen' size={16} color={COLOR.MAIN} />
                </Row>
              </TouchField>
            </Tooltip>
            <Tooltip
              title="Delete the image"
              trigger="mouseenter"
            >
              <TouchField borderRadius={8} alignSelf={'center'} onPress={handleRemove}>
                <Row middle paddingHorizontal={12} paddingVertical={8}>
                  {/* <Text color={COLOR.RED}>Delete </Text> */}
                  <FontAwesome5 name='trash' size={16} color={COLOR.RED} />
                </Row>
              </TouchField>
            </Tooltip>
          </Row>
        }
      </>
    )
  }
  return (
    <Row width100p alignItems={'flex-start'}>
      {!!value && canRemove &&
        <Col>
          <Tooltip
            title="Edit the image"
            trigger="mouseenter"
          >
            <TouchField borderRadius={8} alignSelf={'center'} onPress={handleButtonClick}>
              <Row middle paddingHorizontal={12} paddingVertical={8}>
                {/* <Text color={COLOR.MAIN}>Edit </Text> */}
                <FontAwesome5 name='pen' size={10} color={COLOR.MAIN} />
              </Row>
            </TouchField>
          </Tooltip>
          <Tooltip
            title="Delete the image"
            trigger="mouseenter"
          >
            <TouchField borderRadius={8} alignSelf={'center'} onPress={handleRemove}>
              <Row middle paddingHorizontal={12} paddingVertical={8}>
                {/* <Text color={COLOR.RED}>Delete </Text> */}
                <FontAwesome5 name='trash' size={10} color={COLOR.RED} />
              </Row>
            </TouchField>
          </Tooltip>
        </Col>
      }
      {renderImg}
    </Row>
  )
}

export default ImageSelect;
