import React, { useMemo } from 'react';
import { Col, Text } from 'components';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { linking, screenArr } from './linking';

// specify all navigation here
const Stack = createStackNavigator();

const Navigation = () => {

  return (
    <NavigationContainer
      linking={{ config: { screens: linking }, prefixes: [] }}
      documentTitle={{
        formatter: (options, route) => `${options?.title ?? route?.name}`,
      }}
      fallback={<Text>Loading...</Text>}
    >
      <Stack.Navigator screenOptions={{ header: () => null }}>
        {useMemo(() => {
          return screenArr.map((val, i) => (
            <Stack.Screen key={'screen-' + val + i} name={val.name} component={val.component} options={{ title: val.title }} />
          ));
        }, [])}
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default Navigation;
