import React, { useState } from 'react';
import { Col, Row, Text, TouchField } from 'components';
import { TextInput, TextInputProps } from 'react-native';
import { COLOR } from 'const';
import { IRowProps } from 'components/base/Row';
import { FontAwesome5 } from '@expo/vector-icons';

export const RowTable = ({ ...props }: IRowProps) => <Row borderBottomWidth={1} borderBottomColor={COLOR.GREY_LIGHT} {...props} />;

export const RowInput = ({ ...props }: TextInputProps) => {
  const [height, setHeight] = useState(32)
  return (
    <TextInput
      multiline
      // @ts-ignore
      style={{ outline: 'none', padding: 5, height, maxHeight: 200 }}
      onContentSizeChange={e => setHeight(e.nativeEvent.contentSize.height)}
      dataSet={{ element: 'row-input' }}
      {...props}
    />
  )
};

export const RowInputWithIcon = ({ icon, onPressIcon, ...props }: TextInputProps & { icon: string, onPressIcon: any }) => {
  return (
    <Col>
      <Row alignItems={'flex-start'}>
        <Col flex1>
          <RowInput {...props} />
        </Col>
        {!!icon && (
          <TouchField width={24} height={24} borderRadius={12} middle mt0 ml0 onPress={onPressIcon}>
            <FontAwesome5 name={icon} light size={14} color={COLOR.MAIN} />
          </TouchField>
        )}
      </Row>
    </Col>
  )
};

export const RowInputWithHint = ({ hint, icon, onPressIcon, textIcon, ...props }: TextInputProps & { hint: string, icon?: string, onPressIcon?: any, textIcon?: string }) => {
  const [showHint, setShowHint] = useState(false);
  return (
    <Col>
      {!!showHint && (
        <Row mt0 stretch ml0>
          <Col width={2} backgroundColor={COLOR.MAIN} />
          <Col flex1 ml0>
            <Text caption>{hint}</Text>
          </Col>
        </Row>
      )}
      <Row alignItems={'flex-start'}>
        <Col flex1>
          <RowInput {...props} />
        </Col>
        {!!hint && (
          <TouchField width={24} height={24} borderRadius={12} middle mt0 ml0 onPress={() => setShowHint(v => !v)}>
            <FontAwesome5 name="info" size={14} color={COLOR.MAIN} />
          </TouchField>
        )}
        {!!icon && (
          <TouchField width={24} height={24} borderRadius={12} middle mt0 ml0 onPress={onPressIcon}>
            <FontAwesome5 name={icon} size={14} color={COLOR.MAIN} />
          </TouchField>
        )}
        {!!textIcon && (
          <TouchField paddingHorizontal={6} paddingVertical={4} borderRadius={12} middle mt0 ml0 onPress={onPressIcon}>
            <Text color={COLOR.MAIN}>{textIcon}</Text>
          </TouchField>
        )}
      </Row>
    </Col>
  )
};

export const LineVertical = () => <Col height100p width={1} backgroundColor={COLOR.GREY_LIGHT} />;
export const LineHorizontal = () => <Col width100p height={1} backgroundColor={COLOR.GREY_LIGHT} />;
