import Request from '../Request.utils'


export class HelloAPI {


  request = Request // for ts auto suggestion
  debugMode = false // for ts auto suggestion
  constructor(request, debugMode = false) {
    if(request) this.request = request;
    this.debugMode = debugMode;
  }
  helloWorld = async () => {
    if(this.debugMode) console.log('/b/api', 'GET', undefined, undefined, undefined, );
    const res = await this.request.call('/b/api', 'GET', undefined, undefined, undefined, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  regionInfo = async () => {
    if(this.debugMode) console.log('/b/api/regionInfo', 'GET', undefined, undefined, undefined, );
    const res = await this.request.call('/b/api/regionInfo', 'GET', undefined, undefined, undefined, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
}
export default new HelloAPI(Request);