import { Col, Text, CMSLayout, Row, TouchField, Table, Select, Grid, Input, Button, DatePicker } from 'components';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { IScreen, TLearnerLikerts } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { COLOR, SCREEN } from 'const';
import shallow from 'zustand/shallow';
import { cloneDeep } from 'lodash';
import { VarHelper } from 'helpers';
import moment from 'moment';
import ExportHelper from 'helpers/ExportHelper';

type SearchDataType = {
  pathfinderId?: string,
  learnerId?: string,
  fromDate?: string,
  toDate?: string,
}

type TableRowItem = {
  id?: string,
  name?: string,
  email?: string,
  pathfinderName?: string,
  statement?: string,
  likert?: string
  learnings?: string
  createdAt?: string
}

const ClientReport: IScreen = () => {
  const { navigate } = useNavFunc();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<TableRowItem[]>([]);
  const [showTable, setShowTable] = useState(false);
  const [searchData, setSearchData] = useState<SearchDataType>({});
  const { getPathfinders, pathfinders } = Store.usePathfinderStore((state) => ({
    getPathfinders: state.getPathfinders,
    pathfinders: state.pathfinders,
  }), shallow);
  const { getLearners, learners } = Store.useLearnerStore(state => ({
    getLearners: state.getLearners,
    learners: state.learners
  }))
  const { selectedClient } = Store.useClientStore();

  const editSearch = useCallback((key, value) => {
    setSearchData(state => {
      const newState = cloneDeep(state);
      if (newState) newState[key] = value;
      return newState;
    });
  }, [])

  useEffect(() => {
    getPathfinders(selectedClient?.id);
    getLearners();
  }, [selectedClient])

  const onClear = useCallback(() => {
    setSearchData({});
    setShowTable(false);
  }, [])

  const onPressGenerate = useCallback(async (isExport = false) => {
    try {
      if (!isExport) setShowTable(true);
      if (loading) return;
      setLoading(true);
      const res = await Store.Api.Learner.report(
        VarHelper.removeUndefinedNullField({
          pathfinderId: searchData?.pathfinderId,
          learnerId: searchData?.learnerId,
          fromDate: searchData?.fromDate,
          toDate: searchData?.toDate,
        })
      )
      if (res?.data?.data) {
        const _result = [];
        res.data.data.forEach((i: TLearnerLikerts) => {
          Object.keys(i.likerts).forEach((statementId, idx) => {
            const learner = learners[i.learnerId];
            const statement = i.likerts[statementId];
            _result.push({
              name: idx === 0 ? `${learner?.firstName} ${learner?.lastName}` : undefined,
              email: idx === 0 ? learner?.email : undefined,
              pathfinderName: idx === 0 ? i.pathfinderName : undefined,
              statement: statement.statement,
              likert: statement.likert,
              learnings: statement.learnings?.map(i => i.name).join(',\n'),
              createdAt: idx === 0 ? moment(i.createdAt).format('YYYY/MM/DD') : undefined,
            })
          })
        })
        if (isExport) {
          ExportHelper.saveArrayToFile(_result, {
            fileName: 'client-report.xlsx'
          });
        } else {
          setResult(_result);
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [learners, loading, searchData])

  const learnerOpts = useMemo(() => {
    const allLearners = Object.keys(learners).map(i => ({
      value: learners[i]?.id,
      label: `${learners[i]?.email} (${learners[i]?.firstName} ${learners[i]?.lastName})`,
    }))
    if (searchData?.learnerId) {
      return [{ value: undefined, label: 'None' }, ...allLearners];
    }
    return allLearners;
  }, [learners, searchData?.learnerId]);

  return (
    <CMSLayout requireAuthen>
      <Col flex1 m2 p2 round1 bgWhite shadow>
        <Text h4 mb2 nativeID={'heading'}>Reports</Text>
        <Row>
          <Col m0 mt1 flex1>
            <Text caption mb1 bold>Pathfinder</Text>
            <Select
              options={Object.keys(pathfinders).map(i => ({
                value: pathfinders[i]?.id,
                label: pathfinders[i]?.name || pathfinders[i]?.id,
              }))}
              value={{ value: searchData?.pathfinderId, label: pathfinders[searchData?.pathfinderId]?.name }}
              onChange={(e) => editSearch('pathfinderId', e?.value)}
            />
          </Col>
          <Col m0 mt1 flex1>
            <Text caption mb1 bold>Learner  <Text color={COLOR.SUB_TEXT}>{`(for specific learners)`}</Text></Text>
            <Select
              options={learnerOpts}
              value={{ value: searchData?.learnerId, label: learners[searchData?.learnerId]?.email }}
              onChange={(e) => editSearch('learnerId', e?.value)}
            />
          </Col>
        </Row>
        <Row zIndex={'unset'}>
          <Col m0 mt1 flex1 zIndex={'unset'}>
            <Text caption mb1 bold>From date</Text>
            <DatePicker
              value={searchData?.fromDate}
              onChange={(v) => editSearch('fromDate', v)}
            />
          </Col>
          <Col m0 mt1 flex1 zIndex={'unset'}>
            <Text caption mb1 bold>To date</Text>
            <DatePicker
              value={searchData?.toDate}
              onChange={(v) => editSearch('toDate', v)}
            />
          </Col>
        </Row>
        <Row mb2>
          <Col m0 mt1 flex1>
            <Button text='Generate' width={undefined} onPress={() => onPressGenerate()} />
          </Col>
          <Col m0 mt1 flex1>
            <Button
              text='Export' width={undefined} onPress={() => onPressGenerate(true)}
              // disabled={!canSubmit} opacity={canSubmit ? 1 : 0.2}
              backgroundColor={COLOR.BLUE} bgHovered='transparent'
            />
          </Col>
          <Col m0 mt1 flex1>
            <Button
              text='Clear' width={undefined}
              onPress={onClear}
              nativeID='clear-filter'
              backgroundColor={'transparent'}
              bgHovered='transparent'
              borderColor={COLOR.MAIN}
              borderWidth={1}
              textProps={{ fontWeight: 'bold', color: COLOR.MAIN }}
            />
          </Col>
        </Row>
        {showTable &&
          <Table
            columns={[
              { key: 'name', title: 'Name', flex: 1 },
              { key: 'email', title: 'Email', flex: 1 },
              { key: 'pathfinderName', title: 'Pathfinder', flex: 1 },
              { key: 'statement', title: 'Statement', flex: 1 },
              { key: 'likert', title: 'Likert', width: 150 },
              { key: 'learnings', title: 'Recommended Learning', flex: 1 },
              { key: 'createdAt', title: 'Completed Date', width: 150 },
            ]}
            data={result}
            style={{ margin: 20 }}
            minWidthRequired={500}
            rowProps={{
              paddingVertical: 16,
            }}
            isLoading={loading}
          />
        }
      </Col>
    </CMSLayout>
  )
};

ClientReport.routeInfo = {
  title: 'Pathfinder - My Report',
  path: '/my-report',
};

export default ClientReport;
