import React from 'react';
import { Col, Grid, ShimmerLoading } from 'components';

const PathdinderLoadinng = () => {

  return (
    <Col
      backgroundColor={'white'}
      minHeight={150}
      borderRadius={10}
      borderWidth={1}
      borderColor={'rgba(0,0,0,0.05)'}
    >
      <Grid xs='100%' md=':1' stretch height100p>
        <Col m0 width={200} overflow={'hidden'}>
          <ShimmerLoading borderRadius={10} height={146} />
        </Col>
        <Col flex1 m0 p0 bgWhite round1>
          <ShimmerLoading height={30} borderRadius={10} />
          <Col mt1>
            <ShimmerLoading borderRadius={10} height={100} />
          </Col>
        </Col>
      </Grid>
    </Col>
  )
};

export default PathdinderLoadinng;
