import React, { useMemo } from 'react';
import { Col, Text, Table, Image, Row, TouchField, modal } from 'components';
import { TLearning } from 'type';
import { FontAwesome5 } from '@expo/vector-icons';
import { COLOR } from 'const';

const LearningsByTagModal = ({ learnings, tag }: { learnings: TLearning[], tag: string }) => {

  const data = useMemo(() => {
    if (!learnings?.length) return [];
    return learnings.map(i => ({
      ...i,
      logo: <Image source={{ uri: i.icon }} style={{ width: 70, height: 70 }} resizeMode='contain' />,
    }))
  }, [learnings])

  return (
    <Col round1 bgWhite shadow padding={24} paddingBottom={36}>
      <Row alignItems='center' justifyContent='space-between' marginBottom={24}>
        <Text fontSize={18} semiBold>Learnings with tag: <Text semiBold color={COLOR.MAIN}>{tag}</Text></Text>
        <TouchField padding={4} onPress={() => modal.hide()}>
          <FontAwesome5 name='times' size={18} color={COLOR.MAIN} />
        </TouchField>
      </Row>
      <Table
        columns={[
          { key: 'logo', title: 'Icon', width: 95, padding: 10 },
          { key: 'name', title: 'Name', flex: 1 },
          { key: 'url', title: 'URL', flex: 1 },
          { key: 'tags', title: 'Tags', flex: 1 },
        ]}
        data={data}
        style={{ margin: 20 }}
        minWidthRequired={500}
      />
    </Col>
  );
};

export default LearningsByTagModal;
