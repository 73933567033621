import {
  Col,
  Text,
  Button,
  Input,
  useUIState,
  commonStyles,
  TouchField,
  Row,
} from "components";
import React, { useState, useEffect } from "react";
import { COLOR } from "const";
import { ASSETS } from "assets";
import Store from "store";
import { ActivityIndicator, Animated, Image } from "react-native";
import * as Animatable from "react-native-animatable";
import "react-phone-input-2/lib/style.css";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../firebase.config";
import OtpInput from "otp-input-react";
import apiClient from "store/api-client";
import { VarHelper } from "helpers";

const HEADER_LOGIN_WIDTH = 269 * 0.7;
const HEADER_LOGIN_HEIGHT = 79 * 0.7;

class TText extends React.Component<any> {
  render() {
    return <Text {...this.props} />;
  }
}
const AnimatedText = Animatable.createAnimatableComponent(TText);

interface ILoginAnimationProps {
  width: Animated.Value;
  height: Animated.Value;
  boxShadowOpacity: Animated.Value;
  logoHeight: Animated.AnimatedInterpolation;
  position: Animated.ValueXY;
  inputOpacity: Animated.Value;
  bgOpacity: Animated.Value;
  showWelcomeMessage: boolean;
  showAfterMFALayout: boolean;
  onLoginSuccess: any;
  onMFASuccess: any;
  onForgotPassword: any;
  otpCodeInputOpacity: Animated.Value;
}
declare global {
  interface Window {
    FB: any;
  }
}
const Login = ({
  width,
  height,
  position,
  inputOpacity,
  onLoginSuccess,
  onForgotPassword,
  onMFASuccess,
  showWelcomeMessage,
  logoHeight,
  showAfterMFALayout,
  boxShadowOpacity,
  bgOpacity,
  otpCodeInputOpacity,
}: ILoginAnimationProps) => {
  const UserStore = Store.useUserStore();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [borderRadius, setBorderRadius] = useState(10);
  const [recaptchaVerifierState, setRecaptchaVerifierState] = useState(null);

  const [user, setUser] = useState(null);
  const [code, setCode] = useState("");
  const [confirm, setConfirm] = useState(null);
  const [{ loading, errorMes }, setUI] = useUIState();
  const [{ loading: loadingVerify, errorMes: errMessVerify }, setUIVerify] =
    useUIState();
  const [{ loading: loadingGetCode, errorMes: errMessGetCode }, setUIGetCode] =
    useUIState();

  const [
    { loading: changeOtpTypeLoading, errorMes: changeOtpTypeError },
    setUIChangeOtpType,
  ] = useUIState();

  const [otpType, setOtpType] = useState("TOTP");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState(60);

  const [{ isPointEventInput, isPointEventOTP }, setPointEvents] = useState({
    isPointEventInput: true,
    isPointEventOTP: false,
  });

  const startCountdown = () => {
    setButtonDisabled(true);
    setCountdown(60);
    const timer = setInterval(() => {
      setCountdown((prevCount) => {
        if (prevCount <= 1) {
          setButtonDisabled(false);
          clearInterval(timer);
        }
        return prevCount - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    inputOpacity.addListener(({ value }) => {
      if (value === 0) {
        setBorderRadius(0);
      } else if (value === 1) {
        setBorderRadius(5);
      }
    });
  }, []);

  const sendVerification = async (
    inputPhoneNumber: string,
    isFromLogin?: boolean
  ) => {
    setUI({ loading: true });
    setUIGetCode({ loading: true });
    let recaptchaVerifier = recaptchaVerifierState || null;

    if (!recaptchaVerifierState) {
      recaptchaVerifier = await onCaptchVerify();
      setRecaptchaVerifierState(recaptchaVerifier);
    }
    try {
      const phoneNumberFormat = inputPhoneNumber;
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumberFormat,
        recaptchaVerifier
      );
      if (confirmationResult) {
        setConfirm(confirmationResult);
        setUIGetCode({ loading: false });
        if (isFromLogin) {
          inputPhoneNumber && onLoginSuccess({ isHaveMultiFactor: true });
          setUI({ loading: false });
          setUIGetCode({ loading: false });
          setPointEvents({
            isPointEventInput: false,
            isPointEventOTP: true,
          });
        }
        startCountdown();
      }
    } catch (error) {
      if (error.code === "auth/too-many-requests") {
        setUI({ loading: false, errorMes: "Too many requests!" });
        setUIGetCode({ loading: false, errorMes: "Too many requests!" });
        return;
      }
      if (error.code === "auth/invalid-phone-number") {
        setUI({ loading: false, errorMes: "Invalid phone number!" });
        setUIGetCode({ loading: false, errorMes: "Invalid phone number!" });
        return;
      }
      if (error.code === "auth/invalid-verification-code") {
        setUI({ loading: false, errorMes: "Invalid verification code!" })
        setUIGetCode({
          loading: false,
          errorMes: "Invalid verification code!",
        });
        return;
      }
      if (error.code === "auth/missing-verification-code") {
        setUI({ loading: false, errorMes: "Missing verification code!" });
        setUIGetCode({
          loading: false,
          errorMes: "Missing verification code!",
        });
        return;
      }
      if (error.code === "auth/missing-phone-number") {
        setUI({ loading: false, errorMes: "Missing phone number!" });
        setUIGetCode({ loading: false, errorMes: "Missing phone number!" });
        return;
      }
      if (error.code === "auth/network-request-failed") {
        setUI({ loading: false, errorMes: "Network request failed!" });
        setUIGetCode({ loading: false, errorMes: "Network request failed!" });
        return;
      } else {
        setUI({ loading: false, errorMes: error.message });
        setUIGetCode({ loading: false, errorMes: error.message });
        return;
      }
    }
  };

  const onCaptchVerify = () => {
    return new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "invisible",
      callback: async () => { },
      "expired-callback": () => { },
    });
  };

  const confirmCode = async () => {
    if (otpType === "PHONE") {
      if (confirm) {
        try {
          setUIVerify({ loading: true });
          const result = await confirm.confirm(code);
          if (result) {
            const res = await Store.Client.Api.User.verifyToken({
              userId: user?.id,
              idToken: result._tokenResponse.idToken,
              isLogin: true,
            });

            if (res.data.success && res.data.data?.token) {
              setUI({ loading: false, errorMes: "" });
              const token = res.data.data?.token;
              Store.Client.setToken(token);
              UserStore.set({
                token,
                user: res.data.data?.publicInfo,
              });
              setCode("");
              onMFASuccess();
            }
          }
        } catch (error) {
          console.log("confirmCode error", error);
          setUIVerify({
            loading: false,
            errorMes: "Your code is not correct!",
          });
        }
      }
    } else if (otpType === "TOTP") {
      setUIVerify({ loading: true });
      const res = await Store.Client.Api.User.verify2FA({
        userId: user?.id,
        token: code,
        isLogin: true,
      });

      if (res.data.success && res.data.data?.token) {
        setUI({ loading: false, errorMes: "" });
        const token = res.data.data?.token;
        Store.Client.setToken(token);
        UserStore.set({
          token,
          user: res.data.data?.publicInfo,
        });
        setCode("");
        onMFASuccess();
      } else if (res.data.success === false) {
        setUIVerify({ loading: false, errorMes: "Your code is not correct!" });
      }
    }
  };

  const handleLogin = async () => {
    setUI({ loading: true });
    try {
      if (!email || !password)
        return setUI({
          loading: false,
          errorMes: "Please input your email and password",
        });
      const res = await Store.Client.Api.User.login({
        email,
        password,
      });
      const clientId = res.data.data?.publicInfo?.clientId;
      if (!window.location.host.includes("localhost") && clientId && !window.location.host.includes('pathfinder-rebuild.devserver.london')) {
        const siteRes = await apiClient.Api.Client.siteInfo({ slug: clientId });
        if (siteRes.data.data?.slug) {
          const { apiUrl, slug } = siteRes.data.data;
          const correctUrl = `https://${slug}.harborpathfinder.com`;
          if (!VarHelper.isSameDomainAndPath(correctUrl, window.location.href)) {
            window.location.href = correctUrl;
            return;
          }
          if (apiUrl) apiClient.setHost(apiUrl);
        }
      }
      setUser(res.data.data);
      if (res.data.success && res.data.isHaveMultiFactor === true) {
        if (res.data.data?.multiFactorType?.includes("TOTP")) {
          setOtpType("TOTP");
          onLoginSuccess({ isHaveMultiFactor: true });
          setUI({ loading: false });
          setPointEvents({
            isPointEventInput: false,
            isPointEventOTP: true,
          });
        } else if (res.data.data?.multiFactorType?.includes("PHONE")) {
          setOtpType("PHONE");
          await sendVerification(res.data.data.phoneNumber, true);
          setPointEvents({
            isPointEventInput: false,
            isPointEventOTP: true,
          });
        }

      } else if (res.data.success && res.data.data?.token) {
        setUI({ loading: false, errorMes: "" });
        onLoginSuccess({ isHaveMultiFactor: false });
        const token = res.data.data?.token;
        Store.Client.setToken(token);
        UserStore.set({
          token,
          user: res.data.data?.publicInfo,
        });
        setCode("");
        onMFASuccess(true);
      } else {
        setUI({ loading: false, errorMes: res.data.error });
        // setUI({ loading: false, errorMes: '' });
        // onMFASuccess();
      }
    } catch (err) {
      setUI({ loading: false, errorMes: String(err) });
    }
  };

  const onChangeOtpType = async () => {
    if (otpType === "PHONE") {
      setCode("");
      setUIVerify({ loading: false, errorMes: "" });
      setUI({ loading: false, errorMes: "" });
      setUIGetCode({ loading: false, errorMes: "" });
      setOtpType("TOTP");
    } else {
      setCode("");
      setUIVerify({ loading: false, errorMes: "" });
      setUI({ loading: false, errorMes: "" });
      setUIGetCode({ loading: false, errorMes: "" });
      if (countdown > 0 && countdown < 60) {
        setOtpType("PHONE");
      } else {
        setUIChangeOtpType({ loading: true });
        await sendVerification(user?.phoneNumber);
        setOtpType("PHONE");
        startCountdown();
        setUIChangeOtpType({ loading: false });
      }
    }
  };

  return (
    <Col flex1 backgroundColor={COLOR.GREY_BG}>
      <Col absoluteFill zIndex={1}>
        <Animated.Image
          source={ASSETS.LOGIN_BG}
          style={{ width: "100%", height: "100%", opacity: bgOpacity }}
          resizeMode="cover"
        />
      </Col>
      <Col zIndex={2} flex1 middle>
        {showAfterMFALayout && (
          <>
            <Animatable.View
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: HEADER_LOGIN_WIDTH,
                bottom: 0,
                backgroundColor: "white",
              }}
              animation="fadeInLeftBig"
              duration={300}
            />
            <Animatable.View
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                height: HEADER_LOGIN_HEIGHT,
                backgroundColor: "white",
              }}
              animation="fadeInDownBig"
              duration={300}
            />
          </>
        )}
        <div id="recaptcha-container"></div>
        <Animated.View
          style={{
            position: "absolute",
            top: position.y,
            left: position.x,
            width,
            height,
            backgroundColor: "white",
            borderRadius,
            overflow: "hidden",
            ...commonStyles.shadow,
            shadowOpacity: boxShadowOpacity,
          }}
        >
          <Col>
            <Animated.View
              style={{
                // backgroundColor: COLOR.MAIN,
                height: logoHeight,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                source={ASSETS.LOGO}
                style={{ width: "65%", height: "80%" }}
                resizeMode="contain"
              />
            </Animated.View>
            {showWelcomeMessage && (
              <>
                <Col absolute top={120} right={20} left={20} bottom={0} pt={20}>
                  {/* @ts-ignore */}
                  <AnimatedText
                    duration={500}
                    animation="fadeInUp"
                    center
                    fontSize={20}
                  >
                    We're getting things ready for you
                  </AnimatedText>
                  <ActivityIndicator
                    style={{ marginTop: 20 }}
                    size="small"
                    color={COLOR.MAIN}
                  />
                </Col>
              </>
            )}
            <Animated.View
              pointerEvents={isPointEventInput ? "auto" : "none"}
              style={{
                opacity: inputOpacity,
                padding: 20,
                position: "absolute",
                top: 80,
                left: 0,
                bottom: 0,
                right: 0,
              }}
            >
              {VarHelper.isDev() && (
                <Col padding={4} backgroundColor="red" middle>
                  <Text fontSize={13} colorWhite style={{ fontStyle: "italic" }}>This is not the <Text colorWhite bold>Product</Text> environment</Text>
                </Col>
              )}
              {!!errorMes ? (
                <Text color="red" bold mb1>
                  {errorMes}
                </Text>
              ) : (
                <Col height={27} />
              )}
              <Text mb1>Email</Text>
              <Input
                value={email}
                onChange={setEmail}
                // placeholder={"Input your email"}
                inputProps={{ nativeID: "input-your-email" }}
              />
              <Text mt1 mb1>
                Password
              </Text>
              <Input
                value={password}
                onChange={setPassword}
                // placeholder={"Input your password"}
                password
                inputProps={{
                  onSubmitEditing: handleLogin,
                  nativeID: "input-your-password",
                }}
              />
              <Button
                mt2
                solid
                text="LOGIN"
                width="100%"
                onPress={handleLogin}
                isLoading={loading}
              />
              <TouchField p1 alignSelf="center" onPress={onForgotPassword}>
                <Text color={COLOR.MAIN}>Forgot password?</Text>
              </TouchField>
            </Animated.View>
            <Animated.View
              pointerEvents={isPointEventOTP ? "auto" : "none"}
              style={{
                opacity: otpCodeInputOpacity,
                padding: 20,
                position: "absolute",
                top: 80,
                left: 0,
                bottom: 0,
                right: 0,
              }}
            >
              <Text h4 mt1>
                {otpType === "PHONE"
                  ? "Phone authentication"
                  : "Authentication app"}
              </Text>

              <Text mb1 mt1>
                Enter your code
              </Text>
              <OtpInput
                value={code}
                onChange={setCode}
                OTPLength={6}
                inputStyles={{
                  width: "30px",
                  height: "30px",
                  marginRight: "10px",
                }}
                otpType="number"
                autoFocus
              ></OtpInput>
              {(!!errorMes || !!errMessVerify || !!errMessGetCode) ? (
                <Text color="red" bold mt1>
                  {errorMes || errMessVerify || errMessGetCode}
                </Text>
              ) : (
                <Col height={17} />
              )}
              {otpType === "PHONE" ? (
                <Row flex justifyContent={"space-between"}>
                  <Button
                    mt1
                    solid
                    text="VERIFY"
                    width="47%"
                    onPress={confirmCode}
                    mainColor={
                      (code.length !== 6 || loadingVerify) && COLOR.GREY
                    }
                    disabled={code.length !== 6 || loadingVerify}
                    isLoading={loadingVerify}
                  />
                  <Button
                    mt1
                    solid
                    mainColor={isButtonDisabled && COLOR.GREY}
                    text={
                      isButtonDisabled
                        ? `SEND CODE (${countdown})`
                        : "SEND CODE"
                    }
                    width="47%"
                    disabled={isButtonDisabled}
                    onPress={() => sendVerification(user?.phoneNumber)}
                    isLoading={loadingGetCode}
                  />
                </Row>
              ) : (
                <Button
                  mt1
                  solid
                  text="VERIFY"
                  width="100%"
                  onPress={confirmCode}
                  mainColor={(code.length !== 6 || loadingVerify) && COLOR.GREY}
                  disabled={code.length !== 6 || loadingVerify}
                  isLoading={loadingVerify}
                />
              )}

              {user?.multiFactorType?.includes("PHONE") &&
                user?.multiFactorType?.includes("TOTP") && (
                  <Col mt1>
                    <Text center>Or</Text>
                    <Button
                      mt1
                      solid
                      text={
                        otpType === "PHONE"
                          ? "Change to authentication app"
                          : "Change to phone authentication"
                      }
                      width="100%"
                      onPress={onChangeOtpType}
                      mainColor={changeOtpTypeLoading && COLOR.GREY}
                      disabled={changeOtpTypeLoading}
                      isLoading={changeOtpTypeLoading}
                    />
                  </Col>
                )}
            </Animated.View>
          </Col>
        </Animated.View>
      </Col>
    </Col>
  );
};

export default Login;
