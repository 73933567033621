export const SCREEN = {
  Login: 'Login',
  Home: 'Home',
  ListClients: 'ListClients',
  ListGlobalAdmin: 'ListGlobalAdmin',
  AdminReport: 'AdminReport',
  ListClientAdmin: 'ListClientAdmin',
  ListAllClientAdmin: 'ListAllClientAdmin',
  ClientInfo: 'ClientInfo',
  ClientReport: 'ClientReport',
  ListPathfinder: 'ListPathfinder',
  DetailPathfinder: 'DetailPathfinder',
  ManageLearnings: 'ManageLearnings',
  ManageTags: 'ManageTags',
  DuplicatePathfinder: 'DuplicatePathfinder',
  ForgotPassword: 'ForgotPassword',
  ResetPassword: 'ResetPassword',
  AccountSetting: 'AccountSetting',
  Maintainer: 'Maintainer',
};
