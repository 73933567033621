import { Text } from "components/base";
import React from "react";
import Store from "store";
import shallow from "zustand/shallow";

const ClientName = ({ clientId }) => {
  const { clients } = Store.useClientStore(state => ({
    clients: state.clients,
  }), shallow);

  return (
    <Text>{clients?.[clientId]?.name || clientId}</Text>
  )
}

export default ClientName;
