import React, { useMemo, useState } from 'react';
import { Row, Text, Col } from 'components';
import { useNavFunc, A } from 'navigation';
import { COLOR } from 'const';

const SideNav = ({ title, screen, icon, params } : any) => {
  const { navigate, route, navigation } = useNavFunc();
  const [isHover, setIsHover] = useState(false);
  const isActive = route.name === screen;

  const bgColor = isActive || isHover ? COLOR.GREY_LIGHT : 'transparent';
  const textColor = isActive ? COLOR.MAIN : 'black';
  
  const navIcon = useMemo(() => {
    return icon({
      color: isActive ? 'white' : COLOR.MAIN,
    });
  }, [isActive]);

  return (
    <Col
      round2 m1 mh2 onPress={() => navigate(screen)}
      backgroundColor={bgColor}
      onMouseEnter={( ) => {
        setIsHover(true);
      }}
      onMouseLeave={( ) => {
        setIsHover(false);
      }}
    >
      <A route={screen} params={params}>
        <Row p1>
          <Col width30 height30 borderRadius={15} middle backgroundColor={isActive ? COLOR.MAIN : 'transparent'}>
            {navIcon}
          </Col>
          <Col flex1 dataSet={{ element: 'nav-title' }}>
            <Text ml1 fontSize={14} bold={isActive} color={textColor}>{title}</Text>
          </Col>
        </Row>
      </A>
    </Col>
  );
};

export default SideNav;
