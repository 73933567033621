import create from 'zustand';
import Store from 'store';
import _ from 'lodash';
import { VarHelper } from 'helpers';
import { TImage } from './api-client';

interface IImageStore {
  images: TImage[]
  loading: boolean,
  errors: { id: string, error: string }[],
  set: (data: any) => void,
  setLoading: (value: boolean) => void,
  getImages: (clientId?: string, query?: any) => void,
  addImages: (images: TImage[]) => void,
  deleteImage: (id: string) => void,
  updateImageType: (params: { id: string, type: string }) => void,
}

export const useImageStore = create<IImageStore>((set, get) => ({
  images: [],
  loading: false,
  errors: [],
  set,
  setLoading: (value) => {
    set({ loading: value })
  },
  getImages: async (clientId, query) => {
    try {
      if (get().loading) return;
      get().setLoading(true);
      const res = await Store.Client.Api.Image.list(VarHelper.removeUndefinedField({ clientId, ...query }));
      if (res.data.data && res.data.success) {
        set({
          images: res.data.data,
        });
      }
    } catch (error) {
    } finally {
      get().setLoading(false);
    }
  },
  addImages: async (imgs) => {
    set({
      images: imgs.concat(get().images),
    })
  },
  deleteImage: async (id: string) => {
    try {
      const res = await Store.Api.Image.remove({ id });
      if (res.data.success) {
        set({
          images: get().images.filter(i => i.id !== id)
        })
      }
    } catch (error) {
      alert(error.message || JSON.stringify(error));
    }
  },
  updateImageType: async (params: { id: string, type: string }) => {
    try {
      const res = await Store.Api.Image.update(params);
      if (res.data.success && res.data.data) {
        const newArr = [...get().images];
        const targetIdx = newArr.findIndex(i => i.id === params.id);
        newArr[targetIdx].type = res.data.data.type;
        set({ images: newArr });
      }
    } catch (error) {
      alert(error.message || JSON.stringify(error));
    }
  }
}));
