import React, { memo } from "react";
import { Row, Text } from "components";
import { COLOR } from "const";
import { ActivityIndicator } from "react-native";
import Store from "store";
import shallow from 'zustand/shallow'

const AutoSaver = () => {
  const { editedPathfinderIds } = Store.usePathfinderStore((state) => ({
    editedPathfinderIds: state.editedPathfinderIds,
  }), shallow);
  const { editedElementIds } = Store.useElementStore((state) => ({
    editedElementIds: state.editedElementIds,
  }), shallow);
  const { editedStatementIds } = Store.useStatementStore((state) => ({
    editedStatementIds: state.editedStatementIds,
  }), shallow);

  if (
    !Boolean(editedPathfinderIds?.length) &&
    !Boolean(editedElementIds?.length) &&
    !Boolean(editedStatementIds?.length)
  ) return null;

  return (
    <Row alignItems={'center'} dataSet={{ element: 'auto-saver' }}>
      <ActivityIndicator
        size={"small"}
        color={COLOR.MAIN}
        style={{ marginRight: 5 }}
      />
      <Text mr1>Auto saving...</Text>
    </Row>
  );
}

export default memo(AutoSaver);
