import { Col, Text, CMSLayout, Input, MainContentContainer, Row, Grid, ImageSelect, Button } from 'components';
import React from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { COLOR } from 'const';
import { useClientInfo } from 'store/Client.Store';

const ClientInfo: IScreen = () => {
  const { navigate } = useNavFunc();
  const { user } = Store.useUserStore();
  const { loading } = Store.useClientStore();
  const { selectedClient } = Store.useClientStore();
  const { client, edit, edited, save } = useClientInfo(user?.clientId || selectedClient?.id);

  return (
    <CMSLayout requireAuthen>
      <Col flex1 m2 p2 round1 bgWhite shadow>
        <MainContentContainer>
          <Text h3 mb4 bold color={COLOR.MAIN}>Company Logo</Text>
          <Col>
            {/* <Grid xs='100%' md='50%' stretch> */}
            {/* <Col mr2 mb2>
                <Text h6 mb1 bold>Name</Text>
                <Input
                  height={44}
                  value={client?.name}
                  onChange={(v) => edit('name', v)}
                  inputProps={{
                    style: {
                      // @ts-ignore
                      outline: 'none',
                      fontSize: 16,
                    },
                  }}
                  borderRadius={8}
                  borderWidth={1}
                  borderColor={COLOR.BORDER_LIGHT}
                />
                <Text mt3 h6 mb1 bold>Location</Text>
                <Input
                  height={44}
                  value={client?.location}
                  onChange={(v) => edit('location', v)}
                  inputProps={{
                    style: {
                      // @ts-ignore
                      outline: 'none',
                      fontSize: 16,
                    },
                  }}
                  borderRadius={8}
                  borderWidth={1}
                  borderColor={COLOR.BORDER_LIGHT}
                />
              </Col> */}
            <Col mb2 alignItems={"flex-start"}>
              {/* <Text h6 mb1 bold>Logo</Text> */}
              <Text mb3>Image dimensions: PNG - 645px - 192px</Text>
              <ImageSelect
                value={client?.logo}
                imageStyle={{ width: 500, height: 150 }}
                onChange={(url) => edit('logo', url)}
                isButtonsBottom
                entityName='logo'
              />
            </Col>
            {/* </Grid> */}
          </Col>
          <Row justifyContent={'flex-start'} mt2>
            <Button
              text='Save changes'
              borderRadius={22}
              height={44}
              width={undefined}
              paddingHorizontal={24}
              opacity={edited ? 1 : 0.4}
              disabled={!edited}
              textProps={{ fontWeight: 'bold', fontSize: 16 }}
              isLoading={loading}
              onPress={save}
            />
          </Row>
        </MainContentContainer>
      </Col>
    </CMSLayout>
  )
};

ClientInfo.routeInfo = {
  title: 'Pathfinder - Company Logo',
  path: '/my-info',
};

export default ClientInfo;
