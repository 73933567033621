import React, { useCallback, useEffect } from 'react';
import { Ionicons } from '@expo/vector-icons';
import { COLOR } from 'const';
import TouchField from '../button/TouchField';

export interface CheckBoxProps {
  value?: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  [key: string]: any;
}

const CheckBox = (props: CheckBoxProps) => {
  const {
    disabled,
    onChange,
    value,
  } = props;
  const [isChecked, setChecked] = React.useState(value);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  const onCheckBoxPress = useCallback(() => {
    if (disabled) return;
    if (onChange) {
      onChange(!isChecked);
    } else {
      setChecked(!isChecked);
    }
  }, [disabled, onChange, isChecked]);

  return (
    <TouchField disabled={disabled} width={36} height={36} middle onPress={onCheckBoxPress}>
      <Ionicons name={isChecked ? 'checkbox' : 'square-outline'} size={24} color={COLOR.MAIN} />
    </TouchField>
  );
};

export default CheckBox;
