export class RequestHelper {
  _token = "";
  _learnerToken = "";
  setToken = (t: string) => {
    this._token = t;
    if (typeof window === "undefined") return;
    localStorage.setItem("token", t);
  };
  setLearnerToken = (t: string) => {
    this._learnerToken = t;
  };
  getToken = () => {
    if (this._token) return this._token;
    const fromSaved = localStorage.getItem("token");
    if (fromSaved) {
      this._token = fromSaved;
      return fromSaved;
    }
    return "";
  };
  host = "http://localhost:3000";
  setHost = (h: string) => {
    if (h && h.endsWith("/")) {
      this.host = h.slice(0, -1);
    } else {
      this.host = h;
    }
  };
  h = (path = "") => {
    let url = this.host + path;
    // if (this.host.endsWith("/") && path.startsWith("/")) {
    //   url = url.replace("//", "/");
    // }
    return url;
  };

  makeHeader = (
    method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
    isUpload = false
  ) => {
    let token = this.getToken();
    let headers = new Headers({
      Accept: "application/json, text/plain, */*",
    });
    if (token) headers.set("Authorization", token);
    if (this._learnerToken) headers.set("Learner", this._learnerToken);
    if (method === "POST" || method === "PUT")
      headers.set("Content-Type", "application/json");
    if (isUpload) {
      // headers["Content-Type"] = 'multipart/form-data';
      // @ts-ignore
      delete headers["Accept"];
      delete headers["Content-Type"];
    }
    return headers;
  };
  querify = (url: string, queryObject?: { [key: string]: any }) => {
    let newUrl = url;
    if (!queryObject) return newUrl;
    let queryString = "";
    for (let key in queryObject) {
      queryString += queryString.length > 0 ? "&" : "";
      queryString += `${key}=${queryObject[key]}`;
    }
    newUrl += "?" + queryString;
    return newUrl;
  };

  returnFetchResponse = async (
    response: Response
  ): Promise<{ headers: Headers; data: any; status: number; }> => {
    // console.log("response.headers", response.headers.get("content-type"));
    if (response.headers.get("content-type") === "application/pdf") {
      const blob = await response.blob();
      return {
        headers: response.headers,
        status: response.status,
        data: blob,
      };
    }
    let data = await response.text();
    try {
      data = JSON.parse(data);
    } catch (_) { }
    return {
      status: response.status,
      headers: response.headers,
      data: data,
    };
  };

  get = async (URL: string, queryObject?: { [key: string]: any }) => {
    const urlWithQuery = this.querify(this.h(URL), queryObject);
    const res = await fetch(urlWithQuery, {
      method: "GET",
      headers: this.makeHeader("GET"),
    });
    return await this.returnFetchResponse(res);
  };

  post = async (URL: string, bodyObject?: any) => {
    const res = await fetch(this.h(URL), {
      method: "POST",
      headers: this.makeHeader("POST"),
      body: JSON.stringify(bodyObject || {}),
    });
    return await this.returnFetchResponse(res);
  };

  put = async (URL: string, bodyObject?: any) => {
    const res = await fetch(this.h(URL), {
      method: "PUT",
      headers: this.makeHeader("POST"),
      body: JSON.stringify(bodyObject || {}),
    });
    return await this.returnFetchResponse(res);
  };

  delete = async (URL: string, queryObject?: { [key: string]: any }) => {
    const urlWithQuery = this.querify(this.h(URL), queryObject);
    const res = await fetch(urlWithQuery, {
      method: "DELETE",
      headers: this.makeHeader("GET"),
    });
    return await this.returnFetchResponse(res);
  };

  upload = async (URL: string, file: any) => {
    const formData = new FormData();
    formData.append('file', file);
    const res = await fetch(this.h(URL), {
      method: 'POST',
      headers: this.makeHeader("POST", true),
      body: formData,
    });
    return await this.returnFetchResponse(res);
  }

  call = async (
    URL: string,
    method: string,
    params?: { [key: string]: any },
    query?: { [key: string]: any },
    body = {}
  ) => {
    let url = this.h(URL);
    if (query) url = this.querify(url, query);
    if (params) {
      for (let key in params) {
        url = url.replace(":" + key, params[key]);
      }
    }
    const res = await fetch(url, {
      method: method.toUpperCase(),
      headers: this.makeHeader(
        ["GET", "DELETE"].includes(method.toUpperCase()) ? "GET" : "POST"
      ),
      body: ["GET", "DELETE"].includes(method.toUpperCase())
        ? undefined
        : JSON.stringify(body),
    });
    return await this.returnFetchResponse(res);
  };
}

export default new RequestHelper();
