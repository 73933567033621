import { Col, Text, CMSLayout, Table, Row, TouchField, modal, MergeTagsModal, LearningsByTagModal, modalConfirm } from 'components';
import React, { useEffect, useCallback, useMemo } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { COLOR } from 'const';
import { FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import shallow from 'zustand/shallow';
import { Tooltip } from 'react-tippy';
import { useLearningStore } from 'store/Learning.Store';

const ManageTags: IScreen = () => {
  const { user } = Store.useUserStore();
  const { learnings, getLearnings, learningIds, loading, removeTag } = useLearningStore(state => ({
    learnings: state.learnings,
    getLearnings: state.getLearnings,
    learningIds: state.learningIds,
    loading: state.loading,
    removeTag: state.removeTag,
  }), shallow);
  const { selectedClient } = Store.useClientStore();

  useEffect(() => {
    if (user?.clientId || selectedClient?.id) {
      getLearnings(user?.clientId || selectedClient?.id)
    }
  }, [user?.clientId, selectedClient])

  const showModalMergeTags = useCallback(() => {
    modal.show(
      <MergeTagsModal
        title='Merge tags'
        refresh={() => getLearnings(user?.clientId || selectedClient?.id)}
      />
    )
  }, [getLearnings, user, selectedClient])

  const confirmDelete = (tag) => {
    modalConfirm.show({
      title: 'Delete',
      message: 'Do you want to delete this tag?',
      onConfirm: async () => {
        await removeTag(tag);
      }
    })
  }

  const tableRows = useMemo(() => {
    if (!learnings) return [];

    const onPressDetail = (tag) => {
      const filtered = [];
      Object.keys(learnings).map(key => {
        const learning = learnings[key];
        if (learning?.tags?.includes(tag)) {
          filtered.push(learning);
        }
      })
      modal.show(
        <LearningsByTagModal
          learnings={filtered}
          tag={tag}
        />
      )
    }

    const tags = [];
    Object.keys(learnings).map(key => {
      const learning = learnings[key];
      learning?.tags?.split(',').forEach(key => {
        if (key && !tags.some(i => i.name === key.trim())) {
          tags.push({
            name: key.trim(),
            count: Object.keys(learnings).map(i => learnings[i]).filter(i => i?.tags?.includes?.(key.trim())).length,
          })
        }
      })
    })

    return tags.map(i => ({
      ...i,
      actions: <Row justifyContent='flex-end'>
        <TouchField paddingHorizontal={12} paddingVertical={8} onPress={() => onPressDetail(i.name)}>
          <Text color={COLOR.MAIN}>Learnings</Text>
        </TouchField>
        <Tooltip title='Delete tag' trigger='mouseenter'>
          <TouchField
            onPress={() => confirmDelete(i.value)}
            padding={6}
            marginLeft={6}
          >
            <FontAwesome5
              name={'trash'}
              size={22}
              color={COLOR.RED}
            />
          </TouchField>
        </Tooltip>
      </Row>
    }))
  }, [learnings, getLearnings, user, learningIds, selectedClient])

  return (
    <CMSLayout requireAuthen>
      <Col flex1 m2 p2 round1 bgWhite shadow>
        <Row alignItems={'flex-start'} justifyContent={'space-between'}>
          <Text h4 mb2 nativeID={'heading'}>Tags</Text>
          <Row>
            <Tooltip title='Merge tags' trigger='mouseenter'>
              <TouchField
                marginLeft={8}
                width={36} height={36}
                borderRadius={18} middle
                onPress={showModalMergeTags}
                dataSet={{ element: 'merge-tags-btn' }}
              >
                <MaterialCommunityIcons name='table-merge-cells' solid size={26} color={COLOR.MAIN} />
              </TouchField>
            </Tooltip>
          </Row>
        </Row>
        <Table
          columns={[
            { key: 'name', title: 'Name', flex: 1 },
            { key: 'count', title: 'Total learnings', flex: 1 },
            { key: 'actions', title: '', width: 180 },
          ]}
          data={tableRows}
          style={{ margin: 20 }}
          minWidthRequired={500}
          isLoading={loading}
          rowProps={{
            paddingVertical: 16,
          }}
        />
      </Col>
    </CMSLayout>
  )
};

ManageTags.routeInfo = {
  title: 'Manage Tags',
  path: '/manage-tags',
};

export default ManageTags;
