import { FontAwesome5 } from "@expo/vector-icons";
import { ASSETS } from "assets";
import { Col, Input, Row, Text, TouchField, modal } from "components";
import { COLOR } from "const";
import React, { useState } from "react";
import { ActivityIndicator, Image as RNImage, StyleSheet, useWindowDimensions } from "react-native";
import ReactHtmlParser from 'react-html-parser';
import { VarHelper } from "helpers";
import Store from "store";

interface Props {
  title?: string
  body?: string
  footer?: string
  pathfinderName?: string
  pathfinderId?: string
}

const PreviewEmailModal = ({ title, body, footer, pathfinderName, pathfinderId }: Props) => {
  const { width } = useWindowDimensions()
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const mockData = {
    FirstName: "John",
    PathfinderName: pathfinderName || "Pathfinder 10",
  };

  const onPressSend = async () => {
    try {
      if (loading) return;
      setLoading(true);
      await Store.Api.Pathfinder.demoEmail({
        email,
        pathfinderId,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  return (
    <Col padding={24} paddingBottom={12} paddingRight={16} borderRadius={8} minWidth={Math.min(700, width - 64)} bgWhite>
      <Row alignItems='center' justifyContent='space-between' marginBottom={24}>
        <Text fontSize={18} semiBold color={COLOR.MAIN}>{title}</Text>
        <TouchField padding={4} onPress={() => modal.hide()}>
          <FontAwesome5 name='times' size={18} color={COLOR.MAIN} />
        </TouchField>
      </Row>
      <Col padding={8} borderRadius={8} borderColor={COLOR.BORDER_LIGHT} borderWidth={1}>
        {ReactHtmlParser(VarHelper.replacePlaceholders(body, mockData))}
        <RNImage
          source={ASSETS.EMAIL_TABLE_PREVIEW}
          style={{ width: '100%', aspectRatio: 500 / 270 }}
        />
        {ReactHtmlParser(VarHelper.replacePlaceholders(footer, mockData))}
      </Col>
      <Col mt2>
        <Text caption mb1 bold>Send demo email</Text>
        <Row>
          <Input
            height={40}
            value={email}
            onChange={setEmail}
            inputProps={{
              style: {
                // @ts-ignore
                outline: 'none',
                fontSize: 16,
              },
            }}
            flex1
            borderRadius={8}
            borderWidth={1}
            placeholder="Enter email that you wish to receive demo email"
            borderColor={COLOR.BORDER_LIGHT}
          />
          <TouchField
            height={40} middle
            borderColor={COLOR.MAIN}
            borderRadius={20}
            borderWidth={1}
            backgroundColor={COLOR.MAIN}
            m0 ph2
            onPress={onPressSend}
          >
            <Row>
              {loading && <ActivityIndicator size='small' color={'white'} style={{ marginRight: 5 }} />}
              <Text color={COLOR.WHITE}>Send</Text>
            </Row>
          </TouchField>
        </Row>
      </Col>
    </Col>
  )
}

const styles = StyleSheet.create({
  normalText: {
    fontSize: 14,
    fontFamily: 'Arial',
  }
})

export default PreviewEmailModal
