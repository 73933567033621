
export type TUserType = 'admin' | 'client' | 'learner';
export enum MULTI_FACTOR_TYPE {
  NONE = "NONE",
  PHONE = "PHONE",
  TOTP = "TOTP"
}

export type TUser = {
  id: string;
  clientId?: string;
  role: TUserType;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  photoUrl?: string;
  otherData?: any;
  resetPasswordCode?: string;
  multiFactorType?: string | string[];
  phoneNumber: string;
  secret: string;

  addressLine1?: string;
  addressLine2?: string;
  town?: string;
  country?: string;
  postCode?: string;

  resellerStripeId?: string;
  deletedAt?: string;

  // db fields
  createdAt?: string;
  updatedAt?: string;
}
