import { Col, Text, CMSLayout, MainContentContainer, Row, Button, Select, modal, PathfinderSelectModal, Input } from 'components';
import React, { useEffect, useState } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { COLOR } from 'const';
import shallow from 'zustand/shallow';

const DuplicatePathfinder: IScreen = () => {
  const [selectedPathfinder, setSelectedPathfinder] = useState<string>()
  const [selectedClient, setSelectedClient] = useState<string>()
  const [isLoading, setLoading] = useState(false)
  const { clients, getClients } = Store.useClientStore(state => ({
    clients: state.clients,
    getClients: state.getClients,
  }), shallow);
  const { getPathfinders, pathfinders } = Store.usePathfinderStore((state) => ({
    getPathfinders: state.getPathfinders,
    pathfinders: state.pathfinders,
  }), shallow);

  useEffect(() => {
    getClients();
    getPathfinders(undefined);
  }, []);

  const onSubmit = async () => {
    try {
      if (isLoading) return;
      setLoading(true);
      const res = await Store.Api.Pathfinder.duplicate({
        pathfinderId: selectedPathfinder,
        clientId: selectedClient,
      })
      if (!res?.data?.success) {
        alert(res.data.error);
        return;
      }
      alert('Duplicating completed');
      setSelectedPathfinder('');
      setSelectedClient('');
    } catch (error) {
      alert(error?.message || JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  }

  const showModalSelectPathfinder = () => {
    const onChange = (id) => {
      setSelectedPathfinder(id);
      modal.hide();
    }
    modal.show(<PathfinderSelectModal selectedId={selectedPathfinder} onChange={onChange} />)
  }

  const canSubmit = selectedPathfinder && selectedClient;

  return (
    <CMSLayout requireAuthen>
      <Col flex1 m2 p2 round1 bgWhite shadow>
        <MainContentContainer>
          <Text h3 mb4 bold color={COLOR.MAIN}>Duplicate Pathfinder</Text>
          <Col>
            <Col mt2>
              <Text caption mb1 bold>Select pathfinder</Text>
              <Input
                height={40}
                borderRadius={8}
                borderWidth={1}
                value={pathfinders[selectedPathfinder]?.name}
                onPress={showModalSelectPathfinder}
                inputProps={{
                  style: {
                    // @ts-ignore
                    outline: 'none',
                    fontSize: 16,
                  },
                }}
                borderColor={COLOR.BORDER_LIGHT}
              />
            </Col>
            <Col mt2>
              <Text caption mb1 bold>Save to Client</Text>
              <Select
                options={Object.keys(clients).map(i => ({
                  value: clients[i]?.id,
                  label: clients[i]?.name,
                }))}
                value={{ value: selectedClient, label: clients[selectedClient]?.name }}
                onChange={(e) => setSelectedClient(e?.value)}
              />
            </Col>
          </Col>
          <Row justifyContent={'flex-start'} mt3>
            <Button
              text='Duplicate'
              borderRadius={22}
              height={44}
              width={undefined}
              paddingHorizontal={24}
              opacity={canSubmit ? 1 : 0.4}
              disabled={!canSubmit}
              textProps={{ fontWeight: 'bold', fontSize: 16 }}
              isLoading={isLoading}
              onPress={onSubmit}
            />
          </Row>
        </MainContentContainer>
      </Col>
    </CMSLayout>
  )
};

DuplicatePathfinder.routeInfo = {
  title: 'Duplicate Pathfinder',
  path: '/duplicate-pathfinder',
};

export default DuplicatePathfinder;
