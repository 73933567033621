import React, { useState, useEffect, useMemo } from 'react';
import * as Animatable from 'react-native-animatable';
import { HEADER_LOGIN_HEIGHT } from './CMSLayout';
import Store from 'store';
import { adminNav, clientNav } from './nav';
import SideNav from './SideNav';

const Drawer = ({ navigate, show }: any) => {
  const [removed, setRemoved] = useState(true);
  const UserStore = Store.useUserStore();
  const { selectedClient } = Store.useClientStore();

  useEffect(() => {
    if (show) {
      setRemoved(false);
    } else {
      setTimeout(() => {
        setRemoved(true);
      }, 500);
    }
  }, [show]);

  const nav = useMemo(() => {
    const isClient = UserStore.user?.role === 'client' || (UserStore.user?.role === 'admin' && selectedClient?.id);
    return isClient ? clientNav : adminNav;
  }, []);

  return removed ? null : (
    <Animatable.View
      animation={show ? 'fadeInLeft' : 'fadeOutLeft'}
      duration={500}
      style={{
        // width: width,
        // height: height - HEADER_LOGIN_HEIGHT,
        backgroundColor: 'white',
        position: 'absolute',
        left: 0,
        bottom: 0,
        right: 0,
        top: HEADER_LOGIN_HEIGHT,
      }}
    >
      {nav.map((val, i) => (
        <SideNav key={val.title} title={val.title} screen={val.screen} icon={val.icon} />
      ))}
    </Animatable.View>
  )
};

export default Drawer;
