import React, { useCallback, useState } from 'react';
import { Col, Text, Table, Row, TouchField, CheckBox, modal } from 'components';
import { COLOR } from 'const';
import { ActivityIndicator } from 'react-native';
import Store from 'store';

interface StatementImportItem {
  statement: string;
  rollover1?: string;
  rollover2?: string;
  rollover3?: string;
  [key: string]: any;
}

const ConfirmImportModal = ({ statements, pathfinderId }: {
  statements: StatementImportItem[],
  pathfinderId: string,
}) => {
  const [loading, setLoading] = useState(false);
  const [shouldUpdateExisting, setShouldUpdateExisting] = useState(false);
  const { getStatements } = Store.useStatementStore();

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      await Store.Api.Statement.import({
        statements,
        updateExisting: shouldUpdateExisting,
        pathfinderId,
      });
      getStatements(pathfinderId);
      modal.hide();
    } catch (error) {
      alert(error?.message || error);
    } finally {
      setLoading(false);
    }
  }, [shouldUpdateExisting])

  return (
    <Col round1 bgWhite shadow p2>
      <Text fontSize={16} semiBold mb2>Confirm Import</Text>
      <Table
        columns={[
          { key: 'statement', title: 'Statement', flex: 1 },
          { key: 'rollover1', title: 'Left Rollover', flex: 1 },
          { key: 'rollover2', title: 'Middle Rollover', flex: 1 },
          { key: 'rollover3', title: 'Right Rollover', flex: 1 },
          { key: 'element', title: 'Element', flex: 1 },
        ]}
        data={statements}
        style={{ margin: 20 }}
        minWidthRequired={500}
      />
      <Text mt2 mb1>If the pathfinder's statement already exists, then:</Text>
      <Col>
        <Row>
          <CheckBox
            value={!shouldUpdateExisting}
            onChange={() => setShouldUpdateExisting(s => !s)}
          />
          <Text onPress={() => setShouldUpdateExisting(s => !s)}>Create new</Text>
        </Row>
        <Row>
          <CheckBox
            value={shouldUpdateExisting}
            onChange={() => setShouldUpdateExisting(s => !s)}
          />
          <Text onPress={() => setShouldUpdateExisting(s => !s)}>Update existing</Text>
        </Row>
      </Col>
      <Row justifyContent={'flex-end'} mt2>
        <TouchField
          height={40}
          width={80}
          middle
          borderColor={COLOR.MAIN}
          borderRadius={20}
          borderWidth={1}
          onPress={() => modal.hide()}
          m0
          ph2
        >
          <Text color={COLOR.MAIN}>Cancel</Text>
        </TouchField>
        <TouchField
          height={40}
          ph2
          middle
          borderColor={COLOR.MAIN}
          borderRadius={20}
          borderWidth={1}
          backgroundColor={COLOR.MAIN}
          m0
          onPress={onSubmit}
        >
          <Row>
            {loading && <ActivityIndicator size='small' color={'white'} style={{ marginRight: 5 }} />}
            <Text color={'white'}>Submit</Text>
          </Row>
        </TouchField>
      </Row>
    </Col>
  );
};

export default ConfirmImportModal;
