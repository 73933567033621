import { Col, Text, CMSLayout, Select, DatePicker, Button, Table, Row } from 'components';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { IScreen, TLearnerLikerts, TPathfinder, TStatement } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { COLOR } from 'const';
import shallow from 'zustand/shallow';
import { cloneDeep } from 'lodash';
import { VarHelper } from 'helpers';
import ExportHelper from 'helpers/ExportHelper';

type TableRowItem = {
  id?: string,
  name?: string,
  email?: string,
  pathfinderName?: string,
  statement?: string,
  likert?: string
  learnings?: string
  createdAt?: string
}

type SearchDataType = {
  clientId?: string,
  statementId?: string,
  pathfinderId?: string,
  fromDate?: string,
  toDate?: string,
}

const AdminReport: IScreen = () => {
  const { navigate } = useNavFunc();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<TableRowItem[]>([]);
  const [showTable, setShowTable] = useState(false);
  const [searchData, setSearchData] = useState<SearchDataType>({});
  const { getStatements } = Store.useStatementStore((state) => ({
    getStatements: state.getStatements,
  }), shallow);
  const { getLearners, learners } = Store.useLearnerStore(state => ({
    getLearners: state.getLearners,
    learners: state.learners
  }))
  const { get: getClientState } = Store.useClientStore(state => ({
    get: state.get,
  }), shallow);
  const { getPathfinders } = Store.usePathfinderStore((state) => ({
    getPathfinders: state.getPathfinders,
  }), shallow);

  const [clients, setClients] = useState({});

  const [pathfinders, setPathfinders] = useState<TPathfinder[]>([]);
  const [statements, setStatements] = useState<TStatement[]>([]);

  const editSearch = useCallback((key, value) => {
    setSearchData(state => {
      const newState = cloneDeep(state);
      if (newState) newState[key] = value;
      return newState;
    });
  }, [])

  const loadClients = (tryTime = 0) => {
    const obj = getClientState().clients;
    if (!obj || !Object.keys(obj).length) {
      if (tryTime > 5) return;
      setTimeout(() => {
        loadClients(tryTime + 1);
      }, 200);
    } else {
      setClients({ ...obj });
    }
  }

  useEffect(() => {
    getStatements(undefined).then(a => setStatements(a));
    getPathfinders(undefined).then(a => setPathfinders(a));
    getLearners();
    setTimeout(() => {
      loadClients();
    }, 700);
  }, [])

  const onClear = useCallback(() => {
    setSearchData({});
    setShowTable(false);
  }, [])

  const onPressGenerate = useCallback(async (isExport = false) => {
    try {
      if (!isExport) setShowTable(true);
      if (loading) return;
      setLoading(true);
      const res = await Store.Api.Learner.report(
        VarHelper.removeUndefinedNullField({
          clientId: searchData?.clientId,
          pathfinderId: searchData?.pathfinderId,
          statementId: searchData?.statementId,
          fromDate: searchData?.fromDate,
          toDate: searchData?.toDate,
        })
      )
      if (res?.data?.data) {
        let _result = [];
        res.data.data.forEach((i: TLearnerLikerts) => {
          Object.keys(i.likerts).forEach((statementId, idx) => {
            const learner = learners[i.learnerId];
            const statement = i.likerts[statementId];
            _result.push({
              email: learner?.email,
              name: `${learner?.firstName || ''} ${learner?.lastName || ''}`,
              pathfinderName: i.pathfinderName,
              createdAt: i.createdAt,
              likert: `[ ${statement.likert || ''} ]`,
              statementId,
            })
          })
        })
        if (searchData?.statementId) {
          _result = _result.filter(i => i.statementId === searchData?.statementId);
        }
        if (isExport) {
          ExportHelper.saveArrayToFile(_result, {
            fileName: 'admin-report.xlsx'
          });
        } else {
          setResult(_result);
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [learners, loading, searchData])

  const filteredPathfinders = useMemo(() => {
    let allPathfinders = [...pathfinders];
    if (searchData.clientId) {
      allPathfinders = allPathfinders.filter(i => i.clientId === searchData.clientId);
    }
    return allPathfinders.map(i => ({
      value: i?.id,
      label: i?.name || i?.id,
    }));
  }, [pathfinders, searchData.clientId]);

  const filteredStatements = useMemo(() => {
    let allStatements = [...statements];
    if (searchData.clientId) {
      allStatements = allStatements.filter(i => i.clientId === searchData.clientId);
    }
    if (searchData.pathfinderId) {
      allStatements = allStatements.filter(i => i.pathfinderId === searchData.pathfinderId);
    }
    return allStatements.map(i => ({
      value: i?.id,
      label: i?.statement || i?.id,
    }));
  }, [statements, searchData.clientId, searchData.pathfinderId]);

  const selectedPathfinder = pathfinders?.find(i => i.id === searchData?.pathfinderId);

  return (
    <CMSLayout requireAuthen>
      <Col flex1 m2 p2 round1 bgWhite shadow>
        <Text h4 mb2 nativeID={'heading'}>Global Reports</Text>
        <Col m0 mt1>
          <Text caption mb1 bold>Client</Text>
          <Select
            options={Object.keys(clients).map(i => ({
              value: clients[i]?.id,
              label: clients[i]?.name,
            }))}
            value={{ value: searchData.clientId, label: clients[searchData.clientId]?.name }}
            onChange={(e) => editSearch('clientId', e?.value)}
            innerProps={{ isClearable: true }}
          />
        </Col>
        <Col m0 mt1>
          <Text caption mb1 bold>Pathfinder</Text>
          <Select
            options={filteredPathfinders}
            value={{ value: searchData?.pathfinderId, label: selectedPathfinder?.name || selectedPathfinder?.id }}
            onChange={(e) => editSearch('pathfinderId', e?.value)}
            innerProps={{ isClearable: true }}
          />
        </Col>
        <Col m0 mt1>
          <Text caption mb1 bold>Work Statement</Text>
          <Select
            options={filteredStatements}
            value={{ value: searchData?.statementId, label: statements?.find(i => i.id === searchData?.statementId)?.statement }}
            onChange={(e) => editSearch('statementId', e?.value)}
            innerProps={{ isClearable: true }}
          />
        </Col>
        <Row zIndex={'unset'}>
          <Col m0 mt1 flex1 zIndex={'unset'}>
            <Text caption mb1 bold>From date</Text>
            <DatePicker
              value={searchData?.fromDate}
              onChange={(v) => editSearch('fromDate', v)}
            />
          </Col>
          <Col m0 mt1 flex1 zIndex={'unset'}>
            <Text caption mb1 bold>To date</Text>
            <DatePicker
              value={searchData?.toDate}
              onChange={(v) => editSearch('toDate', v)}
            />
          </Col>
        </Row>
        <Row mb2>
          <Col m0 mt1 flex1>
            <Button text='Generate' width={undefined} onPress={() => onPressGenerate()} />
          </Col>
          <Col m0 mt1 flex1>
            <Button
              text='Export' width={undefined} onPress={() => onPressGenerate(true)}
              // disabled={!canSubmit} opacity={canSubmit ? 1 : 0.2}
              backgroundColor={COLOR.BLUE} bgHovered='transparent'
            />
          </Col>
          <Col m0 mt1 flex1>
            <Button
              text='Clear' width={undefined}
              onPress={onClear}
              nativeID='clear-filter'
              backgroundColor={'transparent'}
              bgHovered='transparent'
              borderColor={COLOR.MAIN}
              borderWidth={1}
              textProps={{ fontWeight: 'bold', color: COLOR.MAIN }}
            />
          </Col>
        </Row>
        {showTable &&
          <Table
            columns={[
              { key: 'email', title: 'Email', flex: 1 },
              { key: 'name', title: 'Name', flex: 1 },
              { key: 'pathfinderName', title: 'Pathfinder', flex: 1 },
              { key: 'createdAt', title: 'Completed Date', flex: 1 },
              { key: 'likert', title: 'User\'s Response', flex: 1 },
            ]}
            data={result}
            style={{ margin: 20 }}
            minWidthRequired={500}
            rowProps={{
              paddingVertical: 16,
            }}
            isLoading={loading}
          />
        }
      </Col>
    </CMSLayout >
  )
};

AdminReport.routeInfo = {
  title: 'Pathfinder - Global Report',
  path: '/report/global',
};

export default AdminReport;
