import { FontAwesome5 } from "@expo/vector-icons";
import { Col, Input, Row, Select, Text, TouchField, modal } from "components";
import { COLOR } from "const";
import { VarHelper } from "helpers";
import { cloneDeep } from "lodash";
import React, { useCallback, useState } from "react";
import { ActivityIndicator, useWindowDimensions } from "react-native";
import Store from "store";

const fields = [
  { id: 'name', label: 'Name' },
  { id: 'location', label: 'Location (Optional)' },
  { id: 'slug', label: 'Client Slug' },
]

const regionOpts = [
  { value: "us", label: "US" },
  { value: "uk", label: "UK" },
]

interface Props {
  data?: any
  title?: string
  refresh?: () => void
}

const ClientDetailModal = ({ data, title, refresh }: Props) => {
  const [fieldsData, setFieldsData] = useState(data || {})
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { width } = useWindowDimensions()

  const editField = useCallback((key, value) => {
    setFieldsData(state => {
      const newState = cloneDeep(state);
      newState[key] = value;
      return newState;
    });
  }, [])

  const onPressConfirm = useCallback(async () => {
    try {
      setLoading(true);
      let res;
      if (data) {
        res = await Store.Api.Client.update(VarHelper.removeUndefinedNullField({
          id: fieldsData.id,
          name: fieldsData.name,
          location: fieldsData.location,
        }))
      } else {
        res = await Store.Api.Client.create(VarHelper.removeUndefinedNullField({
          name: fieldsData.name,
          location: fieldsData.location,
          slug: fieldsData.slug,
          region: fieldsData.region,
        }))
      }
      if (!res.data.success) {
        setError(res.data.error);
        return;
      }
      setError('');
      refresh?.();
      modal.hide();
    } catch (error) {
      setError(error?.message || JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  }, [fieldsData, data])

  const canSubmit = !!fieldsData.slug && !!fieldsData.region && !!fieldsData.name;

  return (
    <Col padding={24} paddingBottom={12} paddingRight={16} borderRadius={8} minWidth={Math.min(500, width - 64)} bgWhite>
      <Row alignItems='center' justifyContent='space-between' marginBottom={24}>
        <Text fontSize={20} semiBold color={COLOR.MAIN}>{title}</Text>
        <TouchField padding={4} onPress={() => modal.hide()}>
          <FontAwesome5 name='times' size={18} color={COLOR.MAIN} />
        </TouchField>
      </Row>
      {fields.map(field => (
        <Col key={field.id} mt2>
          <Text caption mb1 bold>{field.label}</Text>
          <Input
            height={40}
            value={fieldsData[field.id]}
            onChange={(v) => editField(field.id, v)}
            inputProps={{
              style: {
                // @ts-ignore
                outline: 'none',
                fontSize: 16,
              },
            }}
            disabled={field.id === "slug" && !!data}
            borderRadius={8}
            borderWidth={1}
            borderColor={COLOR.BORDER_LIGHT}
          />
        </Col>
      ))}
      <Col mt2>
        <Text caption mb1 bold>Region</Text>
        <Select
          options={regionOpts}
          disabled={!!data}
          value={regionOpts.find(i => i.value === fieldsData.region)}
          onChange={(e) => editField('region', e?.value)}
        />
      </Col>

      {!!error &&
        <Text color={COLOR.RED} marginTop={8}>{error}</Text>
      }
      <Row justifyContent={'flex-end'} marginTop={24}>
        <TouchField
          height={40}
          width={80}
          middle
          borderColor={COLOR.MAIN}
          borderRadius={20}
          borderWidth={1}
          onPress={() => modal.hide()}
          m0 ph2
        >
          <Text color={COLOR.MAIN}>Cancel</Text>
        </TouchField>
        <TouchField
          height={40}
          middle
          borderColor={COLOR.MAIN}
          borderRadius={20}
          borderWidth={1}
          backgroundColor={COLOR.MAIN}
          m0 ph2
          opacity={canSubmit ? 1 : 0.7}
          disabled={!canSubmit}
          onPress={onPressConfirm}
        >
          <Row>
            {loading && <ActivityIndicator size='small' color={'white'} style={{ marginRight: 5 }} />}
            <Text color={COLOR.WHITE}>Save</Text>
          </Row>
        </TouchField>
      </Row>
    </Col>
  )
}

export default ClientDetailModal
