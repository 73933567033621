import { Col, Text, CMSLayout, Table, Row, TouchField, modal, modalConfirm, LearningDetailModal, MergeTagsModal, ImportLearningButton, ImageUploaderModal, Image, Input, Select, TagsManagerModal } from 'components';
import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { COLOR } from 'const';
import { FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import shallow from 'zustand/shallow';
import { Tooltip } from 'react-tippy';
import RequestUtils from 'store/api-client/Request.utils';
import { isEmpty, toLower } from 'lodash';

const ListLearnings: IScreen = () => {
  const { navigate } = useNavFunc();
  const { user } = Store.useUserStore();
  const [searchKey, setSearchKey] = useState('')
  const [sortBy, setSortBy] = useState('');
  const [selectedTag, setSelectedTag] = useState<any>();
  const [selectedType, setSelectedType] = useState<any>();
  const [selectedLearning, setSelectedLearning] = useState([]);
  const { learnings, getLearnings, learningIds, loading } = Store.useLearningStore(state => ({
    learnings: state.learnings,
    getLearnings: state.getLearnings,
    learningIds: state.learningIds,
    loading: state.loading,
  }), shallow);
  const { selectedClient } = Store.useClientStore();
  const { getImages } = Store.useImageStore();

  useEffect(() => {
    setSelectedLearning([]);
  }, [selectedTag, selectedType]);

  const [allTags, allTypes] = useMemo(() => {
    let tags = [];
    let types = [];
    Object.keys(learnings).map(key => {
      const learning = learnings[key];
      learning?.tags?.split(',').forEach(key => {
        if (key && !tags.includes(key.trim())) tags.push(key.trim())
      })
      if (learning?.type) {
        if (!types.includes(learning.type)) types.push(learning.type)
      }
    })
    tags = tags.sort((a,b) => toLower(a).localeCompare(toLower(b)));
    types = types.sort((a,b) => toLower(a).localeCompare(toLower(b)));
    return [
      tags.map(i => ({ label: i, value: i })),
      types.map(i => ({ label: i, value: i })),
    ];
  }, [learnings, learningIds])

  useEffect(() => {
    getImages(user?.clientId || selectedClient?.id);
  }, [user?.clientId, selectedClient])

  useEffect(() => {
    if (user?.clientId || selectedClient?.id) {
      getLearnings(user?.clientId || selectedClient?.id, {
        sortBy,
      })
    }
  }, [user?.clientId, selectedClient, sortBy])

  const onSelectLearning = (id) => {
    setSelectedLearning(s => s.includes(id)
      ? s.filter(i => i !== id)
      : s.concat([id])
    )
  }

  const downloadTemplate = useCallback(() => {
    window.open(`https://pathfinder-cd.fra1.digitaloceanspaces.com/TemplateLearning.xlsx`)
  }, [])

  const showUploadImage = useCallback(() => {
    modal.show(
      <ImageUploaderModal clientId={user?.clientId || selectedClient?.id} />, {
      contentStyle: {
        width: "70%",
        minWidth: 300,
      }
    }
    )
  }, [])

  const showModalMergeTags = useCallback(() => {
    modal.show(
      <MergeTagsModal
        title='Merge tags'
        refresh={() => getLearnings(user?.clientId || selectedClient?.id)}
      />
    )
  }, [getLearnings, user, selectedClient])

  const showModalTagsManager = useCallback(() => {
    modal.show(
      <TagsManagerModal />
    )
  }, [getLearnings, user, selectedClient])

  const showModalCreateLearning = useCallback(() => {
    modal.show(
      <LearningDetailModal
        title='Create Learning'
        data={undefined}
        refresh={() => {
          getLearnings(user?.clientId || selectedClient?.id);
          getImages(user?.clientId || selectedClient?.id);
        }}
      />
    )
  }, [getLearnings, user, selectedClient])

  const confirmDelete = () => {
    modalConfirm.show({
      title: 'Delete',
      message: `Do you want to delete ${selectedLearning.length} learning${selectedLearning.length > 1 ? 's' : ''}?`,
      onConfirm: async () => {
        await Promise.all(selectedLearning.map(id =>
          Store.Api.Learning.remove({ id })
        ))
        setSelectedLearning([]);
        getLearnings(user.clientId || selectedClient?.id);
      }
    })
  }

  const filteredLearning = useMemo(() => {
    if (isEmpty(learningIds)) return [];
    let results = learningIds;
    if (searchKey) results = results.filter(i => toLower(learnings[i].name).includes(toLower(searchKey)));
    if (selectedTag?.value) results = results.filter(i => learnings[i].tags && toLower(learnings[i].tags).includes(toLower(selectedTag?.value)));
    if (selectedType?.value) results = results.filter(i => learnings[i].type && toLower(learnings[i].type) === toLower(selectedType?.value));
    return results;
  }, [learningIds, learnings, searchKey, selectedTag, selectedType])

  const selectedAll = !filteredLearning.some(i => !selectedLearning.includes(i));

  const onSelectAll = () => {
    const newValue = selectedAll ? [] : filteredLearning;
    console.log("onSelectAll", newValue)
    setSelectedLearning(newValue);
  }

  const tableRows = useMemo(() => {
    if (!learnings) return [];
    const onPressDelete = (learning) => {
      modalConfirm.show({
        title: 'Delete',
        message: 'Do you want to delete this learning',
        onConfirm: async () => {
          await Store.Api.Learning.remove({
            id: learning?.id,
          });
          getLearnings(user.clientId || selectedClient?.id)
        }
      })
    }
    const onPressEdit = (learning) => {
      modal.show(
        <LearningDetailModal
          title='Edit Learning'
          data={learning}
          refresh={() => getLearnings(user.clientId || selectedClient?.id)}
        />
      )
    }

    return filteredLearning.map(i => ({
      ...learnings[i],
      url: <a target='_blank' style={{ color: "blue", fontSize: 14 }} href={learnings[i].url}>{learnings[i].urlShortName || learnings[i].url}</a>,
      icon: <Image source={{ uri: learnings[i].icon }} style={{ width: 60, height: 60 }} resizeMode='contain' />,
      actions: <Row justifyContent='flex-end'>
        <Tooltip trigger="mouseenter" title={"Delete learnings"}>
          <TouchField paddingHorizontal={12} paddingVertical={8} onPress={() => onPressDelete(learnings[i])}>
            <FontAwesome5 name='trash' size={16} color={COLOR.RED} />
            {/* <Text color={COLOR.RED}>Delete</Text> */}
          </TouchField>
        </Tooltip>
        <Tooltip trigger="mouseenter" title={"Edit learnings"}>
          <TouchField paddingHorizontal={12} paddingVertical={8} onPress={() => onPressEdit(learnings[i])}>
            <FontAwesome5 name='pen' size={16} color={COLOR.MAIN} />
            {/* <Text color={COLOR.MAIN}>Edit</Text> */}
          </TouchField>
        </Tooltip>
        <TouchField padding={6} onPress={() => onSelectLearning(learnings[i].id)}>
          <FontAwesome5
            name={selectedLearning.includes(learnings[i].id) ? 'check-square' : 'square'}
            solid={selectedLearning.includes(learnings[i].id)}
            size={20}
            color={COLOR.MAIN}
          />
        </TouchField>
      </Row>
    }))
  }, [learnings, getLearnings, user, learningIds, selectedClient, selectedLearning, searchKey, filteredLearning])

  return (
    <CMSLayout requireAuthen>
      <Col flex1 m2 p2 round1 bgWhite shadow>
        <Row alignItems={'center'} justifyContent={'space-between'} mb2>
          <Row>
            <Text h4 nativeID={'heading'}>Manage learnings</Text>
            <Select
              options={allTypes}
              ml2
              innerProps={{
                placeholder: "Select type"
              }}
              placeholderColor={COLOR.GREY}
              value={selectedType}
              onChange={setSelectedType}
            />
            <Select
              options={allTags}
              ml2
              innerProps={{
                placeholder: "Select tag",
              }}
              placeholderColor={COLOR.GREY}
              value={selectedTag}
              onChange={setSelectedTag}
            />
            <Input
              height={40}
              inputProps={{
                style: {
                  // @ts-ignore
                  outline: 'none',
                  fontSize: 16,
                },
                placeholderTextColor: COLOR.GREY,
              }}
              width={250}
              borderRadius={8}
              borderWidth={1}
              borderColor={COLOR.BORDER_LIGHT}
              ml2
              value={searchKey}
              onChange={setSearchKey}
              placeholder='Search'
            />
          </Row>
          <Row>
            <Tooltip title='Upload images and get URLs' trigger='mouseenter'>
              <TouchField
                width={36} height={36}
                borderRadius={18} middle
                onPress={showUploadImage}
              >
                <FontAwesome5 name='images' size={24} color={COLOR.MAIN} />
              </TouchField>
            </Tooltip>
            {/* <Tooltip title='Download manage learning template' trigger='mouseenter'>
              <TouchField
                marginLeft={8}
                width={36} height={36}
                borderRadius={18} middle
                onPress={downloadTemplate}
              >
                <FontAwesome5 name='download' size={24} color={COLOR.MAIN} />
              </TouchField>
            </Tooltip> */}
            <Tooltip title='Import learnings from excel' trigger='mouseenter'>
              <ImportLearningButton />
            </Tooltip>
            <Tooltip title='Tags manager' trigger='mouseenter'>
              <TouchField
                marginLeft={8}
                width={36} height={36}
                borderRadius={18} middle
                onPress={showModalTagsManager}
                dataSet={{ element: 'tags-manager-btn' }}
              >
                <FontAwesome5 name='tags' solid size={20} color={COLOR.MAIN} />
              </TouchField>
            </Tooltip>
            <Tooltip title='Merge tags' trigger='mouseenter'>
              <TouchField
                marginLeft={8}
                width={36} height={36}
                borderRadius={18} middle
                onPress={showModalMergeTags}
                dataSet={{ element: 'merge-tags-btn' }}
              >
                <MaterialCommunityIcons name='table-merge-cells' solid size={26} color={COLOR.MAIN} />
              </TouchField>
            </Tooltip>
            <Tooltip title='Add learning' trigger='mouseenter'>
              <TouchField
                marginLeft={8}
                width={36} height={36}
                borderRadius={18} middle
                onPress={showModalCreateLearning}
                dataSet={{ element: 'add-learning' }}
              >
                <FontAwesome5 name='plus-circle' size={24} color={COLOR.MAIN} />
              </TouchField>
            </Tooltip>
          </Row>
        </Row>
        {selectedLearning?.length > 0 &&
          <Row mb1 backgroundColor={COLOR.BG_TABLE_ROW} borderRadius={8} padding={8} alignItems={'center'} justifyContent={'space-between'}>
            <Text semiBold color={COLOR.MAIN}>{selectedLearning.length} {selectedLearning.length > 1 ? 'items' : 'item'} selected</Text>
            <Tooltip style={{ alignSelf: "center" }} trigger="mouseenter" title={selectedLearning?.length > 1 ? "Delete learnings" : "Delete learning"}>
              <TouchField borderRadius={8} alignSelf={'center'} onPress={confirmDelete}>
                <Row middle paddingHorizontal={12} paddingVertical={8}>
                  <FontAwesome5 name='trash' size={16} color={COLOR.RED} />
                </Row>
              </TouchField>
            </Tooltip>
          </Row>
        }

        <Table
          columns={[
            { key: 'icon', title: 'Icon', width: 80, padding: 10 },
            { key: 'name', title: 'Name', flex: 2, sort: true },
            { key: 'url', title: 'URL', flex: 2 },
            { key: 'type', title: 'Type', flex: 1, sort: true },
            { key: 'tags', title: 'Tags', flex: 1, sort: true },
            {
              key: 'actions', title: '',
              renderTitle: (
                <TouchField
                  onPress={onSelectAll}
                  padding={6}
                  marginLeft={8}
                  alignItems="center"
                  flexDirection="row"
                >
                  <Text marginRight={8} fontWeight={'500'} fontSize={14}>{!selectedAll ? "Select" : "Deselect"} all</Text>
                  <FontAwesome5
                    name={selectedAll ? 'check-square' : 'square'}
                    size={20}
                    solid={selectedAll}
                    color={COLOR.MAIN}
                  />
                </TouchField>
              ),
              width: 150
            },
          ]}
          data={tableRows}
          style={{ margin: 20 }}
          minWidthRequired={500}
          isLoading={loading}
          currentSortBy={sortBy}
          onPressSort={setSortBy}
          rowProps={{
            paddingVertical: 16,
          }}
        />
      </Col>
    </CMSLayout>
  )
};

ListLearnings.routeInfo = {
  title: 'Manage Learnings',
  path: '/manage-learnings',
};

export default ListLearnings;
