import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Col, IColProps, Row, Text } from "components/base";
import { COLOR, SCREEN } from "const";
import { Input, ShimmerLoading, TouchField, modal } from "components/elements";
import Store from "store";
import shallow from "zustand/shallow";
import { TClient } from "type";
import { FontAwesome5, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";

export interface IDropdownMenuItem {
  label?: string;
  icon?: string;
  id?: string;
}

interface Props extends IColProps {
  children?: ReactElement;
  onPressRedirect?: (screen: string) => void;
  options?: { label: string; icon?: string }[];
}

const DROPDOWN_W = 300;

const Menu = ({ onPressClient, onPressRedirect }: any) => {
  // const { navigate } = useNavFunc();
  const [searchKey, setSearchKey] = useState("");
  const { clients, loading, selectClient, selectedClient } =
    Store.useClientStore(
      (state) => ({
        clients: state.clients,
        loading: state.loading,
        selectClient: state.selectClient,
        selectedClient: state.selectedClient,
      }),
      shallow
    );

  const onPressItem = (client: TClient) => {
    onPressClient(client);
    selectClient(client?.id);
  };

  const displayClients = useMemo(() => {
    let allClients = Object.keys(clients).map((i) => ({
      name: clients[i].name,
      id: clients[i].id,
    }));
    if (searchKey) {
      allClients = allClients.filter((i) =>
        i.name.toLowerCase().includes(searchKey.toLowerCase())
      );
    }
    return allClients;
  }, [clients, searchKey]);

  return (
    <>
      <Col
        width={DROPDOWN_W}
        bgWhite
        marginBottom={5}
        borderRadius={8}
        paddingHorizontal={16}
        paddingVertical={5}
      >
        <TouchField
          paddingVertical={12}
          paddingRight={4}
          nativeID="client-item"
          onPress={() => onPressRedirect(SCREEN.AccountSetting)}
        >
          <Row>
            <MaterialCommunityIcons
              name="account-settings"
              size={24}
              color="black"
            />
            <Text marginLeft={5}>Account setting</Text>
          </Row>
        </TouchField>
      </Col>
      <Col
        width={DROPDOWN_W}
        bgWhite
        borderRadius={8}
        paddingHorizontal={16}
        paddingVertical={12}
        nativeID="select-client-modal"
      >
        <Text>Login as Client Admin</Text>
        <Row
          width100p
          borderRadius={20}
          backgroundColor={COLOR.GREY_LIGHT}
          marginVertical={12}
        >
          <Input
            height={40}
            flex1
            value={searchKey}
            onChange={setSearchKey}
            borderColor="transparent"
            inputProps={{
              style: {
                // @ts-ignore
                outline: "none",
                fontSize: 14,
              },
              placeholderTextColor: COLOR.GREY,
            }}
            placeholder="Search"
          />
        </Row>
        {loading ? (
          <ShimmerLoading height={40} borderRadius={8} />
        ) : (
          displayClients?.map((opt, idx) => (
            <TouchField
              paddingVertical={12}
              paddingRight={4}
              nativeID="client-item"
              key={`client${idx}`}
              onPress={() => onPressItem(opt)}
            >
              <Row alignItems={"center"}>
                <Col flex1>
                  <Row>
                    <MaterialIcons
                      name="account-circle"
                      size={16}
                      color="black"
                    />
                    <Text marginLeft={5}>{opt.name}</Text>
                  </Row>
                </Col>
                {selectedClient?.id === opt.id && (
                  <FontAwesome5 name="check" size={16} color={COLOR.MAIN} />
                )}
              </Row>
            </TouchField>
          ))
        )}
      </Col>
    </>
  );
};

const AccountDropdownMenu = ({
  children,
  onPressRedirect,
  ...restProps
}: Props) => {
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const onLayout = useCallback((e: any) => {
    const { left, top, height, width } = e.nativeEvent.layout || {};
    setPosition({
      left: Math.max(0, left - DROPDOWN_W + width),
      top: Math.max(0, top + height + 10),
    });
  }, []);
  const { getClients } = Store.useClientStore(
    (state) => ({
      getClients: state.getClients,
    }),
    shallow
  );

  useEffect(() => {
    getClients();
  }, []);

  const onPress = useCallback(() => {
    modal.show(
      <Menu
        onPressClient={(opt) => {
          modal.hide();
        }}
        onPressRedirect={(screen) => {
          onPressRedirect && onPressRedirect(screen);
          modal.hide();
        }}
      />,
      {
        contentStyle: {
          position: "absolute",
          top: position.top,
          left: position.left,
        },
        bgContainerStyle: {
          backgroundColor: "rgba(0,0,0,0.1)",
        },
      }
    );
  }, [position]);

  return (
    <TouchField
      {...restProps}
      onPress={onPress}
      onLayout={onLayout}
      nativeID="select-client-btn"
    >
      {children}
    </TouchField>
  );
};

export default AccountDropdownMenu;
