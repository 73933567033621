import Request from '../Request.utils'
export interface ICreateRequestBody {
  data?: object;
  location?: string;
  logo?: string;
  name: string;
  region: string;
  slug: string;
}
export interface IUpdateRequestBody {
  data?: object;
  id?: string;
  location?: string;
  logo?: string;
  name?: string;
}
export interface IRemoveRequestBody {
  id: string;
}
export interface IListRequestQuery {
  all?: boolean;
  location?: string;
  name?: string;
}
export interface ICreateAccounntRequestBody {
  email: string;
  firstName: string;
  lastName?: string;
  password: string;
}
export interface IDetailRequestParams {
  id: string;
}
export interface ISiteInfoRequestParams {
  slug: string;
}
export interface ISyncDataRequestBody {
  data?: object;
  id?: string;
  location?: string;
  logo?: string;
  name?: string;
  region?: string;
  slug?: string;
}


export class ClientAPI {


  request = Request // for ts auto suggestion
  debugMode = false // for ts auto suggestion
  constructor(request, debugMode = false) {
    if(request) this.request = request;
    this.debugMode = debugMode;
  }
  create = async (body: ICreateRequestBody) => {
    if(this.debugMode) console.log('/api/clients/create', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/clients/create', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  update = async (body: IUpdateRequestBody) => {
    if(this.debugMode) console.log('/api/clients/update', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/clients/update', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  remove = async (body: IRemoveRequestBody) => {
    if(this.debugMode) console.log('/api/clients/remove', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/clients/remove', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  list = async (query: IListRequestQuery) => {
    if(this.debugMode) console.log('/api/clients', 'GET', undefined, query, undefined, );
    const res = await this.request.call('/api/clients', 'GET', undefined, query, undefined, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  createAccounnt = async (body: ICreateAccounntRequestBody) => {
    if(this.debugMode) console.log('/api/clients/create-account', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/clients/create-account', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  detail = async (params: IDetailRequestParams) => {
    if(this.debugMode) console.log('/api/clients/:id', 'GET', params, undefined, undefined, );
    const res = await this.request.call('/api/clients/:id', 'GET', params, undefined, undefined, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  siteInfo = async (params: ISiteInfoRequestParams) => {
    if(this.debugMode) console.log('/api/site-info/:slug', 'GET', params, undefined, undefined, );
    const res = await this.request.call('/api/site-info/:slug', 'GET', params, undefined, undefined, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  syncData = async (body: ISyncDataRequestBody) => {
    if(this.debugMode) console.log('/api/clients/sync', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/clients/sync', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
}
export default new ClientAPI(Request);