import { Col } from 'components';
import { IScreen } from 'type';
import React, { useEffect } from 'react';
import { SCREEN } from 'const';
import { useNavFunc } from 'navigation';
import Store from 'store';

const LoginByLink: IScreen = () => {
  const { reset, route } = useNavFunc();
  const { redirect, email, pass: password }: any = route.params || {};
  const UserStore = Store.useUserStore();

  useEffect(() => {
    handleLogin();
  }, [])

  const doRedirect = () => {
    if (redirect) {
      reset(redirect);
    } else {
      reset(SCREEN.Home);
    }
  }

  const handleLogin = async () => {
    try {
      if (!email || !password) return;
      const res = await Store.Client.Api.User.login({
        email,
        password,
      });


      if (res.data.success && res.data.data?.token) {
        const token = res.data.data?.token;
        Store.Client.setToken(token);
        UserStore.set({
          token,
          user: res.data.data?.publicInfo,
        });
        doRedirect();
      }
    } catch (err) {
    }
  };

  return <Col flex1 />
}

LoginByLink.routeInfo = {
  title: 'LoginByLink',
  path: '/login-link',
}

export default LoginByLink;
