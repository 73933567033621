import { useEffect } from 'react';
import Client from './api-client';
import { usePathfinderStore } from './Pathfinder.Store';
import { useUserStore } from './User.Store';
import { useElementStore } from './Element.Store';
import { useStatementStore } from './Statement.Store';
import { useLearningStore } from './Learning.Store';
import { useLearnerStore } from './Learner.Store';
import { useClientStore } from './Client.Store';
import { useImageStore } from './Image.Store';

// default to 'http://localhost:3344'
Client.setHost('http://localhost:3344');

if (window.location.host.includes('pathfinder-rebuild.devserver.london')) {
  Client.setHost('https://api.pathfinder-rebuild.devserver.london');
}

if (window.location.host.includes('harborpathfinder.com')) {
  Client.setHost('https://us.harborpathfinder.com');
}

export const Store = {
  Client,
  Api: Client.Api,
  useUserStore,
  usePathfinderStore,
  useElementStore,
  useStatementStore,
  useLearningStore,
  useLearnerStore,
  useClientStore,
  useImageStore,
}

export default Store;
