import RequestUtils from './Request.utils';
import Request, { RequestHelper } from './Request.utils';
import * as Api from './api';
import * as ApiClass from './api/index.class';

export class ApiClient {

  request=Request; // for ts auto suggestion
  Api=Api; // for ts auto suggestion

  constructor({
    debugMode = false,
    singletonRequest = true,
  }) {
    this.request = singletonRequest ? RequestUtils : new RequestHelper();
    // @ts-ignore
    this.Api = {};
    for (let key in ApiClass) {
      this.Api[key.replace('API', '')] = new ApiClass[key](this.request, debugMode);
    }
  }

  setHost(v: string) {
    this.request.setHost(v);
  }

  setToken(v: string) {
    this.request.setToken(v);
  }
  
  getToken = () => this.request.getToken();

  setLearnerToken(v: string) {
    this.request.setLearnerToken(v);
  }
}

export * from './type';

export default new ApiClient({
  debugMode: (() => {
    // @ts-ignore
    if (typeof window !== 'undefined') return window.apiDebugMode === true;
    if (typeof global !== 'undefined') return global.apiDebugMode === true;
    if (typeof process !== 'undefined') return !!process.env.API_DEBUG_MODE;
  })(),
  singletonRequest: true,
});
