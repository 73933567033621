import { Col, Text, CMSLayout, PathfinderLayout, PathfinderLoading, Row, TouchField, ImportButton, ExportButton, modalConfirm } from 'components';
import React, { useCallback, useEffect, useMemo } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { Feather, FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import { COLOR } from 'const';
import shallow from 'zustand/shallow';
import { Tooltip } from 'react-tippy';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AutoSaver from './AutoSaver';
import { useIsFocused } from '@react-navigation/native';

const ListPathfinder: IScreen = () => {
  const { navigate } = useNavFunc();

  const { pathfinderIds, loading, getPathfinders, addEmptyPathfinder, isSelectMode, pathfinderStoreSet, selectedPathfinder, reOrderPathfinders } = Store.usePathfinderStore((state) => ({
    pathfinderIds: state.pathfinderIds,
    loading: state.loading,
    getPathfinders: state.getPathfinders,
    addEmptyPathfinder: state.addEmptyPathfinder,
    isSelectMode: state.isSelectMode,
    pathfinderStoreSet: state.set,
    selectedPathfinder: state.selectedPathfinder,
    reOrderPathfinders: state.reOrderPathfinders,
  }), shallow);
  const { getLearnings } = Store.useLearningStore(state => ({
    getLearnings: state.getLearnings,
  }), shallow);
  const { selectedClient } = Store.useClientStore();
  const isFocused = useIsFocused();

  useEffect(() => {
    getPathfinders(selectedClient?.id);
    getLearnings(selectedClient?.id);
  }, [selectedClient]);

  const confirmDelete = () => {
    modalConfirm.show({
      title: 'Delete',
      message: `Do you want to delete ${selectedPathfinder.length} pathfinder${selectedPathfinder.length > 1 ? 's' : ''}?`,
      onConfirm: async () => {
        await Promise.all(selectedPathfinder.map(id =>
          Store.Api.Pathfinder.remove({ id })
        ))
        pathfinderStoreSet({ selectedPathfinder: [] });
        await getPathfinders(selectedClient?.id);
      }
    })
  }

  const downloadTemplate = useCallback(() => {
    window.open(`https://pathfinder-cd.fra1.digitaloceanspaces.com/PathfinderCreationTemplate-052024.xlsx`)
  }, [])

  const handleDrop = useCallback((droppedItem) => {
    if (!droppedItem.destination) return;
    let updatedList = [...pathfinderIds];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    reOrderPathfinders(updatedList);
  }, [pathfinderIds, reOrderPathfinders]);

  const list = useMemo(() => {
    if (!isFocused) return null;
    return (
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="list-statement-container">
          {(provided) => (
            <div
              className="list-statement-container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {pathfinderIds?.map((i, idx) => (
                <Draggable key={i} draggableId={i} index={idx}>
                  {(provided) => (
                    <div
                      className="statement-item-container"
                      ref={provided.innerRef}
                      {...provided.draggableProps}>
                      <Row mb2>
                        <div {...provided.dragHandleProps}>
                          <Feather
                            name="move"
                            size={18}
                            color={COLOR.SUB_TEXT}
                          />
                        </div>
                        <PathfinderLayout
                          key={i || 'pathfinder-' + idx}
                          flex1
                          pathfinderId={i}
                          onRefresh={() => getPathfinders(selectedClient?.id)}
                        />
                      </Row>
                    </div>
                  )}
                </Draggable>
              )
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }, [pathfinderIds, selectedClient, handleDrop, isFocused]);

  return (
    <CMSLayout requireAuthen>
      <Col flex1 m2 p2 round1 bgWhite shadow>
        <Row alignItems={'flex-start'} justifyContent={'space-between'}>
          <Text h4 mb2 nativeID={'heading'}>Pathfinders</Text>
          <Row>
            <AutoSaver />
            <Tooltip title='Download Pathfinder Creation Template' trigger='mouseenter'>
              <TouchField
                marginHorizontal={8}
                width={36} height={36}
                borderRadius={18} middle
                onPress={downloadTemplate}
              >
                <FontAwesome5 name={'download'} size={24} color={COLOR.MAIN} />
              </TouchField>
            </Tooltip>
            {/* <Tooltip title='Export pathfinders to excel' trigger='mouseenter'>
              <ExportButton />
            </Tooltip>
            <Tooltip title='Import pathfinders from excel' trigger='mouseenter'>
              <ImportButton />
            </Tooltip> */}
            <Tooltip title='Select pathfinders to delete' trigger='mouseenter'>
              <TouchField
                marginLeft={8}
                width={36} height={36}
                borderRadius={18} middle
                onPress={() => pathfinderStoreSet(state => ({
                  isSelectMode: !state.isSelectMode,
                }))}
                dataSet={{ element: 'toggle-select-pathfinder' }}
              >
                <MaterialCommunityIcons name={isSelectMode ? 'selection-ellipse-remove' : 'playlist-remove'} size={30} color={COLOR.MAIN} />
              </TouchField>
            </Tooltip>
            <Tooltip title='Add pathfinder' trigger='mouseenter'>
              <TouchField
                marginLeft={8}
                width={36} height={36}
                borderRadius={18} middle
                onPress={addEmptyPathfinder}
                dataSet={{ element: 'add-pathfinder' }}
              >
                <FontAwesome5 name='plus-circle' size={24} color={COLOR.MAIN} />
              </TouchField>
            </Tooltip>
          </Row>
        </Row>
        {selectedPathfinder?.length > 0 &&
          <Row mb1 backgroundColor={COLOR.BG_TABLE_ROW} borderRadius={8} padding={8} alignItems={'center'} justifyContent={'space-between'}>
            <Text semiBold color={COLOR.MAIN}>{selectedPathfinder.length} {selectedPathfinder.length > 1 ? 'items' : 'item'} selected</Text>
            <Tooltip style={{ alignSelf: "center" }} trigger="mouseenter" title={selectedPathfinder.length > 1 ? "Delete pathfinders" : "Delete pathfinder"}>
              <TouchField borderRadius={8} alignSelf={'center'} onPress={confirmDelete}>
                <Row middle paddingHorizontal={12} paddingVertical={8}>
                  {/* <Text color={COLOR.RED}>Delete </Text> */}
                  <FontAwesome5 name='trash' size={16} color={COLOR.RED} />
                </Row>
              </TouchField>
            </Tooltip>
          </Row>
        }
        {loading ? <PathfinderLoading /> : list}
      </Col>
    </CMSLayout>
  )
};

ListPathfinder.routeInfo = {
  title: 'Pathfinders',
  path: '/pathfinders',
};

export default ListPathfinder;
