import create from 'zustand';
import Client, { TLearner } from './api-client';
import _ from 'lodash';
import Store from 'store';

interface ILearnerStore {
  learner: TLearner,
  token: string,
  asked: boolean,
  loading?: boolean,
  error?: Error,
  learners?: { [learnerId: string]: TLearner},
  learnerIds?: string[],
  set: (data: any) => void,
  editLearner: (key: string, value: any) => void,
  getLearners: () => void,
  saveLearner: (id: string) => void,
  restoreLearner: () => void,
}

const LEARNER_STORE_KEY = 'LEARNER_INFO';

export const useLearnerStore = create<ILearnerStore>((set, get) => ({
  learner: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
  },
  learners: {},
  learnerIds: [],
  loading: false,
  token: '',
  asked: false,
  set,
  restoreLearner: () => {
    try {
      const learnerStr = localStorage.getItem(LEARNER_STORE_KEY);
      if (!learnerStr) return;
      const learner = JSON.parse(learnerStr);
      set({ learner });
    } catch (error) { }
  },
  getLearners: async () => {
    try {
      if (get().loading) return;
      set({ loading: true });
      const res = await Store.Client.Api.Learner.list({});
      if (res.data.data && res.data.success) {
        const obj = {}
        const ids = []
        res.data.data.forEach(i => {
          ids.push(i.id);
          obj[i.id] = i;
        });
        set({
          learners: _.cloneDeep(obj),
          learnerIds: ids,
        });
      }
    } catch (error) {
    } finally {
      set({ loading: false });
    }
  },
  editLearner: (key, value) => {
    if (!key) return;
    set(state => {
      const learner = _.cloneDeep(state.learner);
      learner[key] = value;
      return {
        learner,
      }
    });
  },
  saveLearner: async (pathfinderId) => {
    try {
      if (get().loading) return;
      set({ loading: true, error: undefined });
      const learner = get().learner;
      const res = await Client.Api.Learner.contact({
        email: learner.email,
        firstName: learner.firstName,
        lastName: learner.lastName,
        pathfinderId,
      })
      if (res.data?.success && res.data?.data) {
        const { publicInfo, token } = res.data?.data || {};
        set({
          learner: publicInfo,
          asked: true,
        });
        localStorage.setItem(LEARNER_STORE_KEY, JSON.stringify(publicInfo))
        Store.Client.setLearnerToken(token);
      }
    } catch (error) {
      set({ error });
    } finally {
      set({ loading: false });
    }
  }
}));
