import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDFPcKfd0z7quhoHk4-FWtkzvAVl7WbaOE",
  authDomain: "pathfinder-b5405.firebaseapp.com",
  projectId: "pathfinder-b5405",
  storageBucket: "pathfinder-b5405.appspot.com",
  messagingSenderId: "837265733499",
  appId: "1:837265733499:web:1d0911da27afb7d58d6fde",
  measurementId: "G-F0BKWE8ZX6",
};
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);