import React, { useRef } from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { TouchField, modal } from "components/elements";
import { COLOR } from "const";
import { read, utils } from "xlsx";
import ConfirmImportModal from "./ConfirmImportModal";

const learningColIdx = {
  name: 0,
  url: 1,
  type: 2,
  icon: 3,
  comment: 4,
  tags: 5,
}

const ImportButton = () => {
  const inputRef = useRef(null);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = read(bstr, { type: 'binary' });
      let i = 0;
      // handle learnings
      const learnings = [];
      const ws = wb.Sheets[wb.SheetNames[1]];
      if (ws) {
        const range2 = utils.decode_range(ws['!ref']);
        const grid2 = [];
        for (let rowNum = range2.s.r; rowNum <= range2.e.r; rowNum++) {
          i += 1;
          const row = [];
          // Parse each row of the column
          for (let colNum = range2.s.c; colNum <= range2.e.c; colNum++) {
            const cellAddress = utils.encode_cell({ r: rowNum, c: colNum });
            const cellData = ws[cellAddress];
            let cellValue = cellData?.v || cellData?.f;
            if (row.length === learningColIdx.url && cellData?.l?.Target) {
              cellValue = {
                url: cellData?.l?.Target,
                urlShortName: cellData?.v || cellData?.f,
              };
            }
            row.push(cellValue);
          }
          grid2.push(row);
        }
        for (let j = 1; j < grid2.length; j++) {
          if (grid2[j][learningColIdx.name]) {
            learnings.push({
              name: grid2[j][learningColIdx.name],
              url: typeof grid2[j][learningColIdx.url] === "string" ? grid2[j][learningColIdx.url] : grid2[j][learningColIdx.url]?.url,
              urlShortName: typeof grid2[j][learningColIdx.url] === "object" ? grid2[j][learningColIdx.url]?.urlShortName : undefined,
              icon: grid2[j][learningColIdx.icon],
              type: grid2[j][learningColIdx.type],
              comment: grid2[j][learningColIdx.comment],
              tags: grid2[j][learningColIdx.tags] ? String(grid2[j][learningColIdx.tags]) : undefined,
            })
          }
        }
      }

      modal.show(
        <ConfirmImportModal learnings={learnings} />
      );
    };
    reader.readAsBinaryString(file);
  };

  return (
    <TouchField
      width={36} height={36} marginLeft={8}
      borderRadius={18} middle
      onPress={() => inputRef.current?.click()}
      dataSet={{ element: 'import-pathfinder' }}
    >
      <MaterialCommunityIcons name='database-import' size={28} color={COLOR.MAIN} />
      <input
        ref={inputRef} type="file" accept=".xlsx"
        onChange={handleFileUpload} style={{ display: 'none' }}
      />
    </TouchField>
  )
}

export default ImportButton;
