import Request from '../Request.utils'
export interface ICreateRequestBody {
  additionalInstruction?: string;
  additionalRecipients?: string;
  clientId?: string;
  completionInstruction?: string;
  data?: object;
  disableElementsFilter?: boolean;
  elementsTitle?: string;
  emailContent?: string;
  emailFooter?: string;
  emailSubject?: string;
  id?: string;
  likertScaleTitle1?: string;
  likertScaleTitle2?: string;
  likertScaleTitle3?: string;
  listLearningTitle?: string;
  listStatementSubtitle?: string;
  listStatementTitle?: string;
  logo?: string;
  name?: string;
  orderIndex?: number;
  sendEmail?: boolean;
  welcomeInstruction?: string;
}
export interface IUpdateRequestBody {
  additionalInstruction?: string;
  additionalRecipients?: string;
  completionInstruction?: string;
  data?: object;
  disableElementsFilter?: boolean;
  elementsTitle?: string;
  emailContent?: string;
  emailFooter?: string;
  emailSubject?: string;
  id: string;
  likertScaleTitle1?: string;
  likertScaleTitle2?: string;
  likertScaleTitle3?: string;
  listLearningTitle?: string;
  listStatementSubtitle?: string;
  listStatementTitle?: string;
  logo?: string;
  name?: string;
  orderIndex?: number;
  sendEmail?: boolean;
  welcomeInstruction?: string;
}
export interface IListRequestQuery {
  all?: boolean;
  clientId?: string;
  name?: string;
}
export interface IDetailRequestParams {
  id: string;
}
export interface IImportRequestBody {
  clientId?: string;
  learnings?: {
    /**
     * Unknown Property
     */
    [x: string]: unknown;
    comment?: string;
    icon?: string;
    name?: string;
    tags?: string;
    type?: string;
    url?: string;
    urlShortName?: string;
  }[];
  pathfinders: {
    /**
     * Unknown Property
     */
    [x: string]: unknown;
    additionalInstruction?: string;
    completionInstruction?: string;
    elements?: {
      filters?: string;
      image?: string;
      name?: string;
      rolloverText?: string;
    }[];
    elementsTitle?: string;
    likertScale?: string;
    logo?: string;
    name?: string;
    statements?: {
      element?: string;
      learning1?: string;
      learning2?: string;
      learning3?: string;
      rollover1?: string;
      rollover2?: string;
      rollover3?: string;
      statement?: string;
    }[];
    welcomeInstruction?: string;
  }[];
  updateExisting?: boolean;
}
export interface IExportRequestBody {
  clientId?: string;
  pathfinderIds?: string[];
}
export interface IRemoveRequestBody {
  id: string;
}
export interface IDemoEmailRequestBody {
  email: string;
  pathfinderId: string;
}
export interface IDuplicateRequestBody {
  clientId?: string;
  pathfinderId: string;
}
export interface IDownloadScormRequestBody {
  id: string;
  type: string;
}


export class PathfinderAPI {


  request = Request // for ts auto suggestion
  debugMode = false // for ts auto suggestion
  constructor(request, debugMode = false) {
    if(request) this.request = request;
    this.debugMode = debugMode;
  }
  create = async (body: ICreateRequestBody) => {
    if(this.debugMode) console.log('/api/pathfinders/create', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/pathfinders/create', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  update = async (body: IUpdateRequestBody) => {
    if(this.debugMode) console.log('/api/pathfinders/update', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/pathfinders/update', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  list = async (query: IListRequestQuery) => {
    if(this.debugMode) console.log('/api/pathfinders', 'GET', undefined, query, undefined, );
    const res = await this.request.call('/api/pathfinders', 'GET', undefined, query, undefined, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  detail = async (params: IDetailRequestParams) => {
    if(this.debugMode) console.log('/api/pathfinders/:id', 'GET', params, undefined, undefined, );
    const res = await this.request.call('/api/pathfinders/:id', 'GET', params, undefined, undefined, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  import = async (body: IImportRequestBody) => {
    if(this.debugMode) console.log('/api/pathfinders/import', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/pathfinders/import', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  export = async (body: IExportRequestBody) => {
    if(this.debugMode) console.log('/api/pathfinders/export', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/pathfinders/export', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  remove = async (body: IRemoveRequestBody) => {
    if(this.debugMode) console.log('/api/pathfinders/remove', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/pathfinders/remove', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  demoEmail = async (body: IDemoEmailRequestBody) => {
    if(this.debugMode) console.log('/api/pathfinders/demo-email', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/pathfinders/demo-email', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  duplicate = async (body: IDuplicateRequestBody) => {
    if(this.debugMode) console.log('/api/pathfinders/duplicate', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/pathfinders/duplicate', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  downloadScorm = async (body: IDownloadScormRequestBody) => {
    if(this.debugMode) console.log('/api/pathfinders/scorm', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/pathfinders/scorm', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
}
export default new PathfinderAPI(Request);