import React, { useImperativeHandle, forwardRef, useRef, useState } from 'react';

import Store from 'store';

interface IProps {
  isMulti?: boolean,
  onUploaded(urls: Array<string>): void,
  onUploading?: () => void,
  accept?: string,
  saveImage?: boolean,
  shortenUrl?: boolean,
}

const UploadFile = forwardRef((props: IProps, ref) => {
  const [uploadedPath, setUploadedPath] = useState('');
  const inputRef = useRef(null);

  const clearFiles = () => {
    if (!inputRef.current) return;
    inputRef.current.value = '';
    inputRef.current.type = '';
    inputRef.current.type = 'file';
  }

  const onChange: React.InputHTMLAttributes<HTMLInputElement>['onChange'] = async e => {
    const files = Array.from(e.target.files);

    if (files.length === 0) return props.onUploaded([]);
    props.onUploading?.();
    const toBeProcessedFiles = props.isMulti ? files : [files[0]];

    const promiseArr = toBeProcessedFiles.map(async (val: File) => {
      const uploadName = new Date().getTime() + '-' + val.name;
      const serverSidePath = props.shortenUrl ? undefined : (uploadedPath || `files/${uploadName}`);
      try {
        const res1 = await Store.Api.User.getUploadPresignedUrl({
          serverSidePath,
          contentType: val.type,
        });
        const awsUrl = res1.data.data
        if (!awsUrl) return undefined;
        const res2 = await fetch(awsUrl, {
          method: 'PUT',
          headers: {
            'x-amz-acl': 'public-read',
            'Content-Type': val.type,
          },
          body: val,
        });
        if (res2.status !== 200) return undefined;
        const u = new URL(awsUrl);
        if (props.saveImage) {
          await Store.Api.Image.create({
            name: val.name,
            url: `${u.origin}${u.pathname}`,
          });
        }
        return `${u.origin}${u.pathname}`;
      } catch (err) {
        return undefined;
      }
    });

    const urls = await Promise.all(promiseArr);
    props.onUploaded(urls.filter(val => !!val));
    clearFiles();
  }

  useImperativeHandle(ref, () => ({

    showDialog: (uploadedUrl: string) => {
      if (uploadedUrl) {
        const url = new URL(uploadedUrl);
        setUploadedPath(url.pathname.substring(1));
      }
      inputRef.current?.click();
    },

  }));

  return (
    <input accept={props.accept} multiple ref={inputRef} type="file" style={{ display: 'none' }} onChange={onChange} />
  );
});

export default UploadFile;
