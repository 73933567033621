import Request from '../Request.utils'
export interface IUpsertRequestBody {
  data?: any;
  field1?: string;
  field2?: string;
  id?: string;
  name?: string;
  publicPermission?: string;
}
export interface IUpsertRequestParams {
  type: string;
}
export interface IListRequestParams {
  type: string;
}
export interface IListRequestQuery {
  field1?: string;
  field2?: string;
  name?: string;
  userId?: string;
}
export interface IDetailRequestParams {
  id: string;
  type: string;
}


export class DataAPI {


  request = Request // for ts auto suggestion
  debugMode = false // for ts auto suggestion
  constructor(request, debugMode = false) {
    if(request) this.request = request;
    this.debugMode = debugMode;
  }
  upsert = async (params: IUpsertRequestParams, body: IUpsertRequestBody) => {
    if(this.debugMode) console.log('/api/general-data/:type', 'POST', params, undefined, body, );
    const res = await this.request.call('/api/general-data/:type', 'POST', params, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  list = async (params: IListRequestParams, query: IListRequestQuery) => {
    if(this.debugMode) console.log('/api/general-data/:type', 'GET', params, query, undefined, );
    const res = await this.request.call('/api/general-data/:type', 'GET', params, query, undefined, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  detail = async (params: IDetailRequestParams) => {
    if(this.debugMode) console.log('/api/general-data/:type/:id', 'GET', params, undefined, undefined, );
    const res = await this.request.call('/api/general-data/:type/:id', 'GET', params, undefined, undefined, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
}
export default new DataAPI(Request);