import { FontAwesome5 } from "@expo/vector-icons";
import { Col, Row, Text, TouchField, modal, Input } from "components";
import { COLOR } from "const";
import React, { useCallback } from "react";
import { useWindowDimensions } from "react-native";

interface Props {
  title?: string
  url?: string
}

const StandalonePublishModal = ({ url }: Props) => {
  const { width } = useWindowDimensions()

  const onPressConfirm = useCallback(async () => {
    navigator.clipboard.writeText(url);
  }, [url])

  return (
    <Col padding={24} paddingBottom={12} paddingRight={16} borderRadius={8} minWidth={Math.min(500, width - 64)} bgWhite>
      <Row alignItems='center' justifyContent='space-between' marginBottom={16}>
        <Text fontSize={20} semiBold color={COLOR.MAIN}>Standalone Publish</Text>
        <TouchField padding={4} onPress={() => modal.hide()}>
          <FontAwesome5 name='times' size={18} color={COLOR.MAIN} />
        </TouchField>
      </Row>
      <Col>
        <Text caption mb1 bold>Pathfinder url</Text>
        <Input value={url} disabled />
      </Col>
      <Row justifyContent={'flex-end'} marginTop={24}>
        <TouchField
          height={40}
          width={80}
          middle
          borderColor={COLOR.MAIN}
          borderRadius={20}
          borderWidth={1}
          onPress={() => modal.hide()}
          m0 ph2
        >
          <Text color={COLOR.MAIN}>Cancel</Text>
        </TouchField>
        <TouchField
          height={40}
          middle
          borderColor={COLOR.MAIN}
          borderRadius={20}
          borderWidth={1}
          backgroundColor={COLOR.MAIN}
          m0 ph2
          onPress={onPressConfirm}
        >
          <Text color={COLOR.WHITE}>Copy</Text>
        </TouchField>
      </Row>
    </Col>
  )
}

export default StandalonePublishModal
