import create from 'zustand';
import { TLearning } from 'type';
import Store from 'store';
import _ from 'lodash';
import { VarHelper } from 'helpers';

type LearningItem = TLearning & {
  isNew?: boolean
}

type OptLearning = {
  value: string
  label: string
  data: TLearning
}

interface ILearningStore {
  learningIds: string[]
  optsLearning: OptLearning[]
  learnings: {
    [learningId: string]: LearningItem
  },
  loading: boolean,
  errors: { id: string, error: string }[],
  set: (data: any) => void,
  setLoading: (value: boolean) => void,
  getLearnings: (clientId?: string, query?: any) => void,
  updateLearning: (learningId: string, data?: any) => void,
  removeTag: (tag: string) => void,
}

export const useLearningStore = create<ILearningStore>((set, get) => ({
  learningIds: [],
  optsLearning: [],
  learnings: {},
  editedLearningIds: [],
  loading: false,
  errors: [],
  set,
  setLoading: (value) => {
    set({ loading: value })
  },
  getLearnings: async (clientId, query) => {
    try {
      if (get().loading) return;
      get().setLoading(true);
      const res = await Store.Client.Api.Learning.list(VarHelper.removeUndefinedField({ clientId, ...query }));
      if (res.data.data && res.data.success) {
        const obj = {}
        const ids = []
        const opts = []
        res.data.data.forEach((i: TLearning) => {
          ids.push(i.id);
          obj[i.id] = i;
          opts.push({
            label: i.name,
            value: i.id,
            data: i,
          })
        });

        set((state) => ({
          learnings: Object.assign(state.learnings, obj),
          learningIds: ids,
          optsLearning: opts,
        }));
      }
    } catch (error) {
    } finally {
      get().setLoading(false);
    }
  },
  updateLearning: (learningId, data) => {
    const learnings = get().learnings;
    learnings[learningId] = {
      ...learnings[learningId],
      ...data,
    };
    set({
      learnings: { ...learnings },
    })
  },
  removeTag: async (tag: string) => {
    console.log("xxxx", tag, tag.length)
    if (!tag) return;
    const learnings = get().learnings;
    const newDatas = await Promise.all(Object.keys(learnings).map(async key => {
      const learning = learnings[key];
      let _tags = learning?.tags?.split(',') || [];
      if (_tags.includes(tag)) {
        let newTag = _tags.filter(i => i !== tag).join(',');
        return Store.Api.Learning.update({
          id: learning.id,
          tags: newTag,
        });
      }
      return;
    }));
    for (let i = 0; i < newDatas.length; i++) {
      if (!newDatas[i]?.data?.data?.id) continue;
      const data = newDatas[i]?.data?.data;
      get().updateLearning(data.id, data);
    }
  }
}));
