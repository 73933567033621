import { FontAwesome5 } from "@expo/vector-icons";
import { Col, Row, Text, TouchField, modal } from "components";
import { COLOR, SCREEN } from "const";
import React from "react";
import { useWindowDimensions } from "react-native";

interface Props {
  redirect: (screen?: string) => void;
}

const MultiFactorNotiModal = ({ redirect }: Props) => {
  const { width } = useWindowDimensions();
  return (
    <Col
      padding={24}
      paddingBottom={12}
      paddingRight={16}
      borderRadius={8}
      minWidth={Math.min(500, width - 64)}
      bgWhite
    >
      <Row justifyContent="flex-end" marginBottom={24}>
        <TouchField
          padding={4}
          onPress={() => {
            modal.hide();
            redirect();
          }}
        >
          <FontAwesome5 name="times" size={18} color={COLOR.MAIN} />
        </TouchField>
      </Row>
      <Col
        alignItems="center"
        textAlign={"center"}
        justifyContent="column"
        marginBottom={24}
      >
        <Text fontSize={20} semiBold color={COLOR.MAIN}>
          {"Required two-factor authentication"}
        </Text>
        <Text fontSize={16} mt3 semiBold color={COLOR.FONT}>
          {"Upgrade your security by requiring two-factor authentication"}
        </Text>
      </Col>

      <Col justifyContent={"column"} alignItems={"center"} marginTop={24}>
        <TouchField
          height={40}
          width={240}
          middle
          borderColor={COLOR.MAIN}
          borderRadius={20}
          borderWidth={1}
          backgroundColor={COLOR.MAIN}
          m0
          ph2
          onPress={() => {
            redirect(SCREEN.AccountSetting);
            modal.hide();
          }}
        >
          <Text color={COLOR.WHITE}>Setup</Text>
        </TouchField>
        <TouchField
          height={40}
          width={240}
          middle
          borderColor={COLOR.MAIN}
          borderRadius={20}
          borderWidth={1}
          onPress={() => {
            redirect();
            modal.hide();
          }}
          m0
          ph2
        >
          <Text color={COLOR.MAIN}>Continue using site</Text>
        </TouchField>
      </Col>
    </Col>
  );
};

export default MultiFactorNotiModal;
