import { FontAwesome5 } from "@expo/vector-icons";
import { Col, Input, Row, Text, TouchField, modal } from "components";
import { SelectTagInput } from "components/elements/form/Select";
import { COLOR } from "const";
import { cloneDeep, isArray } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { ActivityIndicator, useWindowDimensions } from "react-native";
import Store from "store";

interface Props {
  title?: string
  refresh?: () => void
}

interface MergeData {
  tags?: string
  newTag?: string
}

const MergeTagsModal = ({ title, refresh }: Props) => {
  const [fieldsData, setFieldsData] = useState<MergeData>({})
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { width } = useWindowDimensions()
  const { learnings } = Store.useLearningStore();

  const allTags = useMemo(() => {
    const result = [];
    if (!isArray(result)) return result;
    Object.keys(learnings).map(key => {
      const learning = learnings[key];
      learning?.tags?.split(',').forEach(key => {
        if (!result.includes(key.trim())) result.push(key.trim())
      })
    })
    return result.map(i => ({ label: i, value: i }));
  }, [learnings])

  const editField = useCallback((key, value) => {
    setFieldsData(state => {
      const newState = cloneDeep(state);
      newState[key] = value;
      return newState;
    });
  }, [])

  const onPressConfirm = useCallback(async () => {
    try {
      setLoading(true);
      const res = await Store.Api.Learning.mergeTags({
        newTag: fieldsData.newTag,
        tags: fieldsData.tags,
      })
      if (!res?.data?.success) {
        setError(res.data.error);
        return;
      }
      setError('');
      refresh?.();
      modal.hide();
    } catch (error) {
      setError(error?.message || JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  }, [fieldsData])

  const canSubmit = fieldsData.newTag && fieldsData.tags;

  return (
    <Col padding={24} paddingBottom={12} paddingRight={16} borderRadius={8} minWidth={Math.min(500, width - 64)} bgWhite>
      <Row alignItems='center' justifyContent='space-between' marginBottom={24}>
        <Text fontSize={20} semiBold color={COLOR.MAIN}>{title}</Text>
        <TouchField padding={4} onPress={() => modal.hide()}>
          <FontAwesome5 name='times' size={18} color={COLOR.MAIN} />
        </TouchField>
      </Row>
      <Col mt2>
        <Text caption mb1 bold>Tags to merge</Text>
        <SelectTagInput
          options={allTags}
          value={fieldsData.tags}
          onChange={(opts) => editField('tags', opts.map(i => i.value).join(','))}
          isMulti
          bonusStyle={{
            control: (style) => ({
              ...style,
              // height: 40,
              minHeight: 40,
              borderRadius: 8,
              backgroundColor: 'transparent',
              borderWidth: 1,
              borderColor: COLOR.BORDER_LIGHT,
              boxShadow: 'none',
              borderStyle: 'solid',
              '&:hover': {
                borderColor: COLOR.BORDER_LIGHT,
              },
            }),
          }}
          placeholder='Select tags to merge'
          noOptionsMessage=' '
          dataSet={{ element: 'select-tags' }}
          noPlaceholderBtn
        />
      </Col>
      <Col mt2>
        <Text caption mb1 bold>New tag</Text>
        <Input
          height={40}
          value={fieldsData.newTag}
          onChange={(v) => editField('newTag', v)}
          inputProps={{
            style: {
              // @ts-ignore
              outline: 'none',
              fontSize: 16,
            },
          }}
          borderRadius={8}
          borderWidth={1}
          borderColor={COLOR.BORDER_LIGHT}
        />
      </Col>

      {!!error &&
        <Text color={COLOR.RED} marginTop={32}>{error}</Text>
      }
      <Row justifyContent={'flex-end'} marginTop={24}>
        <TouchField
          height={40}
          width={80}
          middle
          borderColor={COLOR.MAIN}
          borderRadius={20}
          borderWidth={1}
          onPress={() => modal.hide()}
          m0 ph2
        >
          <Text color={COLOR.MAIN}>Cancel</Text>
        </TouchField>
        <TouchField
          height={40}
          middle
          borderColor={COLOR.MAIN}
          borderRadius={20}
          borderWidth={1}
          backgroundColor={COLOR.MAIN}
          m0 ph2
          opacity={canSubmit ? 1 : 0.2}
          disabled={!canSubmit}
          onPress={onPressConfirm}
        >
          <Row>
            {loading && <ActivityIndicator size='small' color={'white'} style={{ marginRight: 5 }} />}
            <Text color={COLOR.WHITE}>Submit</Text>
          </Row>
        </TouchField>
      </Row>
    </Col>
  )
}

export default MergeTagsModal
