import Request from '../Request.utils'
export interface ILoginRequestBody {
  email: string;
  password: string;
}
export interface IVerifyTokenRequestBody {
  idToken: string;
  isLogin?: boolean;
  userId: string;
}
export interface IEnable2FARequestBody {
  userId: string;
}
export interface IVerify2FARequestBody {
  isLogin?: boolean;
  token: string;
  userId: string;
}
export interface IRegisterRequestBody {
  __key?: string;
  addressLine1?: string;
  addressLine2?: string;
  country?: string;
  email: string;
  firstName: string;
  lastName?: string;
  otherData?: any;
  password: string;
  photoUrl?: string;
  postCode?: string;
  role: string;
  town?: string;
}
export interface IGetUploadPresignedUrlRequestBody {
  contentType: string;
  serverSidePath?: string;
}
export interface IDetailRequestParams {
  id: string;
}
export interface IUpdateRequestBody {
  email?: string;
  firstName?: string;
  id: string;
  lastName?: string;
  password?: string;
}
export interface IChangePasswordRequestBody {
  newPassword: string;
  oldPassword: string;
}
export interface ICreateAccountRequestBody {
  clientId?: string | null;
  email: string;
  firstName: string;
  lastName?: string;
  password: string;
  role: string;
}
export interface IChangeAccountPasswordRequestBody {
  id: string;
  password: string;
}
export interface IListRequestQuery {
  allClient?: boolean;
  clientId: string;
}
export interface IRemoveRequestBody {
  id: string;
}
export interface IUploadImageFromUrlRequestBody {
  url: string;
}
export interface IForgotPasswordRequestBody {
  email: string;
}
export interface IVerifyForgotPasswordRequestBody {
  code: string;
}
export interface IResetPasswordRequestBody {
  code: string;
  newPassword: string;
}
export interface ISyncDataRequestBody {
  clientId?: string;
  email?: string;
  firstName?: string;
  id?: string;
  lastName?: string;
  password?: string;
  role?: string;
}


export class UserAPI {


  request = Request // for ts auto suggestion
  debugMode = false // for ts auto suggestion
  constructor(request, debugMode = false) {
    if(request) this.request = request;
    this.debugMode = debugMode;
  }
  login = async (body: ILoginRequestBody) => {
    if(this.debugMode) console.log('/api/users/login', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/users/login', 'POST', undefined, undefined, body, );
    if (res.data.data && res.data.data.token) this.request.setToken(res.data.data.token)
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  verifyToken = async (body: IVerifyTokenRequestBody) => {
    if(this.debugMode) console.log('/api/users/verify-token', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/users/verify-token', 'POST', undefined, undefined, body, );
    if (res.data.data && res.data.data.token) this.request.setToken(res.data.data.token)
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  enable2FA = async (body: IEnable2FARequestBody) => {
    if(this.debugMode) console.log('/api/users/enable2FA', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/users/enable2FA', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  verify2FA = async (body: IVerify2FARequestBody) => {
    if(this.debugMode) console.log('/api/users/verify2FA', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/users/verify2FA', 'POST', undefined, undefined, body, );
    if (res.data.data && res.data.data.token) this.request.setToken(res.data.data.token)
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  logout = async () => {
    if(this.debugMode) console.log('/api/users/logout', 'POST', undefined, undefined, undefined, );
    const res = await this.request.call('/api/users/logout', 'POST', undefined, undefined, undefined, );
    this.request.setToken('')
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  register = async (body: IRegisterRequestBody) => {
    if(this.debugMode) console.log('/api/users/register', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/users/register', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  me = async () => {
    if(this.debugMode) console.log('/api/users/me', 'GET', undefined, undefined, undefined, );
    const res = await this.request.call('/api/users/me', 'GET', undefined, undefined, undefined, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  getUploadPresignedUrl = async (body: IGetUploadPresignedUrlRequestBody) => {
    if(this.debugMode) console.log('/api/users/me/get-upload-url', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/users/me/get-upload-url', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  detail = async (params: IDetailRequestParams) => {
    if(this.debugMode) console.log('/api/users/:id', 'GET', params, undefined, undefined, );
    const res = await this.request.call('/api/users/:id', 'GET', params, undefined, undefined, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  update = async (body: IUpdateRequestBody) => {
    if(this.debugMode) console.log('/api/users/update', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/users/update', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  changePassword = async (body: IChangePasswordRequestBody) => {
    if(this.debugMode) console.log('/api/users/change-password', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/users/change-password', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  createAccount = async (body: ICreateAccountRequestBody) => {
    if(this.debugMode) console.log('/api/users/create-account', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/users/create-account', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  changeAccountPassword = async (body: IChangeAccountPasswordRequestBody) => {
    if(this.debugMode) console.log('/api/users/change-account-password', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/users/change-account-password', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  list = async (query: IListRequestQuery) => {
    if(this.debugMode) console.log('/api/users', 'GET', undefined, query, undefined, );
    const res = await this.request.call('/api/users', 'GET', undefined, query, undefined, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  remove = async (body: IRemoveRequestBody) => {
    if(this.debugMode) console.log('/api/users/remove', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/users/remove', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  uploadImageFromUrl = async (body: IUploadImageFromUrlRequestBody) => {
    if(this.debugMode) console.log('/api/users/upload-image-url', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/users/upload-image-url', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  forgotPassword = async (body: IForgotPasswordRequestBody) => {
    if(this.debugMode) console.log('/api/users/forgot-password', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/users/forgot-password', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  verifyForgotPassword = async (body: IVerifyForgotPasswordRequestBody) => {
    if(this.debugMode) console.log('/api/users/verify-forgot-password', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/users/verify-forgot-password', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  resetPassword = async (body: IResetPasswordRequestBody) => {
    if(this.debugMode) console.log('/api/users/reset-password', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/users/reset-password', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  syncData = async (body: ISyncDataRequestBody) => {
    if(this.debugMode) console.log('/api/users/sync', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/users/sync', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
}
export default new UserAPI(Request);