import create from 'zustand';
import { TClient } from 'type';
import Store from 'store';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { VarHelper } from 'helpers';

interface IClientStore {
  clientIds: string[]
  selectedClient?: TClient
  clients: {
    [clientId: string]: TClient
  },
  loading: boolean,
  errors: { id: string, error: string }[],
  set: (data: any) => void,
  setLoading: (value: boolean) => void,
  selectClient: (clientId: string) => void,
  getClients: () => void,
  getClientDetail: (clientId: string) => void,
  updateClientDetail: (clientId: string) => void,
  editClient: (id: string, key: string, value: any) => void,
}

export const useClientStore = create<IClientStore>((set, get) => ({
  clientIds: [],
  selectedClient: undefined,
  clients: {},
  loading: false,
  errors: [],
  set,
  get,
  setLoading: (value) => {
    set({ loading: value })
  },
  selectClient: (clientId: string) => {
    set({
      selectedClient: get().clients[clientId],
    })
  },
  getClients: async () => {
    try {
      if (get().loading) return;
      get().setLoading(true);
      const res = await Store.Client.Api.Client.list({});
      if (res.data.data && res.data.success) {
        const obj = {}
        const ids = []
        res.data.data.forEach(i => {
          ids.push(i.id);
          obj[i.id] = i;
        });

        set((state) => ({
          clients: Object.assign(state.clients, obj),
          clientIds: ids,
        }));
        return obj;
      }
    } catch (error) {
    } finally {
      get().setLoading(false);
    }
  },
  getClientDetail: async (id) => {
    try {
      if (get().loading) return;
      get().setLoading(true);
      const res = await Store.Client.Api.Client.detail({ id });
      if (res.data.data && res.data.success) {
        set((state) => ({
          clients: Object.assign(state.clients, { [id]: res.data.data }),
        }));
      }
    } catch (error) {
    } finally {
      get().setLoading(false);
    }
  },
  updateClientDetail: async (id) => {
    try {
      if (get().loading) return;
      get().setLoading(true);
      const params = get().clients?.[id];

      const res = await Store.Client.Api.Client.update(VarHelper.removeUndefinedNullField({
        id,
        name: params.name,
        location: params.location,
        logo: params.logo,
      }));
      if (res.data.data && res.data.success) {
        set((state) => ({
          clients: Object.assign(state.clients, { [id]: res.data.data }),
        }));
      }
    } catch (error) {
      alert(error?.message || error);
    } finally {
      get().setLoading(false);
    }
  },
  editClient: (id, key, value) => {
    if (!id) return;
    set(state => {
      const clients = _.cloneDeep(state.clients);
      _.set(clients, `${id}.${key}`, value);
      return {
        clients,
      }
    });
  },
}));

export const useClientInfo = (id: string) => {
  const { clients, getClientDetail, editClient, updateClientDetail } = useClientStore(state => ({
    clients: state.clients,
    getClientDetail: state.getClientDetail,
    editClient: state.editClient,
    updateClientDetail: state.updateClientDetail,
  }));
  const [edited, setEdited] = useState(false);

  useEffect(() => {
    if (id) getClientDetail(id);
  }, [id]);

  const refresh = useCallback(() => {
    getClientDetail(id);
  }, [id])

  const edit = useCallback((key, value) => {
    editClient(id, key, value);
    setEdited(true);
  }, [id])

  const save = useCallback(() => {
    updateClientDetail(id);
    setEdited(false);
  }, [id])

  return {
    client: clients?.[id],
    refresh,
    edit,
    edited,
    save,
  };
}
