import create from 'zustand';
import { TPathfinder } from 'type';
import Store from 'store';
import { VarHelper } from 'helpers';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import ExportHelper from 'helpers/ExportHelper';

type PathfinderItem = TPathfinder & {
  isNew?: boolean
}

interface IPathfinderStore {
  pathfinders: { [id: string]: PathfinderItem },
  pathfinderIds: string[],
  expandingPathfinderId: string,
  editedPathfinderIds: string[],
  currentPathfinder?: TPathfinder,
  loading: boolean,
  isSelectMode: boolean,
  selectedPathfinder: string[],
  errors: { id: string, error: string }[],
  set: (data: any) => void,
  getPathfinders: (clientId?: string) => void,
  addEmptyPathfinder: () => void,
  editPathfinder: (id: string, key: string, value: any) => void,
  reOrderPathfinders: (newArr: string[]) => void,
  autoUpdate: () => void,
  getPathfinderDetail: (id: string) => void,
  exportPathfinderToExcel: (id: string) => void,
  selectPathfinder: (id: string) => void,
  expandPathfinder: (id: string) => void,
}

let autoUpdateTimeout = setTimeout(() => { }, 0);

export const usePathfinderStore = create<IPathfinderStore>((set, get) => ({
  pathfinders: {},
  pathfinderIds: [],
  expandingPathfinderId: '',
  editedPathfinderIds: [],
  loading: false,
  isSelectMode: false,
  selectedPathfinder: [],
  errors: [],
  set,
  getPathfinders: async (clientId) => {
    try {
      if (get().loading) return;
      set({ loading: true });
      const res = await Store.Client.Api.Pathfinder.list(VarHelper.removeUndefinedNullField({ clientId }));
      set({ loading: false });
      if (res.data.data && res.data.success) {
        const obj = {}
        const ids = []
        res.data.data.forEach(i => {
          ids.push(i.id);
          obj[i.id] = i;
        });
        set({
          pathfinders: _.cloneDeep(obj),
          pathfinderIds: ids,
        });
        if (!get().expandingPathfinderId) {
          set({
            expandingPathfinderId: ids[0],
          })
        }
        return res.data.data;
      }
    } catch (error) {
      set({ loading: false });
    }
  },
  addEmptyPathfinder: async () => {
    set(state => {
      const newId = uuid();
      const newPathfinders = state.pathfinders;
      newPathfinders[newId] = {
        id: newId,
        clientId: '',
        name: 'PowerPoint',
        logo: '',
        welcomeInstruction: 'Welcome to the {{.PathfinderName}} Pathfinder',
        additionalInstruction: 'Select the role to see the most relevant workflows for your learning.',
        completionInstruction: 'Congratulations {{.FirstName}}. You will receive also your personalized learning plan in an email. Click "Go to Results" to start learning now -- or Exit and learn later.',
        listStatementTitle: 'Your workflows',
        listStatementSubtitle: 'Move the slider to indicate how you work.',
        listLearningTitle: 'This is your personalized plan. Click on an item to begin learning.',
        likertScaleTitle1: 'I got this!',
        likertScaleTitle2: 'Advanced Skills',
        likertScaleTitle3: 'Show me everything',
        elementsTitle: 'Role',
        disableElementsFilter: false,
        sendEmail: false,
        additionalRecipients: '',
        emailSubject: '',
        emailContent: '',
        emailFooter: '',
        orderIndex: 0,
        data: {
          resultDialogTitle: 'Complete!',
        },
        isNew: true,
      }
      return {
        pathfinderIds: [newId, ...state.pathfinderIds],
        pathfinders: newPathfinders,
        editedPathfinderIds: [newId, ...state.editedPathfinderIds],
        expandingPathfinderId: newId,
      }
    })
  },
  editPathfinder: (id, key, value) => {
    if (!id) return;
    set(state => {
      const pathfinders = _.cloneDeep(state.pathfinders);
      _.set(pathfinders, `${id}.${key}`, value);
      return {
        pathfinders,
        editedPathfinderIds: _.uniq([id, ...state.editedPathfinderIds]),
      }
    });

    clearTimeout(autoUpdateTimeout);
    autoUpdateTimeout = setTimeout(() => {
      get().autoUpdate();
    }, 2000);
  },
  autoUpdate: async () => {
    const { pathfinders, editedPathfinderIds } = get();
    if (!editedPathfinderIds.length) return;
    clearTimeout(autoUpdateTimeout);
    const errors = [];
    const savedPathfinders = _.cloneDeep(pathfinders);
    await Promise.all(
      editedPathfinderIds.reverse().map(async (id) => {
        const item = { ...savedPathfinders[id] };
        delete item.clientId;
        let res;
        const params = VarHelper.removeUndefinedNullField({
          id,
          name: item.name,
          logo: item.logo,
          welcomeInstruction: item.welcomeInstruction,
          additionalInstruction: item.additionalInstruction,
          completionInstruction: item.completionInstruction,
          listStatementTitle: item.listStatementTitle,
          listStatementSubtitle: item.listStatementSubtitle,
          listLearningTitle: item.listLearningTitle,
          likertScaleTitle1: item.likertScaleTitle1,
          likertScaleTitle2: item.likertScaleTitle2,
          likertScaleTitle3: item.likertScaleTitle3,
          elementsTitle: item.elementsTitle,
          disableElementsFilter: item.disableElementsFilter,
          sendEmail: item.sendEmail,
          additionalRecipients: item.additionalRecipients,
          emailSubject: item.emailSubject,
          emailContent: item.emailContent,
          emailFooter: item.emailFooter,
          orderIndex: item.orderIndex,
          data: item.data,
        });
        if (item.isNew) {
          res = await Store.Client.Api.Pathfinder.create({
            ...params,
          });
        } else {
          res = await Store.Client.Api.Pathfinder.update({
            ...params,
          })
        }
        if (res?.data?.success && res?.data?.data) {
          savedPathfinders[id] = res.data.data;
        } else {
          errors.push({
            id: item.id,
            error: res.data.error,
          });
        }
      })
    );

    set({
      pathfinders: savedPathfinders,
      editedPathfinderIds: [],
      errors,
    });
  },
  getPathfinderDetail: async (id: string) => {
    const res = await Store.Api.Pathfinder.detail({
      id,
    })
    if (res?.data?.success && res?.data?.data) {
      set({
        currentPathfinder: res.data.data
      })
    }
  },
  exportPathfinderToExcel: async (id: string) => {
    const res = await Store.Api.Pathfinder.export({ pathfinderIds: [id] });
    if (res.data.data) {
      ExportHelper.saveDataToFile(res.data.data);
    }
  },
  selectPathfinder: (id: string) => {
    set(state => ({
      selectedPathfinder: state.selectedPathfinder.includes(id)
        ? state.selectedPathfinder.filter(i => i !== id)
        : state.selectedPathfinder.concat([id])
    }))
  },
  expandPathfinder: (id: string) => {
    set({
      expandingPathfinderId: id
    })
  },
  reOrderPathfinders: (pathfinderIds) => {
    const obj = {}
    const ids = []
    if (!pathfinderIds?.length) return;
    pathfinderIds.forEach((i, idx) => {
      ids.push(i)
      obj[i] = {
        ...get().pathfinders[i],
        orderIndex: idx,
      };
    });
    set((state) => ({
      pathfinderIds,
      pathfinders: Object.assign(state.pathfinders, obj),
      editedPathfinderIds: _.uniq([...ids, ...state.editedPathfinderIds]),
    }));

    clearTimeout(autoUpdateTimeout);
    autoUpdateTimeout = setTimeout(() => {
      get().autoUpdate();
    }, 2000);
  },
}));

export const usePathfinder = (id: string) => {
  const { pathfinders } = usePathfinderStore(state => ({
    pathfinders: state.pathfinders,
  }));
  return pathfinders?.[id];
}
