import React, { useImperativeHandle, forwardRef, useRef } from 'react';

import Store from 'store';
import { modalConfirm } from '../modal';

interface IProps {
  isMulti?: boolean,
  onUploaded(urls: Array<string>): void,
  onUploading?: () => void,
  accept?: string,
}

const UploadLearningImage = forwardRef((props: IProps, ref) => {

  const inputRef = useRef(null);

  const clearFiles = () => {
    if (!inputRef.current) return;
    inputRef.current.value = '';
    inputRef.current.type = '';
    inputRef.current.type = 'file';
  }

  const onChange: React.InputHTMLAttributes<HTMLInputElement>['onChange'] = async e => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return props.onUploaded([]);
    props.onUploading?.();
    const toBeProcessedFiles = props.isMulti ? files : [files[0]];

    modalConfirm.show({
      title: 'Confirm',
      message: 'Input learning type of the image',
      isPrompt: true,
      onConfirm: async (prompt) => {
        await uploadImages(toBeProcessedFiles, prompt);
      }
    })
  }

  const uploadImages = async (files, type) => {
    const promiseArr = files.map(async (val: File) => {
      try {
        const res1 = await Store.Api.User.getUploadPresignedUrl({
          contentType: val.type,
          // serverSidePath: 'PathfinderCreationTemplate.xlsx'
        });
        const awsUrl = res1.data.data
        if (!awsUrl) return undefined;

        const res2 = await fetch(awsUrl, {
          method: 'PUT',
          headers: {
            'x-amz-acl': 'public-read',
            'Content-Type': val.type,
          },
          body: val,
        });

        if (res2.status !== 200) return undefined;
        const u = new URL(awsUrl);
        await Store.Api.Image.create({
          name: val.name,
          url: `${u.origin}${u.pathname}`,
          type,
        });
        return `${u.origin}${u.pathname}`;
      } catch (err) {
        return undefined;
      }
    });

    const urls = await Promise.all(promiseArr);
    props.onUploaded(urls.filter(val => !!val));
    clearFiles();
  }

  useImperativeHandle(ref, () => ({

    showDialog: () => {
      console.log('showDialog', inputRef.current);
      inputRef.current?.click();
    },

  }));

  return (
    <input accept={props.accept} multiple ref={inputRef} type="file" style={{ display: 'none' }} onChange={onChange} />
  );
});

export default UploadLearningImage;
