import React, { useState, useEffect, useCallback } from 'react';
import { Col, IColProps, Row, Text, TouchField, CheckBox, Dropdown, modal, PreviewEmailModal, SelectClientModal, ImportStatementButton, StandalonePublishModal } from 'components';
import { COLOR } from 'const';
import { TPathfinder } from 'type';
import { FontAwesome5, MaterialCommunityIcons, Fontisto } from '@expo/vector-icons';
import Store from 'store';
import { usePathfinder } from 'store/Pathfinder.Store';
import { LineVertical, RowInputWithHint, RowTable } from './Commons';
import TabStatements from './TabStatements';
import { modalConfirm } from 'components/elements/modal/ConfirmModal';
import ReactQuill from 'react-quill';
import { Tooltip } from 'react-tippy';
import 'react-quill/dist/quill.snow.css';
import TabElements from './TabElements';
import apiClient from 'store/api-client';
import { VarHelper } from 'helpers';

interface Props extends IColProps {
  pathfinderId?: string
  onRefresh?: () => void
  collapsePathfinder?: () => void
}

const DROPDOWN_MENU = [
  { id: 'delete', label: 'Delete', icon: 'trash' },
  { id: 'export', label: 'Export', icon: 'file-download' },
  { id: 'duplicate', label: 'Duplicate', icon: 'clone' },
  { id: 'scorm-1.2', label: 'Publish SCORM 1.2', icon: 'upload' },
  { id: 'scorm-2004', label: 'Publish SCORM 2004', icon: 'upload' },
  { id: 'standalone', label: 'Standalone Publish', icon: 'upload' },
]

const PathfinderContents = ({ pathfinderId, ...props }: Props) => {
  const pathfinder: TPathfinder = usePathfinder(pathfinderId);
  const [tab, setTab] = useState('info');
  const { editPathfinder, exportPathfinderToExcel, autoUpdate: autoUpdatePathfinders } = Store.usePathfinderStore(state => ({
    autoUpdate: state.autoUpdate,
    editPathfinder: state.editPathfinder,
    exportPathfinderToExcel: state.exportPathfinderToExcel,
    isSelectMode: state.isSelectMode,
    selectPathfinder: state.selectPathfinder,
    selectedPathfinder: state.selectedPathfinder,
  }));
  const { loading: loadingElements, getElements, addEmptyElement, editElement, autoUpdate: autoUpdateElements, toggleSelectMode: toggleSelectModeElm, selectModePathfinder: selectModePathfinderElm } = Store.useElementStore();
  const { loading: loadingStatements, getStatements, addEmptyStatement, autoUpdate: autoUpdateStatements, toggleSelectMode, selectModePathfinder: selectModePathfinderStm } = Store.useStatementStore();
  const { user } = Store.useUserStore();

  useEffect(() => {
    if (tab === 'elements' && loadingElements?.[pathfinderId] === undefined) {
      getElements(pathfinderId);
    }
    if (tab === 'work-statements' && loadingStatements?.[pathfinderId] === undefined) {
      getElements(pathfinderId);
      getStatements(pathfinderId);
    }
  }, [tab, pathfinder]);

  const onPressDropdownOption = useCallback((opt) => {
    if (opt.id === 'delete') {
      modalConfirm.show({
        title: 'Delete',
        message: 'Do you want to delete this pathfinder?',
        onConfirm: async () => {
          await Store.Api.Pathfinder.remove({
            id: pathfinderId
          });
          await props.onRefresh?.();
        }
      })
    }
    if (opt.id === 'export') {
      exportPathfinderToExcel(pathfinderId);
    }
    if (opt.id === 'duplicate') {
      if (user.role === 'admin') {
        setTimeout(() => {
          modal.show(
            <SelectClientModal
              title='Select client to duplicate pathfinder to'
              pathfinderId={pathfinderId}
              refresh={() => props.onRefresh?.()}
            />
            , { contentStyle: {} })
        }, 300);
        return;
      }
      modalConfirm.show({
        title: 'Duplicate',
        message: 'Do you want to duplicate this pathfinder?',
        onConfirm: async () => {
          await Store.Api.Pathfinder.duplicate({
            pathfinderId,
          });
          await props.onRefresh?.();
        }
      })
    }
    if (opt.id === 'scorm-1.2' || opt.id === 'scorm-2004') {
      Store.Api.Pathfinder.downloadScorm({
        id: pathfinderId,
        type: opt.id,
      }).then(res => {
        const url = res?.data?.data?.url;
        if (url) {
          window.open(url, '_blank');
        }
      })
    }
    if (opt.id === 'standalone') {
      if (window.location.host.includes("pathfinder-rebuild.devserver.london")) {
        setTimeout(() => {
          modal.show(
            <StandalonePublishModal
              url={`https://learner.pathfinder-rebuild.devserver.london/${pathfinder.id}`}
            />
            , { contentStyle: {} })
        }, 300);
      } else {
        apiClient.Api.Client.siteInfo({ slug: pathfinder.clientId }).then(res => {
          if (res.data.data?.url) {
            const url = VarHelper.replacePlaceholders(res.data.data.url, {
              pathfinderId: pathfinder.id,
            })
            modal.show(
              <StandalonePublishModal
                url={url}
              />
              , { contentStyle: {} })
          }
        })
      }
    }
  }, [pathfinderId, pathfinder, user]);

  const TABS = [
    { label: 'Info', key: 'info' },
    { label: 'Email Settings', key: 'email-settings' },
    { label: 'Elements', key: 'elements' },
    { label: 'Work Statements', key: 'work-statements' },
  ];

  const onChangeTab = (key) => {
    if (tab === 'info') {
      autoUpdatePathfinders()
    }
    if (tab === 'elements') {
      autoUpdateElements()
    }
    if (tab === 'work-statements') {
      autoUpdateStatements()
    }
    setTab(key)
  }

  const showPreviewEmail = () => {
    modal.show(
      <PreviewEmailModal
        body={pathfinder?.emailContent} footer={pathfinder?.emailFooter}
        title='Example Only Of What Recipient Will See'
        pathfinderName={pathfinder?.name}
        pathfinderId={pathfinder?.id}
      />
    )
  }

  const openPathfinder = async () => {
    if (window.location.host.includes("pathfinder-rebuild.devserver.london")) {
      window.open(`https://learner.pathfinder-rebuild.devserver.london/${pathfinder.id}`, 'blank');
    } else {
      apiClient.Api.Client.siteInfo({ slug: pathfinder.clientId }).then(res => {
        if (res.data.data?.url) {
          const url = VarHelper.replacePlaceholders(res.data.data.url, {
            pathfinderId: pathfinder.id,
          })
          window.open(url, 'blank');
        }
      })
    }
  }

  const infoFields = [
    {
      label: 'Pathfinder Name',
      value: pathfinder?.name,
      fieldKey: 'name',
      // textIcon: 'Preview',
      // onPressIcon: openPathfinder,
    },
    {
      label: 'Show pathfinder name',
      value: pathfinder?.data?.showPathfinderName,
      fieldKey: 'data.showPathfinderName',
      hint: 'Show pathfinder name on the header, next to the logo',
    },
    {
      label: 'Welcome Instruction',
      value: pathfinder?.welcomeInstruction,
      fieldKey: 'welcomeInstruction',
      hint: 'Available Filtered Variables: {{.PathfinderName}}',
    },
    {
      label: 'Additional Instruction',
      value: pathfinder?.additionalInstruction,
      fieldKey: 'additionalInstruction',
      hint: '',
    },
    {
      label: 'Results Dialog Title',
      value: pathfinder?.data?.resultDialogTitle,
      fieldKey: 'data.resultDialogTitle',
      hint: '',
    },
    {
      label: 'Completion Dialog Instructions',
      value: pathfinder?.completionInstruction,
      fieldKey: 'completionInstruction',
      hint: 'Available Filtered Variables: {{.FirstName}}, {{.PathfinderName}}',
    },
    {
      label: 'Statement Dialog Title',
      value: pathfinder?.listStatementTitle,
      fieldKey: 'listStatementTitle',
      hint: '',
    },
    {
      label: 'Statement Dialog Instructions',
      value: pathfinder?.listStatementSubtitle,
      fieldKey: 'listStatementSubtitle',
      hint: '',
    },
    {
      label: 'Results dialog instructions',
      value: pathfinder?.listLearningTitle,
      fieldKey: 'listLearningTitle',
      hint: 'Available Filtered Variables: {{.ElementName}}, {{.FirstName}}, {{.PathfinderName}}',
    },
    {
      label: 'Screen Element Title',
      value: pathfinder?.elementsTitle,
      fieldKey: 'elementsTitle',
      hint: '',
    },
    {
      label: 'Likert Scale Left',
      value: pathfinder?.likertScaleTitle1,
      fieldKey: 'likertScaleTitle1',
    },
    {
      label: 'Likert Scale Middle',
      value: pathfinder?.likertScaleTitle2,
      fieldKey: 'likertScaleTitle2',
    },
    {
      label: 'Likert Scale Right',
      value: pathfinder?.likertScaleTitle3,
      fieldKey: 'likertScaleTitle3',
    },
  ];

  const emailSettingsFields = [
    {
      label: 'Send To User',
      value: pathfinder?.sendEmail,
      fieldKey: 'sendEmail',
    },
    {
      label: 'Additional recipients',
      value: pathfinder?.additionalRecipients,
      fieldKey: 'additionalRecipients',
      hint: 'Enter email address of the users (separated by comma) you wish to view this email as well as the users that complete the pathfinder.',
    },
    {
      label: 'Subject Line',
      value: pathfinder?.emailSubject,
      fieldKey: 'emailSubject',
      hint: '',
    },
    {
      label: 'Welcome & Body Content',
      value: pathfinder?.emailContent,
      fieldKey: 'emailContent',
      type: 'email',
      hint: 'Available Filtered Variables: {{.FirstName}}, {{.PathfinderName}}',
    },
    {
      label: 'Body footer',
      value: pathfinder?.emailFooter,
      fieldKey: 'emailFooter',
      type: 'email',
      hint: '',
    },
  ];

  const addEmptyPathfinderEntity = () => {
    if (tab === 'elements') {
      addEmptyElement(pathfinderId);
    }
    if (tab === 'work-statements') {
      addEmptyStatement(pathfinderId);
    }
  }

  const onPressToggleSelectMode = () => {
    if (tab === 'elements') {
      toggleSelectModeElm(pathfinderId)
    } else {
      toggleSelectMode(pathfinderId)
    }
  }

  const downloadTemplate = useCallback(() => {
    window.open(`https://pathfinder-cd.fra1.digitaloceanspaces.com/TemplateStatement.xlsx`)
  }, [])

  return (
    <Col flex1 m0 p0 bgWhite round1>
      <Row justifyContent={'space-between'}>
        <Row>
          {TABS.map((v, vI) => {
            const isActive = tab === v.key;
            return (
              <TouchField
                key={'tab-' + vI} dataSet={{ element: 'pathfinder-tab' }}
                borderRadius={0} p0 mr0 borderBottomWidth={isActive ? 2 : 0} borderBottomColor={COLOR.MAIN} onPress={() => onChangeTab(v.key)}>
                <Text fontSize={13} fontWeight={'600'} color={isActive ? COLOR.MAIN : undefined}>{v.label}</Text>
              </TouchField>
            );
          })}
        </Row>
        <Row>
          <Tooltip title={'Preview the pathfinder'} trigger='mouseenter'>
            <TouchField
              marginRight={8}
              width={30} height={30}
              borderRadius={15} middle
              onPress={openPathfinder}
            >
              <Fontisto name='preview' size={20} color={COLOR.MAIN} />
            </TouchField>
          </Tooltip>
          <Tooltip title={'Collapse the pathfinder'} trigger='mouseenter'>
            <TouchField
              marginRight={8}
              width={30} height={30}
              borderRadius={15} middle
              onPress={props.collapsePathfinder}
            >
              <FontAwesome5 name='chevron-down' size={20} color={COLOR.MAIN} />
            </TouchField>
          </Tooltip>
          {tab === 'work-statements' &&
            <>
              {/* <Tooltip title={'Download import statements template'} trigger='mouseenter'>
                <TouchField
                  marginRight={8}
                  width={30} height={30}
                  borderRadius={15} middle
                  onPress={downloadTemplate}
                >
                  <FontAwesome5 name={'download'} size={20} color={COLOR.MAIN} />
                </TouchField>
              </Tooltip> */}
              <Tooltip title={'Import statements from excel'} trigger='mouseenter'>
                <ImportStatementButton pathfinderId={pathfinderId} />
              </Tooltip>
            </>
          }
          {['elements', 'work-statements'].includes(tab) &&
            <Row>
              <Tooltip title={`Select ${tab} to delete`} trigger='mouseenter'>
                <TouchField
                  width={30} height={30}
                  borderRadius={15} middle
                  onPress={onPressToggleSelectMode}
                  dataSet={{ element: 'toggle-select-mode' }}
                >
                  <MaterialCommunityIcons
                    name={
                      (selectModePathfinderElm === pathfinderId && tab === 'elements') ||
                        (selectModePathfinderStm === pathfinderId && tab === 'work-statements')
                        ? 'selection-ellipse-remove' : 'playlist-remove'
                    }
                    size={26} color={COLOR.MAIN}
                  />
                </TouchField>
              </Tooltip>
              <Tooltip title={`Add ${tab === 'elements' ? 'element' : 'work-statement'}`} trigger='mouseenter'>
                <TouchField
                  marginLeft={8}
                  width={30} height={30}
                  borderRadius={15} middle
                  onPress={addEmptyPathfinderEntity}
                  dataSet={{ element: 'add-pathfinder-entity' }}
                >
                  <FontAwesome5 name='plus-circle' size={20} color={COLOR.MAIN} />
                </TouchField>
              </Tooltip>
            </Row>
          }
          {tab === 'info' &&
            <Dropdown
              width={30} height={30}
              borderRadius={15} middle
              dataSet={{ element: 'pathfinder-actions' }}
              options={DROPDOWN_MENU}
              onPressOption={onPressDropdownOption}
            >
              <FontAwesome5 name='ellipsis-v' size={16} color={COLOR.MAIN} />
            </Dropdown>
          }
        </Row>
      </Row>
      <Col mt1>
        {tab === 'info' && (
          <>
            {infoFields.map((v, vI) => {
              if (v.fieldKey === 'data.showPathfinderName') {
                return (
                  <RowTable key={'info-row-' + vI} backgroundColor={vI % 2 ? undefined : COLOR.BG_TABLE_ROW}>
                    <Col width={150}>
                      <Text caption bold>{v.label}</Text>
                    </Col>
                    <LineVertical />
                    <Col flex1 ml0>
                      <CheckBox
                        value={v.value as boolean}
                        onChange={(val) => editPathfinder(pathfinderId, v.fieldKey, val)}
                      />
                    </Col>
                  </RowTable>
                )
              }
              return (
                <RowTable key={'info-row-' + vI} backgroundColor={vI % 2 ? undefined : COLOR.BG_TABLE_ROW}>
                  <Col width={150}>
                    <Text caption bold>{v.label}</Text>
                  </Col>
                  <LineVertical />
                  <Col flex1 ml0>
                    <RowInputWithHint
                      value={v.value ? String(v.value) : undefined}
                      onChangeText={(text) => editPathfinder(pathfinderId, v.fieldKey, text)}
                      hint={v.hint}
                    // textIcon={v.textIcon}
                    // onPressIcon={v.onPressIcon}
                    />
                  </Col>
                </RowTable>
              )
            })}
          </>
        )}
        {tab === 'email-settings' && (
          <>
            {emailSettingsFields.map((v, vI) => {
              if (v.fieldKey === 'sendEmail') {
                return (
                  <RowTable key={'info-row-' + vI} backgroundColor={vI % 2 ? undefined : COLOR.BG_TABLE_ROW}>
                    <Col width={150}>
                      <Text caption bold>{v.label}</Text>
                    </Col>
                    <LineVertical />
                    <Col flex1 ml0>
                      <CheckBox
                        value={v.value as boolean}
                        onChange={(val) => editPathfinder(pathfinderId, v.fieldKey, val)}
                      />
                    </Col>
                  </RowTable>
                )
              }
              return (
                <RowTable key={'info-row-' + vI} backgroundColor={vI % 2 ? undefined : COLOR.BG_TABLE_ROW}>
                  <Col width={150}>
                    <Text caption bold>{v.label}</Text>
                  </Col>
                  <LineVertical />
                  <Col flex1 ml0>
                    {v.type === 'email'
                      ?
                      <ReactQuill
                        className='custom-quill'
                        value={v.value as string}
                        onChange={(text) => editPathfinder(pathfinderId, v.fieldKey, text)}
                      />
                      :
                      <RowInputWithHint
                        value={v.value as string}
                        onChangeText={(text) => editPathfinder(pathfinderId, v.fieldKey, text)}
                        hint={v.hint}
                      />
                    }
                  </Col>
                </RowTable>
              )
            })}
            <TouchField
              onPress={showPreviewEmail}
              padding={6}
              borderRadius={12}
              middle mt0 ml0
            >
              <Text color={COLOR.MAIN}>Preview email</Text>
            </TouchField>
          </>
        )}
        {tab === 'elements' && (
          <TabElements pathfinderId={pathfinderId} />
        )}
        {tab === 'work-statements' && (
          <TabStatements pathfinderId={pathfinderId} />
        )}
      </Col>
    </Col>
  )
};

export default React.memo(PathfinderContents)
