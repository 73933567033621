import { Col, Text, Button, Input, Image, TouchField } from 'components';
import { IScreen } from 'type';
import React, { useState } from 'react';
import { COLOR, SCREEN } from 'const';
import { ASSETS } from 'assets';
import { useNavFunc } from 'navigation';
import Store from 'store';

const ForgotPassword: IScreen = () => {
  const { navigate, route } = useNavFunc();
  const { code }: any = route.params || {};
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMes, setErrorMes] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const onSubmit = async () => {
    try {
      if (loading) return;
      setLoading(true);
      const res = await Store.Api.User.forgotPassword({
        email,
      });
      if (!res.data.success) {
        setErrorMes(res.data.error);
      } else {
        setShowMessage(true);
      }
    } catch (error) {
      setErrorMes(error.message || JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  }

  const onPressLogin = () => {
    navigate(SCREEN.Login);
  }

  return (
    <Col flex1 backgroundColor={COLOR.GREY_BG}>
      <Col absoluteFill zIndex={1}>
        <Image source={ASSETS.LOGIN_BG} style={{ width: '100%', height: '100%' }} resizeMode='cover' />
      </Col>
      <Col zIndex={2} flex1 middle>
        <Col bgWhite borderRadius={8} width={320}>
          <Image source={ASSETS.LOGO} style={{ width: 200, height: 100, alignSelf: "center" }} resizeMode='contain' />
          {showMessage ?
            <Col middle padding={20}>
              <Text textAlign={"center"}>Check your email and confirm resetting the password</Text>
            </Col>
            :
            <Col padding={20} paddingBottom={4}>
              <Text>What is your email?</Text>
              <Input
                mt2
                value={email}
                onChange={setEmail}
                // placeholder={"Input your email"}
                inputProps={{ nativeID: 'input-your-email' }}
              />
              {!!errorMes &&
                <Text color="red" body1 bold mv1>{errorMes}</Text>
              }
              <Button
                mt2
                solid
                text='SUBMIT'
                width='100%'
                isLoading={loading}
                disabled={!email}
                onPress={onSubmit}
              />
              <TouchField p1 alignSelf="center" onPress={onPressLogin}>
                <Text color={COLOR.MAIN}>Login</Text>
              </TouchField>
            </Col>
          }
        </Col>
      </Col>
    </Col>
  )
}

ForgotPassword.routeInfo = {
  title: 'Forgot Password',
  path: '/forgot-password',
}

export default ForgotPassword;
