import Request from '../Request.utils'
export interface IListRequestQuery {
  clientId?: string;
  name?: string;
  type?: string;
}
export interface ICreateRequestBody {
  clientId?: string;
  data?: object;
  id?: string;
  name?: string;
  type?: string;
  url?: string;
}
export interface IRemoveRequestBody {
  id: string;
}
export interface IUpdateRequestBody {
  id: string;
  name?: string;
  type?: string;
}


export class ImageAPI {


  request = Request // for ts auto suggestion
  debugMode = false // for ts auto suggestion
  constructor(request, debugMode = false) {
    if(request) this.request = request;
    this.debugMode = debugMode;
  }
  list = async (query: IListRequestQuery) => {
    if(this.debugMode) console.log('/api/images', 'GET', undefined, query, undefined, );
    const res = await this.request.call('/api/images', 'GET', undefined, query, undefined, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  create = async (body: ICreateRequestBody) => {
    if(this.debugMode) console.log('/api/images/create', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/images/create', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  remove = async (body: IRemoveRequestBody) => {
    if(this.debugMode) console.log('/api/images/remove', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/images/remove', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  update = async (body: IUpdateRequestBody) => {
    if(this.debugMode) console.log('/api/images/update', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/images/update', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
}
export default new ImageAPI(Request);