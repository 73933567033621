import { Col, Text, TouchField, Row, Table, modalConfirm, modal } from 'components';
import React, { useMemo } from 'react';
import { COLOR } from 'const';
import { ScrollView, useWindowDimensions } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import { Tooltip } from 'react-tippy';
import { useLearningStore } from 'store/Learning.Store';
import { isArray } from 'lodash';

const TagsManagerModal = () => {
  const { height } = useWindowDimensions();
  const { learnings, removeTag } = useLearningStore();

  const allTags = useMemo(() => {
    const result = [];
    if (!isArray(result)) return result;
    Object.keys(learnings).map(key => {
      const learning = learnings[key];
      learning?.tags?.split(',').forEach(key => {
        if (!key) return;
        if (result.some(i => i.value === key.trim())) {
          const idx = result.findIndex(i => i.value === key.trim());
          result[idx] = {
            value: key.trim(),
            count: (result[idx].count || 0) + 1
          }
        } else {
          result.push({
            value: key.trim(),
            count: 1,
          })
        }
      })
    })
    return result;
  }, [learnings])

  const data = useMemo(() => {
    if (!allTags?.length) return [];

    const confirmDelete = (tag) => {
      modalConfirm.show({
        title: 'Delete',
        message: 'Do you want to delete this tag?',
        onConfirm: async () => {
          await removeTag(tag);
        }
      })
    }

    return allTags.map(i => ({
      ...i,
      actions: <Row justifyContent='flex-end'>
        <Tooltip title='Delete tag' trigger='mouseenter'>
          <TouchField
            onPress={() => confirmDelete(i.value)}
            padding={6}
          >
            <FontAwesome5
              name={'trash'}
              size={22}
              color={COLOR.RED}
            />
          </TouchField>
        </Tooltip>
      </Row>
    }))
  }, [allTags])

  return (
    <Col round1 bgWhite shadow p2 paddingBottom={0} maxHeight={height * 0.8}>
      <ScrollView style={{ flex: 1 }} contentContainerStyle={{ paddingBottom: 16 }} showsVerticalScrollIndicator={false}>
        <Row justifyContent={'space-between'} alignItems={'flex-end'} flexWrap={"wrap"} mb2>
          <Text h3 mb2 bold color={COLOR.MAIN}>Tags manager</Text>
          {/* <TouchField
            height={40}
            ph2
            middle
            width={200}
            borderColor={COLOR.MAIN}
            borderWidth={1}
            backgroundColor={COLOR.MAIN}
            onPress={onPressUpload}
          >
            <Row>
              {loading && <ActivityIndicator size='small' color={'white'} style={{ marginRight: 5 }} />}
              <Text color={'white'}>Select icon</Text>
            </Row>
          </TouchField> */}
        </Row>
        <Table
          columns={[
            { key: 'value', title: 'Tag', width: 150, padding: 10 },
            { key: 'count', title: 'Learnings', width: 150 },
            { key: 'actions', title: '', width: 100 },
          ]}
          data={data}
          style={{ margin: 20 }}
          minWidthRequired={400}
        />
        <Col absolute right={-8} top={-8}>
          <TouchField padding={8} onPress={() => modal.hide()}>
            <FontAwesome5 name='times' size={18} color={COLOR.MAIN} />
          </TouchField>
        </Col>
      </ScrollView>
    </Col>
  )
};

export default TagsManagerModal;
