import create from 'zustand';
import { TElement } from 'type';
import Store from 'store';
import _ from 'lodash';
import { VarHelper } from 'helpers';
import { v4 as uuid } from 'uuid';

type ElementItem = TElement & {
  isNew?: boolean
}

interface IElementStore {
  elementIds: {
    [pathfinderId: string]: string[]
  }
  editedElementIds: string[]
  elements: {
    [elementId: string]: ElementItem
  },
  loading: {
    [pathfinderId: string]: boolean
  },
  errors: { id: string, error: string }[],
  selectModePathfinder: string,
  selectedElement: string[],
  selectElement: (id: string) => void,
  toggleSelectMode: (id: string) => void,
  set: (data: any) => void,
  setLoading: (pathfinderId: string, value: boolean) => void,
  getElements: (pathfinderId: string) => void,
  reOrderElements: (newElements: ElementItem[]) => void,
  addEmptyElement: (pathfinderId: string) => void,
  editElement: (id: string, key: string, value: any) => void,
  autoUpdate: () => void,
}

let autoUpdateTimeout = setTimeout(() => { }, 0);

export const useElementStore = create<IElementStore>((set, get) => ({
  elementIds: {},
  elements: {},
  editedElementIds: [],
  loading: {},
  errors: [],
  selectModePathfinder: '',
  selectedElement: [],
  set,
  setLoading: (pathfinderId, value) => {
    set((state) => ({
      loading: Object.assign(state.loading, {
        [pathfinderId]: value,
      })
    }))
  },
  getElements: async (pathfinderId) => {
    try {
      if (get().loading[pathfinderId]) return;
      get().setLoading(pathfinderId, true);
      const res = await Store.Client.Api.Element.list({ pathfinderId });
      if (res.data.data && res.data.success) {
        const obj = {}
        const ids = []
        res.data.data.forEach(i => {
          ids.push(i.id);
          obj[i.id] = i;
        });

        set((state) => ({
          elements: Object.assign(state.elements, obj),
          elementIds: Object.assign(state.elementIds, {
            [pathfinderId]: ids,
          })
        }));
      }
    } catch (error) {
    } finally {
      get().setLoading(pathfinderId, false);
    }
  },
  addEmptyElement: async (pathfinderId) => {
    set(state => {
      const newId = uuid();
      const elementIds = state.elementIds[pathfinderId] || [];

      return {
        elementIds: Object.assign(state.elementIds, {
          [pathfinderId]: [...elementIds, newId],
        }),
        elements: Object.assign(state.elements, {
          [newId]: {
            id: newId,
            clientId: '',
            pathfinderId,
            name: '',
            rolloverText: '',
            image: '',
            filterKeys: '',
            data: '',
            isNew: true,
            orderIndex: elementIds.length,
          }
        }),
      }
    })
  },
  editElement: (id, key, value) => {
    if (!id) return;
    set(state => {
      const elements = _.cloneDeep(state.elements);
      _.set(elements, `${id}.${key}`, value);
      return {
        elements,
        editedElementIds: _.uniq([id, ...state.editedElementIds]),
      }
    });

    clearTimeout(autoUpdateTimeout);
    autoUpdateTimeout = setTimeout(() => {
      get().autoUpdate();
    }, 2000);
  },
  autoUpdate: async () => {
    clearTimeout(autoUpdateTimeout);
    const { elements, editedElementIds } = get();
    if (!editedElementIds.length) return;
    const errors = [];
    const savedElements = _.cloneDeep(elements);
    await Promise.all(
      editedElementIds.reverse().map(async (id) => {
        const item = { ...savedElements[id] };
        let res;
        const params = VarHelper.removeUndefinedNullField({
          id,
          pathfinderId: item.pathfinderId,
          name: item.name,
          rolloverText: item.rolloverText,
          image: item.image,
          filterKeys: item.filterKeys,
          orderIndex: item.orderIndex,
        });
        if (item.isNew) {
          res = await Store.Client.Api.Element.create({
            ...params,
          });
        } else {
          delete params.pathfinderId;
          res = await Store.Client.Api.Element.update({
            ...params,
          })
        }
        if (res?.data?.success && res?.data?.data) {
          savedElements[id] = res.data.data;
        } else {
          errors.push({
            id: item.id,
            error: res.data.error,
          });
        }
      })
    );

    set({
      elements: savedElements,
      editedElementIds: [],
      errors,
    });
  },
  reOrderElements: (newElements) => {
    const obj = {}
    const ids = []
    if (!newElements?.length) return;
    newElements.forEach((i, idx) => {
      ids.push(i.id)
      obj[i.id] = {
        ...i,
        orderIndex: idx,
      };
    });
    set((state) => ({
      elements: Object.assign(state.elements, obj),
      editedElementIds: _.uniq([...ids, ...state.editedElementIds]),
    }));

    clearTimeout(autoUpdateTimeout);
    autoUpdateTimeout = setTimeout(() => {
      get().autoUpdate();
    }, 2000);
  },
  toggleSelectMode: (id: string) => {
    if (id === get().selectModePathfinder) {
      set({
        selectModePathfinder: '',
        selectedElement: [],
      });
    } else {
      set({
        selectModePathfinder: id,
        selectedElement: [],
      });
    }
  },
  selectElement: (id: string) => {
    set(state => ({
      selectedElement: state.selectedElement.includes(id)
        ? state.selectedElement.filter(i => i !== id)
        : state.selectedElement.concat([id])
    }))
  }
}));

export const useElements = (pathfinderId: string) => {
  const { elementIds, elements } = useElementStore(state => ({
    elementIds: state.elementIds,
    elements: state.elements,
  }));
  const ids = elementIds[pathfinderId] || [];
  return ids.map(i => elements[i]).sort(
    (a, b) => (a.orderIndex || 0) - (b.orderIndex || 0)
  );
}
