import { Col, Text, LearnerLayout } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';

const DetailPathfinder : IScreen = ( ) => {
  const { navigate } = useNavFunc();

  return  (
    <LearnerLayout>
      <Col flex1 m2 p2 round1 bgWhite shadow>
        <Text h4>Detail Pathfinder</Text>
      </Col>
    </LearnerLayout>
  )
};

DetailPathfinder.routeInfo = {
  title: 'Pathfinders',
  path: '/pathfinders/:id/:name',
};

export default DetailPathfinder;
