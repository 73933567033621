import create from 'zustand';
import { TStatement } from 'type';
import Store from 'store';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import { VarHelper } from 'helpers';

type StatementItem = TStatement & {
  isNew?: boolean
}

let autoUpdateTimeout = setTimeout(() => { }, 0);

interface IStatementStore {
  statementIds: {
    [pathfinderId: string]: string[]
  },
  statements: {
    [statementId: string]: StatementItem
  },
  editedStatementIds: string[],
  loading: {
    [pathfinderId: string]: boolean
  },
  errors: { id: string, error: string }[],
  selectModePathfinder: string,
  selectedStatement: string[],
  selectStatement: (id: string) => void,
  toggleSelectMode: (id: string) => void,
  set: (data: any) => void,
  reOrderStatements: (newArr: StatementItem[]) => void,
  setLoading: (pathfinderId: string, value: boolean) => void,
  getStatements: (pathfinderId: string) => void,
  addEmptyStatement: (pathfinderId: string) => void,
  editStatement: (id: string, key: string, value: any) => void,
  autoUpdate: () => void,
}

export const useStatementStore = create<IStatementStore>((set, get) => ({
  statementIds: {},
  statements: {},
  editedStatementIds: [],
  loading: {},
  errors: [],
  selectModePathfinder: '',
  selectedStatement: [],
  set,
  setLoading: (pathfinderId, value) => {
    set((state) => ({
      loading: Object.assign(state.loading, {
        [pathfinderId]: value,
      })
    }))
  },
  getStatements: async (pathfinderId) => {
    try {
      if (get().loading[pathfinderId]) return;
      get().setLoading(pathfinderId, true);
      const res = await Store.Client.Api.Statement.list(VarHelper.removeUndefinedNullField({ pathfinderId }));
      get().setLoading(pathfinderId, false);
      if (res.data.data && res.data.success) {
        const obj = {}
        const ids = []
        res.data.data.forEach(i => {
          ids.push(i.id);
          obj[i.id] = i;
        });

        set((state) => ({
          statements: Object.assign(state.statements, obj),
          statementIds: Object.assign(state.statementIds, {
            [pathfinderId]: ids,
          })
        }));
        return res.data.data;
      }
    } catch (error) {
      get().setLoading(pathfinderId, false);
    }
  },
  addEmptyStatement: async (pathfinderId) => {
    set(state => {
      const newId = uuid();
      const statementIds = state.statementIds[pathfinderId] || [];

      return {
        statementIds: Object.assign(state.statementIds, {
          [pathfinderId]: [newId, ...statementIds],
        }),
        statements: Object.assign(state.statements, {
          [newId]: {
            id: newId,
            clientId: '',
            pathfinderId,
            statement: '',
            rolloverLikert1: '',
            rolloverLikert2: '',
            rolloverLikert3: '',
            learningIdsLikert1: '',
            learningIdsLikert2: '',
            learningIdsLikert3: '',
            elementIds: '',
            isNew: true,
            orderIndex: 0,
          }
        }),
      }
    })
  },
  editStatement: (id, key, value) => {
    if (!id) return;
    set(state => {
      const statements = _.cloneDeep(state.statements);
      _.set(statements, `${id}.${key}`, value);
      return {
        statements,
        editedStatementIds: _.uniq([id, ...state.editedStatementIds]),
      }
    });

    clearTimeout(autoUpdateTimeout);
    autoUpdateTimeout = setTimeout(() => {
      get().autoUpdate();
    }, 2000);
  },
  autoUpdate: async () => {
    clearTimeout(autoUpdateTimeout);
    const { statements, editedStatementIds } = get();
    if (!editedStatementIds.length) return;
    const errors = [];
    const savedStatements = _.cloneDeep(statements);
    await Promise.all(
      editedStatementIds.reverse().map(async (id) => {
        const item = { ...savedStatements[id] };
        delete item.clientId;
        let res;
        const params = VarHelper.removeUndefinedNullField({
          id,
          pathfinderId: item.pathfinderId,
          statement: item.statement,
          rolloverLikert1: item.rolloverLikert1,
          rolloverLikert2: item.rolloverLikert2,
          rolloverLikert3: item.rolloverLikert3,
          learningIdsLikert1: item.learningIdsLikert1,
          learningIdsLikert2: item.learningIdsLikert2,
          learningIdsLikert3: item.learningIdsLikert3,
          elementIds: item.elementIds,
          orderIndex: item.orderIndex,
        });
        if (item.isNew) {
          res = await Store.Client.Api.Statement.create({
            ...params,
          });
        } else {
          delete params.pathfinderId
          res = await Store.Client.Api.Statement.update({
            ...params,
          })
        }
        if (res?.data?.success && res?.data?.data) {
          savedStatements[id] = res.data.data;
        } else {
          errors.push({
            id: item.id,
            error: res.data.error,
          });
        }
      })
    );

    set({
      statements: savedStatements,
      editedStatementIds: [],
      errors,
    });
  },
  reOrderStatements: (newStatements) => {
    const obj = {}
    const ids = []
    if (!newStatements?.length) return;
    newStatements.forEach((i, idx) => {
      ids.push(i.id)
      obj[i.id] = {
        ...i,
        orderIndex: idx,
      };
    });
    set((state) => ({
      statements: Object.assign(state.statements, obj),
      editedStatementIds: _.uniq([...ids, ...state.editedStatementIds]),
    }));

    clearTimeout(autoUpdateTimeout);
    autoUpdateTimeout = setTimeout(() => {
      get().autoUpdate();
    }, 2000);
  },
  toggleSelectMode: (id: string) => {
    if (id === get().selectModePathfinder) {
      set({
        selectModePathfinder: '',
        selectedStatement: [],
      });
    } else {
      set({
        selectModePathfinder: id,
        selectedStatement: [],
      });
    }
  },
  selectStatement: (id: string) => {
    set(state => ({
      selectedStatement: state.selectedStatement.includes(id)
        ? state.selectedStatement.filter(i => i !== id)
        : state.selectedStatement.concat([id])
    }))
  }
}));

export const useStatements = (pathfinderId: string) => {
  const { statements, statementIds } = useStatementStore(state => ({
    statements: state.statements,
    statementIds: state.statementIds,
  }));
  const ids = statementIds[pathfinderId] || [];
  return ids.map(i => statements[i]).sort(
    (a, b) => (a.orderIndex || 0) - (b.orderIndex || 0)
  );;
}
