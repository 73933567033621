import React from 'react';
import { SCREEN } from 'const';
import { MaterialIcons, Foundation, FontAwesome, FontAwesome5, Ionicons, Entypo } from '@expo/vector-icons';

export const adminNav = [
  { title: 'Admins', screen: SCREEN.ListGlobalAdmin, icon: ({ color }) => <MaterialIcons name="admin-panel-settings" size={20} color={color} /> },
  { title: 'Clients', screen: SCREEN.ListClients, icon: ({ color }) => <Foundation name="torso-business" size={20} color={color} /> },
  { title: 'Client Admins', screen: SCREEN.ListAllClientAdmin, icon: ({ color }) => <MaterialIcons name="admin-panel-settings" size={20} color={color} /> },
  { title: 'Duplicate Pathfinder', screen: SCREEN.DuplicatePathfinder, icon: ({ color }) => <FontAwesome5 name="copy" solid size={18} color={color} /> },
  { title: 'Reports', screen: SCREEN.AdminReport, icon: ({ color }) => <Ionicons name="md-stats-chart" size={18} color={color} /> },
  { title: 'Training Videos', screen: SCREEN.Home, icon: ({ color }) => <Entypo name="video" size={20} color={color} /> },
  // { title: 'Maintainer', screen: SCREEN.Maintainer, icon: ({ color }) => <MaterialIcons name="admin-panel-settings" size={20} color={color} /> },
];

export const clientNav = [
  { title: 'Client Admins', screen: SCREEN.ListClientAdmin, icon: ({ color }) => <MaterialIcons name="admin-panel-settings" size={20} color={color} /> },
  { title: 'Company Logo', screen: SCREEN.ClientInfo, icon: ({ color }) => <Foundation name="torso-business" size={20} color={color} /> },
  { title: 'Manage Tags', screen: SCREEN.ManageTags, icon: ({ color }) => <FontAwesome5 name="tag" size={18} color={color} /> },
  { title: 'Manage Learnings', screen: SCREEN.ManageLearnings, icon: ({ color }) => <FontAwesome5 name="graduation-cap" size={18} color={color} /> },
  { title: 'Pathfinders', screen: SCREEN.ListPathfinder, icon: ({ color }) => <FontAwesome5 name="list" size={18} color={color} /> },
  { title: 'Reports', screen: SCREEN.ClientReport, icon: ({ color }) => <Ionicons name="md-stats-chart" size={18} color={color} /> },
  { title: 'Training Videos', screen: SCREEN.Home, icon: ({ color }) => <Entypo name="video" size={20} color={color} /> },
];
