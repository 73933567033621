import React from 'react';
import { Entypo } from '@expo/vector-icons';
import { useWindowDimensions } from 'react-native';
import { modal, RatioCol, Col, TouchField } from 'components';

const VModal = ({ src }: any) => {
  const { width, height } = useWindowDimensions();
  return (
    <Col width={ width <= 600 ? width * 0.9 : width * 0.7}>
      <RatioCol width='100%' ratio={2560/1440}>
        <video src={src} playsInline autoPlay muted controls style={{ width: '100%', height: '100%' }} />
      </RatioCol>
    </Col>
  )
}

const VideoPlayInModal = ({ src, ...props } : any) => {

  const showModal = () => {
    modal.show(<VModal src={src} />)
  }

  return (
    <RatioCol width='100%' ratio={2560/1440} {...props}>
      <video src={src} muted style={{ width: '100%', height: '100%' }} />
      <Col absoluteFill middle backgroundColor={'rgba(0,0,0,0.05)'} onPress={showModal}>
        {/* <TouchField cirle middle shadow onPress={showModal}>
          
        </TouchField> */}
        <Entypo name="controller-play" size={48} color="white" />
      </Col>
    </RatioCol>
  );
};

export default VideoPlayInModal;
