import Request from '../Request.utils'
export interface IListAllRequestQuery {
  type?: string;
}
export interface IImportAllRequestBody {
  data?: any;
  type?: string;
}


export class MaintainerAPI {


  request = Request // for ts auto suggestion
  debugMode = false // for ts auto suggestion
  constructor(request, debugMode = false) {
    if(request) this.request = request;
    this.debugMode = debugMode;
  }
  listAll = async (query: IListAllRequestQuery) => {
    if(this.debugMode) console.log('/api/maintainer/list-all', 'GET', undefined, query, undefined, );
    const res = await this.request.call('/api/maintainer/list-all', 'GET', undefined, query, undefined, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
  importAll = async (body: IImportAllRequestBody) => {
    if(this.debugMode) console.log('/api/maintainer/import-all', 'POST', undefined, undefined, body, );
    const res = await this.request.call('/api/maintainer/import-all', 'POST', undefined, undefined, body, );
    if(this.debugMode) console.log('res.data', res.data);
    return res;
  }
}
export default new MaintainerAPI(Request);