import React, { useMemo } from 'react';
import { Platform, useWindowDimensions, ScrollView } from 'react-native';
import { COLOR } from 'const';
import Col from './Col';
import Row, { IRowProps } from './Row';
import Text from './Text';
import { ShimmerLoading, TouchField } from 'components/elements';
import { FontAwesome5 } from '@expo/vector-icons';

(() => {
  if (Platform.OS !== 'web') return;
  const style = document.createElement('style')
  style.textContent = `
    ::-webkit-scrollbar {
      height: 5px;
    }
    *:hover::-webkit-scrollbar {
      height: 10px;
    }
    #table-header {
      background-image: linear-gradient(rgba(0, 170, 180, 0.4), rgba(0, 170, 180, 0));
    }
  `;
  document.head.append(style);
})();

export interface IColProps {
  key: string, title?: string, flex?: number, width?: number, padding?: number, center?: boolean, noLeftBorder?: boolean, sort?: boolean, renderTitle?: any
}

interface ITableProps {
  columns: Array<IColProps>
  data: Array<{ [key: string]: any }>
  minWidthRequired?: number
  isLoading?: boolean
  rowProps?: IRowProps
  onPressSort?: (sortBy: string) => void
  currentSortBy?: string
  [anyProp: string]: any,
}

const TableHeader = (props) => {
  const { currentSortBy, onPressSort, col, index: i } = props;
  const { key } = col;
  const isSortUp = currentSortBy === key;
  const isSortDown = currentSortBy === `${key}_desc`;

  const onPress = () => {
    if (!isSortUp && !isSortDown) onPressSort?.(key);
    if (isSortUp) onPressSort?.(`${key}_desc`);
    if (isSortDown) onPressSort?.(key);
  }
  const WrapComponent = col.sort ? TouchField : Col;
  return (
    <WrapComponent
      width={col.width} flex={col.flex} borderRadius={0} onPress={col.sort ? onPress : undefined}
      borderLeftWidth={(!col.noLeftBorder && i !== 0) && 1} paddingHorizontal={col.padding || 20}
      nativeID='table-header' borderLeftColor={COLOR.WHITE}
    >
      <Row
        flex={1} paddingVertical={21}
        middle={col.center}
      >
        {col.renderTitle || (
          <>
            {col.sort &&
              <FontAwesome5
                name={isSortUp ? "sort-up" : (isSortDown ? "sort-down" : "sort")}
                size={16} color={COLOR.MAIN_TEXT} style={{ marginRight: 8 }}
              />
            }
            <Text fontWeight={'500'} fontSize={16} color={COLOR.MAIN_TEXT}>{col.title || ''}</Text>
          </>
        )}
      </Row>
    </WrapComponent>
  )
}

const Table = (props: ITableProps) => {
  const { width } = useWindowDimensions();
  const { columns, data, minWidthRequired, isLoading, rowProps, onPressSort, currentSortBy } = props;
  const isScroll = width <= minWidthRequired;

  const mainContent = useMemo(() => {
    return (
      <Col borderRadius={8} overflow='hidden'>
        <Row stretch>
          {columns.map((col, i) => (
            <TableHeader
              index={i}
              key={`col${i}`}
              onPressSort={onPressSort}
              currentSortBy={currentSortBy}
              col={col}
            />
          ))}
        </Row>
        {data.map((row, i) => (
          <Row
            nativeID={'table-row'} key={`row${i}`}
            alignItems='center' stretch
            backgroundColor={i % 2 ? 'rgba(242, 248, 249, 1)' : undefined}
          >
            {columns.map((col, j) => {
              let content = <Text></Text>;
              if (typeof row[col.key] === 'string') content = <Text>{row[col.key]}</Text>;
              else if (row[col.key]) content = row[col.key];
              return (
                <Col
                  width={col.width} flex={col.flex} key={`cel${i}${j}`}
                  paddingVertical={8} paddingHorizontal={col.padding || 20}
                  middle={col.center} borderColor={COLOR.MAIN}
                  {...rowProps}
                >
                  {content}
                </Col>
              )
            })}
          </Row>
        ))}
        {isLoading &&
          <Row stretch>
            {columns.map((col, i) => (
              <Col
                width={col.width} flex={col.flex} key={`col${i}`} paddingVertical={2}
                borderLeftWidth={(!col.noLeftBorder && i !== 0) && 1} paddingHorizontal={2}
                borderLeftColor={COLOR.WHITE}
              >
                <ShimmerLoading height={60} />
              </Col>
            ))}
          </Row>
        }
      </Col>
    )
  }, [columns, data])

  if (isScroll) {
    return (
      <ScrollView horizontal>
        {mainContent}
      </ScrollView>
    );
  }
  return mainContent;
};

export default Table;
