import React, { useMemo, useEffect, useState } from 'react';
import { Col, Input, Row, Text, TouchField } from 'components';
import { ActivityIndicator, StyleSheet, useWindowDimensions } from 'react-native';
import * as Animatable from 'react-native-animatable';
import { FontAwesome5 } from '@expo/vector-icons';
import { COLOR } from 'const';

class ModalController {

  show(params, modalSettings = {}) {

  }

  hide() {

  }
}

export const modalConfirm = new ModalController();

export const ConfirmModal = ({ children }) => {
  const { width } = useWindowDimensions();
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [anim, setAnim] = useState({
    animation: 'fadeInUp',
    duration: 300,
    delay: 300,
  });
  const [modalParams, setModalParams] = useState(null);
  const [modalSettings, setModalSettings] = useState<any>({
    containerStyle: {},
    contentStyle: {},
    containerOnClick: () => setShow(false),
  });

  const onPressConfirm = async () => {
    try {
      setLoading(true);
      await modalParams?.onConfirm?.(inputValue);
      modalConfirm.hide();
    } catch (error) {
      setError(error?.message || JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    modalConfirm.hide = () => {
      setShow(false);
    };
    modalConfirm.show = (params = {}, settings = {}) => {
      if (Object.keys(modalSettings).length > 0) {
        setModalSettings({
          ...modalSettings,
          ...settings,
        })
      }
      if (params?.initValue) {
        setInputValue(params?.initValue);
      }
      setModalParams(params);
      setShow(true);
    }
  });

  useEffect(() => {
    if (!show) {
      setModalParams(null);
      setInputValue('');
    }
  }, [show])

  return (
    <Col flex1>
      {useMemo(() => children, [])}
      {show && (
        <Animatable.View
          style={[{
            ...StyleSheet.absoluteFillObject,
            position: 'fixed',
            backgroundColor: 'rgba(0,0,0,0.3)',
          }, modalSettings.bgContainerStyle]}
          animation='fadeIn'
          duration={300}
        >
          <Col flex1 middle style={modalSettings.containerStyle} onPress={modalSettings.containerOnClick}>
            <Animatable.View {...anim} style={modalSettings.contentStyle}>
              <Col onPress={() => { }} activeOpacity={1}>
                <Col padding={24} paddingBottom={12} paddingRight={16} borderRadius={8} minWidth={Math.min(500, width - 64)} bgWhite>
                  <Row alignItems='center' justifyContent='space-between' marginBottom={24}>
                    <Text fontSize={20} semiBold color={COLOR.MAIN}>{modalParams?.title}</Text>
                    <TouchField padding={4} onPress={() => modalConfirm.hide()}>
                      <FontAwesome5 name='times' size={18} color={COLOR.MAIN} />
                    </TouchField>
                  </Row>
                  <Text fontSize={18} semiBold>{modalParams?.message}</Text>
                  {modalParams?.isPrompt &&
                    <Input
                      height={40}
                      mt2
                      value={inputValue}
                      onChange={setInputValue}
                      inputProps={{
                        style: {
                          // @ts-ignore
                          outline: 'none',
                          fontSize: 16,
                        },
                      }}
                      borderRadius={8}
                      borderWidth={1}
                      borderColor={COLOR.BORDER_LIGHT}
                    />
                  }
                  {!!error &&
                    <Text color={COLOR.RED} marginTop={8}>{error}</Text>
                  }
                  <Row justifyContent={'flex-end'} marginTop={24}>
                    <TouchField
                      height={40}
                      width={80}
                      middle
                      borderColor={COLOR.MAIN}
                      borderRadius={20}
                      borderWidth={1}
                      onPress={() => modalConfirm.hide()}
                      m0 ph2
                    >
                      <Text color={COLOR.MAIN}>{modalParams?.cancelText || 'Cancel'}</Text>
                    </TouchField>
                    <TouchField
                      height={40}
                      middle
                      borderColor={COLOR.MAIN}
                      borderRadius={20}
                      borderWidth={1}
                      backgroundColor={COLOR.MAIN}
                      m0 ph2
                      onPress={onPressConfirm}
                    >
                      <Row>
                        {loading && <ActivityIndicator size='small' color={'white'} style={{ marginRight: 5 }} />}
                        <Text color={COLOR.WHITE}>{modalParams?.confirmText || 'Confirm'}</Text>
                      </Row>
                    </TouchField>
                  </Row>
                </Col>
              </Col>
            </Animatable.View>
          </Col>
        </Animatable.View>
      )}
    </Col>
  );
};
