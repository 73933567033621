import { Col, Text, CMSLayout, Button, useUIState, Input } from "components";
import React, { useState, useEffect } from "react";
import { IScreen } from "type";
import Store from "store";
import { COLOR } from "const";
import OtpInput from "otp-input-react";
import PhoneInput from "react-phone-input-2";
import { auth } from "firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

const AccountSetting: IScreen = () => {
  const [{ loading: loadingGetCode, errorMes: errMessGetCode }, setUIGetCode] =
    useUIState();
  const [
    { loading: loadingSendCode, errorMes: errMessSendCode },
    setUISendCode,
  ] = useUIState();
  const [
    { loading: loadingEnable2Fa, errorMes: errMessEnable2Fa },
    setUIEnable2Fa,
  ] = useUIState();
  const [
    { loading: loadingTOTPSendCode, errorMes: errMessSendTOTPCode },
    setUISendTOTPCode,
  ] = useUIState();

  const UserStore = Store.useUserStore();

  const [isShowOTP, setIsShowOTP] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState("");
  const [totpCode, setTotpCode] = useState("");
  const [recaptchaVerifierState, setRecaptchaVerifierState] = useState(null);
  const [
    isSetupMultiFactorAuthentication,
    setIsSetupMultiFactorAuthentication,
  ] = useState(false);

  const [qrCodeImg, setQrCodeImg] = useState("");

  const [confirm, setConfirm] = useState(null);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState(60);

  const startCountdown = () => {
    setButtonDisabled(true);
    setCountdown(60);
    const timer = setInterval(() => {
      setCountdown((prevCount) => {
        if (prevCount <= 1) {
          setButtonDisabled(false);
          clearInterval(timer);
        }
        return prevCount - 1;
      });
    }, 1000);
  };

  const sendVerification = async () => {
    setUIGetCode({ loading: true });
    let recaptchaVerifier = recaptchaVerifierState || null;
    if (!recaptchaVerifierState) {
      recaptchaVerifier = await onCaptchVerify();
      setRecaptchaVerifierState(recaptchaVerifier);
    }
    try {
      const phoneNumberFormat = "+" + phoneNumber;
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumberFormat,
        recaptchaVerifier
      );
      if (confirmationResult) {
        setConfirm(confirmationResult);
        setUIGetCode({ loading: false, errorMes: "" });
        startCountdown();
        setIsShowOTP(true);
      }
    } catch (error) {
      if (error.code === "auth/too-many-requests") {
        setUIGetCode({ loading: false, errorMes: "Too many requests!" });
        return;
      }
      if (error.code === "auth/invalid-phone-number") {
        setUIGetCode({ loading: false, errorMes: "Invalid phone number!" });
        return;
      }
      if (error.code === "auth/invalid-verification-code") {
        setUIGetCode({
          loading: false,
          errorMes: "Invalid verification code!",
        });
        return;
      }
      if (error.code === "auth/missing-verification-code") {
        setUIGetCode({
          loading: false,
          errorMes: "Missing verification code!",
        });
        return;
      }
      if (error.code === "auth/missing-phone-number") {
        setUIGetCode({ loading: false, errorMes: "Missing phone number!" });
        return;
      }
      if (error.code === "auth/network-request-failed") {
        setUIGetCode({ loading: false, errorMes: "Network request failed!" });
        return;
      } else {
        setUIGetCode({ loading: false, errorMes: error.message });
        return;
      }
    }
  };

  const enable2Fa = async () => {
    try {
      setUIEnable2Fa({ loading: true });
      const res = await Store.Client.Api.User.enable2FA({
        userId: UserStore?.user.id,
      });
      if (res) {
        setQrCodeImg(res.data.data?.QRCodeImage);
      }
    } catch (error) {
      setUIEnable2Fa({
        loading: false,
        errorMes: "Enable 2FA failed!",
      });
    }
  };

  const onCaptchVerify = () => {
    return new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "invisible",
      callback: async () => {},
      "expired-callback": () => {},
    });
  };

  const confirmCode = async (authenticationType) => {
    if (authenticationType === "PHONE") {
      if (confirm) {
        try {
          setUISendCode({ loading: true });
          const result = await confirm.confirm(code);
          if (result) {
            const res = await Store.Client.Api.User.verifyToken({
              userId: UserStore?.user.id,
              idToken: result._tokenResponse.idToken,
            });
  
            if (res) {
              const token = res.data.data?.token;
              Store.Client.setToken(token);
              UserStore.set({
                token,
                user: res.data.data?.publicInfo,
              });
              setIsSetupMultiFactorAuthentication(true);
              setUISendCode({
                loading: false, errorMes: ""
              });
            }
          }
        } catch (error) {
          console.log("confirmCode error", error);
          setUISendCode({
            loading: false,
            errorMes: "Your code is not correct!",
          });
        }
      }
    }
    else if(authenticationType === "TOTP") {
      try {
        setUISendTOTPCode({ loading: true });
        const res = await Store.Client.Api.User.verify2FA({
          userId: UserStore?.user.id,
          token: totpCode,
        });
        
        if (res?.data?.success) {
          const token = res.data.data?.token;
          Store.Client.setToken(token);
          UserStore.set({
            token,
            user: res.data.data?.publicInfo,
          });
          setUISendTOTPCode({ loading: false, errorMes: ""});
        }
        else {
          setUISendTOTPCode({
            loading: false,
            errorMes: "Your code is not correct!",
          });
        }
      } catch (error) {
        console.log("confirmCode error", error);
        setUISendCode({
          loading: false,
          errorMes: "Your code is not correct!",
        });
      }
    }
  };

  return (
    <CMSLayout requireAuthen>
      {UserStore && (
        <>
          <Col flex m2 p2 round1 bgWhite shadow>
            <Text h4>Authentication App</Text>
            {(!!UserStore?.user?.multiFactorType &&
              UserStore?.user?.multiFactorType !== "NONE" &&
              UserStore?.user?.multiFactorType?.includes("TOTP")) ? (
              <Text mt4 fontSize={20} color={COLOR.MAIN}>
                Your authentication app was setup!
              </Text>
            ) : (
              <Col width={400}>
                <Text mt2>
                  Use an authentication app like <Text color={COLOR.MAIN}><a target="_blank" href="https://apps.apple.com/us/app/google-authenticator/id388497605">Google Authenticator</a></Text>, <Text color={COLOR.MAIN}><a target="_blank" href="https://apps.apple.com/us/app/twilio-authy/id494168017">Authy</a></Text>,
                  or browser extension like <Text color={COLOR.MAIN}><a target="_blank" href="https://chromewebstore.google.com/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai">Authenticator</a></Text> to get two-factor authentication codes.
                </Text>
                {qrCodeImg ? (
                  <>
                    <Text mb1 mt2>
                      Scan the QR code below with your authentication app
                    </Text>
                    <img
                      src={qrCodeImg}
                      alt="qr-code-img-2fa"
                      style={{ width: "300px" }}
                    />
                    <Text mb1 mt2>
                      Enter the code from your authentication app
                    </Text>
                    <OtpInput
                      value={totpCode}
                      onChange={setTotpCode}
                      OTPLength={6}
                      inputStyles={{
                        width: "30px",
                        height: "30px",
                        marginRight: "10px",
                      }}
                      otpType="number"
                      autoFocus
                    ></OtpInput>
                    {!!errMessSendTOTPCode ? (
                      <Text color="red" bold mt1>
                        {errMessSendTOTPCode}
                      </Text>
                    ) : (
                      <Col height={27} />
                    )}
                    <Button
                      mt2
                      solid
                      text="VERIFY"
                      width="100%"
                      onPress={() => confirmCode("TOTP")}
                      disabled={loadingTOTPSendCode}
                      isLoading={loadingTOTPSendCode}
                    />
                  </>
                ) : (
                  <>
                    {!!errMessEnable2Fa ? (
                      <Text color="red" bold mt1>
                        {errMessEnable2Fa}
                      </Text>
                    ) : (
                      <Col height={16} />
                    )}
                    <Button
                      mt2
                      solid
                      text="ENABLE"
                      width="100%"
                      onPress={enable2Fa}
                      disabled={loadingEnable2Fa}
                      isLoading={loadingEnable2Fa}
                    />
                  </>
                )}
              </Col>
            )}
          </Col>
          <div id="recaptcha-container"></div>
          <Col flex1 m2 p2 round1 bgWhite shadow>
            <Text h4>Phone authentication</Text>
            {(!!UserStore?.user?.multiFactorType &&
              UserStore?.user?.multiFactorType !== "NONE" &&
              UserStore?.user?.multiFactorType?.includes("PHONE")) ||
            isSetupMultiFactorAuthentication ? (
              <Text mt4 fontSize={20} color={COLOR.MAIN}>
                Your phone authentication was setup!
              </Text>
            ) : (
              <Col width={400}>
                <Text mb1 mt2>
                  Get one-time codes sent to your phone via SMS to complete authentication.
                </Text>
                <Text mb1 mt2>
                  Enter your phone number
                </Text>
                <PhoneInput
                  inputStyle={{ width: "100%" }}
                  country={"vn"}
                  value={phoneNumber}
                  countryCodeEditable={false}
                  onChange={setPhoneNumber}
                />
                {!loadingGetCode && errMessGetCode ? (
                  <Text color="red" bold mt1>
                    {errMessGetCode}
                  </Text>
                ) : (
                  <Col height={27} />
                )}
                <Button
                  mt2
                  solid
                  mainColor={isButtonDisabled && COLOR.GREY}
                  text={
                    isButtonDisabled ? `SEND CODE (${countdown})` : "SEND CODE"
                  }
                  width="100%"
                  disabled={isButtonDisabled}
                  onPress={() => sendVerification()}
                  isLoading={loadingGetCode}
                />
                {isShowOTP && (
                  <>
                    <Text mb1 mt2>
                      Enter your code
                    </Text>
                    <OtpInput
                      value={code}
                      disable
                      onChange={setCode}
                      OTPLength={6}
                      inputStyles={{
                        width: "30px",
                        height: "30px",
                        marginRight: "10px",
                      }}
                      otpType="number"
                      autoFocus
                    ></OtpInput>
                    {!!errMessSendCode ? (
                      <Text color="red" bold mb1>
                        {errMessSendCode}
                      </Text>
                    ) : (
                      <Col height={27} />
                    )}
                    <Button
                      mt2
                      solid
                      text="VERIFY"
                      width="100%"
                      onPress={() => confirmCode("PHONE")}
                      isLoading={loadingSendCode}
                    />
                  </>
                )}
              </Col>
            )}
          </Col>
        </>
      )}
    </CMSLayout>
  );
};

AccountSetting.routeInfo = {
  title: "Pathfinder - Account setting",
  path: "/account-setting",
};

export default AccountSetting;
