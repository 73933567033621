import React, { useEffect } from 'react';
import { useNavFunc } from './useNavFunc';
import { convertRouteToLink } from './linking';

interface IAProps {
  children?: any,
  route?: string,
  params?: any,
  forceLink?: string,
  preData?: any,
  onPress?: any,
  style?: any,
  className?: string,
}

import './A.css';

const A = ({ children, forceLink, route, params, onPress, preData, style, className } : IAProps) => {
  const { navigate , goWithPreData} = useNavFunc();

  const href = forceLink || convertRouteToLink(route, params);
  const target = !href ? undefined : href.indexOf(window.location.origin) === 0 ? '_self' : '_blank';

  const handlePress = (e) => {
    e.preventDefault();
    if (onPress) return onPress(e);
    if (!!forceLink) {
      window.open(forceLink, target);
      return;
    }
    if (!route) return;
    if (!!preData && !!preData.id && !!params && !!params.id) {
      goWithPreData(route, params, preData);
    } else {
      navigate(route, params);
    }
  };

  return (
    <a href={href} target={target} onClick={handlePress} className={className} style={style}>
      {children}
    </a>
  )
};

export default A;