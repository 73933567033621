import React from 'react';
import { Col, Row, ShimmerLoading } from 'components';
import { IRowProps } from 'components/base/Row';
import { COLOR } from 'const';

const RowTable = ({ ...props }: IRowProps) => <Row borderBottomWidth={1} borderBottomColor={COLOR.GREY_LIGHT} {...props} />;

const RowTableLoading = ({ numOfCol = 1, height = 24 }) => {

  return (
    <RowTable>
      {new Array(numOfCol).fill(1).map((i, idx) => (
        <Col flex1 p1 key={idx}>
          <ShimmerLoading height={height} />
        </Col>
      ))}
    </RowTable>
  )
};

export default RowTableLoading;
