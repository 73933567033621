import { FontAwesome5 } from "@expo/vector-icons";
import { Col, Row, Text, TouchField, modal, Select } from "components";
import { COLOR } from "const";
import React, { useCallback, useState } from "react";
import { ActivityIndicator, useWindowDimensions } from "react-native";
import Store from "store";
import shallow from "zustand/shallow";

interface Props {
  title?: string
  refresh?: () => void
  pathfinderId?: string
}

const SelectClientModal = ({ title, refresh, pathfinderId }: Props) => {
  const [selectedClient, setSelectedClient] = useState<string>()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { width } = useWindowDimensions()
  const { clients } = Store.useClientStore(state => ({
    clients: state.clients,
  }), shallow);

  const onPressConfirm = useCallback(async () => {
    try {
      setLoading(true);
      const res = await Store.Api.Pathfinder.duplicate({
        pathfinderId,
        clientId: selectedClient,
      })
      if (!res?.data?.success) {
        setError(res.data.error);
        return;
      }
      setError('');
      refresh?.();
      modal.hide();
    } catch (error) {
      setError(error?.message || JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  }, [selectedClient])

  return (
    <Col padding={24} paddingBottom={12} paddingRight={16} borderRadius={8} minWidth={Math.min(500, width - 64)} bgWhite>
      <Row alignItems='center' justifyContent='space-between' marginBottom={24}>
        <Text fontSize={20} semiBold color={COLOR.MAIN}>{title}</Text>
        <TouchField padding={4} onPress={() => modal.hide()}>
          <FontAwesome5 name='times' size={18} color={COLOR.MAIN} />
        </TouchField>
      </Row>
      <Col mt2>
        <Text caption mb1 bold>Client</Text>
          <Select
            options={Object.keys(clients).map(i => ({
              value: clients[i]?.id,
              label: clients[i]?.name,
            }))}
            value={{ value: selectedClient, label: clients[selectedClient]?.name }}
            onChange={(e) => setSelectedClient(e?.value)}
          />
      </Col>
      
      {!!error &&
        <Text color={COLOR.RED} marginTop={32}>{error}</Text>
      }
      <Row justifyContent={'flex-end'} marginTop={24}>
        <TouchField
          height={40}
          width={80}
          middle
          borderColor={COLOR.MAIN}
          borderRadius={20}
          borderWidth={1}
          onPress={() => modal.hide()}
          m0 ph2
        >
          <Text color={COLOR.MAIN}>Cancel</Text>
        </TouchField>
        <TouchField
          height={40}
          middle
          borderColor={COLOR.MAIN}
          borderRadius={20}
          borderWidth={1}
          backgroundColor={COLOR.MAIN}
          m0 ph2
          opacity={selectedClient ? 1 : 0.2}
          disabled={!selectedClient}
          onPress={onPressConfirm}
        >
          <Row>
            {loading && <ActivityIndicator size='small' color={'white'} style={{ marginRight: 5 }} />}
            <Text color={COLOR.WHITE}>Confirm</Text>
          </Row>
        </TouchField>
      </Row>
    </Col>
  )
}

export default SelectClientModal
